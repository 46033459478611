import React, { useEffect, useState } from 'react'
import styles from "./Unarmiyans.module.css";
import { IoMdClose } from "react-icons/io";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import addFont from '../../fonts/fonts'
import moment from 'moment';
import { IoIosClose } from "react-icons/io";
import logo from '../../assets/images/label.png';
import { filters, selects } from '../../utils/const';
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import CurrentProfilesPrintBadges from './CurrentProfilesPrintBadges';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { getRegionDepsOptions } from '../../utils/getOptions';

export default function CurrentProfilesList({
    currentProfiles,
    allProfiles,
    setCurrentProfiles,
    handleDeleteCurrentProfile,
    removeDataForPrint,
    authData,
    print,
    setPrint,
    departments,
    editYunarmianGroup,
    getDeps,
    depsList,
    getTroops,
    troopList,
    page,
    groupChanges,
    setGroupChanges,
    massiveSendDocuments,
    loading
}) {

    const [status, setStatus] = useState(null);
    const [domesticDepartment, setDomesticDepartment] = useState(null);
    const [detachmentId, setDetachmentId] = useState(null);
    const [isShowPopup, setIsShowPopup] = useState(null);
    const [searchTroop, setSearchTroop] = useState('');
    const [organiz, setOrganiz] = useState('');
    const [city, setCity] = useState('');
    const [confirmation, setConfirmation] = useState(null);
    const [regDep, setRegDep] = useState(null)
    const [printBadgesMode, setPrintBadgesMode] = useState(false)
    
    const title = 'Список юнармейцев'
    const orientation = 'p'
    const profiles = (print||groupChanges) ? allProfiles : currentProfiles

  useEffect(() => {
    authData?.user?.department_id && setRegDep(authData?.user?.department_id)
  
  }, [authData?.user?.department_id])
  

    useEffect(() => {
      if (allProfiles && !loading) {
        print && toPdf()
        groupChanges && setIsShowPopup(true)
      }
    }, [print, groupChanges, allProfiles, loading])

    useEffect(() => {
      getDeps(regDep);
      getTroops(regDep);
    }, [regDep])
    

    const handleGroupChange = () => {
      const listId = profiles.map(el => el.id);
      editYunarmianGroup(status, domesticDepartment, detachmentId, listId, organiz, city, page);
      setGroupChanges(false)
      handleClosePopup();
    }

    const handleReset = () => {
      setDomesticDepartment(null);
      setDetachmentId(null);
      setStatus(null);
      setOrganiz('');
      setSearchTroop('');
    }

    const handleOpenPopup = () => {
      setRegDep(authData?.user?.department_id)
      getDeps(regDep);
      getTroops(regDep);
      setIsShowPopup(true);
    }

    const handleClosePopup = () => {
      setIsShowPopup(false);
      setDomesticDepartment(null);
      setDetachmentId(null);
      setStatus(null);
      setOrganiz('');
      setSearchTroop('');
      setGroupChanges(false);
    }

    const handleSearch = (value) => {
      setSearchTroop(value);
    }

    const acceptConfirmation = () => {
      handleGroupChange();
      setConfirmation(null);
    }

    const handleSetDep = (id) => {
      setRegDep(id);
    }

    const addFooters = (doc, img) => {
      const pageCount = doc.internal.getNumberOfPages()
      const ownDepartment = departments.find(it => it.id===authData.user.department_id)

      for (var i = 1; i <= pageCount; i++) {
        doc.setFontSize(6)
        doc.setPage(i)
        doc.addImage(img, 'png', 5, 5, 25, 25)
        doc.text(`ШТАБ РЕГИОНАЛЬНОГО ОТДЕЛЕНИЯ ВСЕРОССИЙСКОГО ДЕТСКО-ЮНОШЕСКОГО ВОЕННО-ПАТРИОТИЧЕСКОГО ДВИЖЕНИЯ "ЮНАРМИЯ"\n
        ${ownDepartment?.name.toUpperCase()} - ${authData.user.phone} ${authData.user.email}`, 100, 289, {
          align: 'center'
        })
        doc.text(`Отчет составлен пользователем: ${authData.user.displayname} ${moment().format("DD.MM.YYYY HH:mm")}`, 100, 294, "center")
        doc.setFontSize(10)
        doc.text(`ШТАБ РЕГИОНАЛЬНОГО ОТДЕЛЕНИЯ ВСЕРОССИЙСКОГО\n
        ДЕТСКО-ЮНОШЕСКОГО ВОЕННО-ПАТРИОТИЧЕСКОГО\n
        ДВИЖЕНИЯ "ЮНАРМИЯ" ${ownDepartment?.name.toUpperCase()}`, 100, 10, "center")
      }
    }

  const createDocData = async () => {
    

    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation, lineHeight: 0.5 })

		doc.setFont("Montserrat");

    doc.setFontSize(18)
    doc.text("Список Юнармейцев", 100, 25, "center" )
    const img = new Image()
    img.src = logo
    
    doc.autoTable({
      html: `#currentProfilesTable`,
      margin: {
        top: 35
      },
      styles: { font: 'Montserrat', fontSize: 8 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        textColor: '#000',
      }
    })
    
    const finalY = doc.previousAutoTable.finalY
    doc.setFontSize(8)
    doc.text(`Всего ${profiles.length} записей`, 15, finalY+5)
    doc.setFontSize(10)
    doc.text('Должность_____________________                             ФИО___________________________    Подпись____________', 10, finalY+20)
    doc.text('"___"___________20___г.', 155, finalY+35)

    addFooters(doc, img)

    doc.addPage('a4', orientation)
    doc.deletePage(doc.getCurrentPageInfo().pageNumber)

    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
    setPrint(false)
    removeDataForPrint()
  }

  const sendDocuments = () => {
    const payload = profiles.map(profile => profile.id)
    massiveSendDocuments(payload)
  }

  const table = <table id="currentProfilesTable">
  <thead>
      <tr>
        <th>№</th>
        <th>ФИО</th>
        <th>Дата рождения</th>
        <th>Дата вступления</th>
        <th>Личный номер</th>
        <th>Местное отделение</th>
        <th>Отряд</th>
        
      </tr>
  </thead>
  <tbody>
    {profiles && profiles.map((it,i) => {
      return <tr key={i}>
      <td>{i+1}</td>
      <td>{`${it.lastName} ${it.firstName} ${it.fathersName}`}</td>
      <td>{moment(it.birthday).format("DD.MM.YYYY")}</td>
      <td>{it.signDate ? moment(it.signDate).format("DD.MM.YYYY") : ''}</td>
      <td>{it.personalId}</td>
      <td>{it?.domestic_department?.name}</td>
      <td>{it?.detachment?.name}</td>
    </tr>
    })}
  </tbody>  
</table>

const handleSelectRegion = (e) => {
  const value = e.target.value
  handleSetDep(value)
}

  return (
    <>
      {isShowPopup &&
        <div className={styles.wapperPopup}>
        <div className={styles.popup}>
          <i className={styles.closePopupButton} onClick={handleClosePopup}><IoMdClose/></i>
          <p className={styles.popupTitle}>Групповая смена данных</p>
          <p className={styles.popupMessage}>{`Внимание! Количество записей, которые затронут изменения - ${profiles.length}!`}</p>

          {
            !!authData.superuser &&
            <div className={`${styles.regionBlock} ${styles.regionBlock_First}`}>
              <div className={styles.nameAndArrow}>
                <span className={styles.inputName}>Региональное отделение:</span>
                
                <div style={{width: "100%"}} className={styles.localSelect}>
                    <CustomSelect
                      width="500px"
                      title="Выберите региональное отделение"
                      options={[{name: "Все", value: "0"}, ...getRegionDepsOptions(departments)]}
                      onChange={handleSelectRegion}
                      selectedValue={regDep}
                      withoutFirst
                      defaultValue={departments?.filter(el => el.id === regDep)[0]?.id}
                    />
                </div>
              </div>
            </div> 
          }

          <div className={styles.inputBlock}>
          <div className={styles.regionBlock}>
              <span className={styles.inputName}>Статус</span>
                <ul className={styles.regionList}>
                  {filters && filters.filter(el => el.name === 'Статус')[0].value.map(dep => 
                    <li 
                    key={dep} 
                    className={status == dep ? styles.regionItem_active : styles.regionItem}
                    onClick={() => setStatus(dep)}
                    >
                      {`${selects.status.filter(st => st.value === dep)[0]?.name}`}
                    </li>  
                  )}
                </ul>
            </div>

            <div className={styles.regionBlock}>
              <span className={styles.inputName}>Местное отделение</span>
                <ul className={styles.regionList}>
                  {depsList && depsList.map(dep => 
                    <li 
                    key={dep.id} 
                    className={domesticDepartment == dep.id ? styles.regionItem_active : styles.regionItem}
                    onClick={() => setDomesticDepartment(dep.id)}
                    >
                      {`${dep.name}`}
                    </li>  
                  )}
                </ul>
            </div>

            <div className={styles.regionBlock}>
              <span className={styles.inputName}>Отряд</span>
              <input type='text' onChange={e => handleSearch(e.target.value)} value={searchTroop} className={styles.inputSearch}/>
                <ul className={styles.regionList}>
                  {troopList && troopList
                  .filter(dep => dep.name.toLowerCase().includes(searchTroop.toLowerCase()))
                  .map(dep => 
                    <li 
                    key={dep.id} 
                    className={detachmentId == dep.id ? styles.regionItem_active : styles.regionItem}
                    onClick={() => setDetachmentId(dep.id)}
                    >
                      {`${dep.name}`}
                    </li>
                  )
                  }
                </ul>
            </div>

            <div className={styles.regionBlock}>
            <span className={styles.inputName}>Образовательная организация</span>
              <input 
              type='text'
              onChange={e => setOrganiz(e.target.value)} 
              value={organiz} 
              className={styles.inputSearch}/>
            </div>

            <div className={styles.regionBlock}>
            <span className={styles.inputName}>Населенный пункт</span>
              <input 
              type='text'
              onChange={e => setCity(e.target.value)} 
              value={city} 
              className={styles.inputSearch}/>
            </div>

          </div>
            <div className={styles.buttonsPopup}>
            <button className={styles.sendButton} onClick={setConfirmation} disabled={!status && !domesticDepartment && !detachmentId && !organiz}>Применить</button>
            
            <button className={styles.resetButton} onClick={handleReset} disabled={!status && !domesticDepartment && !detachmentId && !organiz}>
              <i className={styles.resetButtonIcon} onClick={handleClosePopup}><IoMdClose/></i>
              <span className={styles.buttonText}>Сбросить</span>
              </button>
            </div>
        </div>
      </div>
      }
      {printBadgesMode &&
        <CurrentProfilesPrintBadges
          currentProfiles={currentProfiles}
          open={printBadgesMode}
          onClose={() => setPrintBadgesMode(false)}
        />
      }
      { !groupChanges &&
        <div className={`${styles.currentProfilesBlock} ${currentProfiles.length ? styles.active : null}`}>
          <IoMdClose className={styles.closeButton} onClick={() => setCurrentProfiles([])}/>
          {table}
          <div className={styles.currentProfilesList}>
              {currentProfiles.map((it,i) => <div key={i}>
                  <span>{i+1}</span>
                  <span>{`${it.lastName} ${it.firstName} ${it.fathersName}`}</span>
                  <IoIosClose onClick={() => handleDeleteCurrentProfile(i)}/>
              </div>)}
          </div>
          <div className={styles.currentButtonsBlock}>
              <button onClick={toPdf}>Скачать PDF</button>
              <button onClick={handleOpenPopup}>Применить к группе</button>
              {!!currentProfiles.length
              && currentProfiles.length<200 &&              
              <button onClick={sendDocuments}>Отправить пакет документов на вступление</button>}
              <button onClick={() => setPrintBadgesMode(true)}>Печать бейджей</button>

          <div>
          </div>
          </div>
        </div>}

        {confirmation &&
          <Confirmation 
            title={`Внимание! Количество записей, которые затронут изменения - ${profiles.length}! Применить изменения?`}
            color="red"
            acceptConfirmation={acceptConfirmation}
            rejectConfirmation={() => setConfirmation(null)}
          />
        }
      </>
  )
}
