import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import styles from '../styles.module.css'
import Li from '../../../components/Li'
import { createGroupAccess, getAccessEntities, getGroupAccess, updateGroupAccess } from '../../../actions/accessControls'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { ButtonRed } from '../../../assets/common/ButtonRed/ButtonRed'
import CustomSelect from '../../../components/CustomSelect/CustomSelect'
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox'
import { switchCase } from '@babel/types'
import { createNotification } from '../../../components/Notifications/Notifications'


const selectOptions = [
  {name: 'Полный'},
  {name: 'Только свой регион'},
  {name: 'Нет'}
]

const accessObject = {
  ac_create: false,
  ac_create_by_region: false,
  ac_delete: false,
  ac_delete_by_region: false,
  ac_read: false,
  ac_read_by_region: false,
  ac_update: false,
  ac_update_by_region: false,
  ac_publish: false,
}

export function Access ({roleId}) {
  
  const dispatch = useDispatch()
  const {accessEntities, groupAccess, loading} = useSelector(state => state.roles.access_control)

  const [selectedEntity, setSelectedEntity] = useState(null)

  useEffect(() => {
    !accessEntities && dispatch(getAccessEntities())
    dispatch(getGroupAccess(roleId))
  }, [])
  

  const openModal = (entData) => () => setSelectedEntity(entData)

  const closeModal = () => setSelectedEntity(null)

  const renderPointItemsLi = (entity, index) => {
    const {description} = entity 
    return (
      <Li onClick={openModal(entity)}>
        <div className={styles.itemLabel}>{description}</div>
      </Li>
    )
  }
  
  return (
    <div className={styles.accessWrapper}>
      <AccessModal
          open={!!selectedEntity}
          closeModal={closeModal}
          selectedEntity={selectedEntity}
          accessList={groupAccess}
          addmode={!selectedEntity?.id}
          roleId={roleId}
        />
      <ul>{accessEntities?.map(renderPointItemsLi)}</ul>
    </div>
  )
}


function AccessModal ({open, closeModal, selectedEntity, accessList, addMode, roleId}) {
  
  const initialValues = accessList?.find(accessItem => accessItem.ac_entity_id === selectedEntity?.id) || {}
  const modalTitle = addMode ? 'Добавление сущности' : selectedEntity?.description

  const dispatch = useDispatch()
  
  const updateAccess = async ({id, ...data}) => {
    if (!id) {
      createNotification('error', 'Ошибка обновления прав')
      return
    }
    await dispatch(updateGroupAccess(id, roleId, data))
    closeModal()
  }
  
  const {
    values,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: updateAccess
  })

  const getSelectValue = (selectName) => {
    const { fullAccess, byRegionAccess } = getAccessNames(selectName)
    if (values[fullAccess]) return 'Полный';
    if (values[byRegionAccess]) return 'Только свой регион';
    return 'Нет'
  }

  const handleCheckboxChange = (e) => {
    const { name } = e.target
    const newFieldValue = !values[name]
    setFieldValue(name, newFieldValue)
  }
  
  const getAccessNames = (name) => {
    const fullAccess = `ac_${name}`
    const byRegionAccess = `ac_${name}_by_region`
    return { fullAccess, byRegionAccess } 
  }
  
  const handleSelectChange = (e) => {
    const { name, value } = e.target
    const { fullAccess, byRegionAccess } = getAccessNames(name)
    switch (value) {
      case 'Полный':
        setFieldValue(fullAccess, true)
        setFieldValue(byRegionAccess, true)
        return
      case 'Только свой регион':
        setFieldValue(fullAccess, false)
        setFieldValue(byRegionAccess, true)
        return
      default:
        setFieldValue(fullAccess, false)
        setFieldValue(byRegionAccess, false)
        return
    }
  }
  
  return (
    <Modal
      open={open}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      classNames={{
        modal: styles.modal
      }}
    >
      <div className={styles.title}>{modalTitle}</div>
      <div className={styles.modalBody}>
        <CustomSelect
          title='Создание'
          name='create'
          options={selectOptions}
          selectedValue={getSelectValue('create')}
          onChange={handleSelectChange}
          withoutFirst
        />
        <CustomSelect
          title='Чтение (просмотр)'
          name='read'
          options={selectOptions}
          selectedValue={getSelectValue('read')}
          onChange={handleSelectChange}
          withoutFirst
        />
        <CustomSelect
          title='Изменение'
          name='update'
          options={selectOptions}
          selectedValue={getSelectValue('update')}
          onChange={handleSelectChange}
          withoutFirst
        />
        <CustomSelect
          title='Удаление'
          name='delete'
          options={selectOptions}
          selectedValue={getSelectValue('delete')}
          onChange={handleSelectChange}
          withoutFirst
        />
        <CustomCheckbox
          label='публикация'
          name='ac_publish'
          value={values.ac_publish}
          onClick={handleCheckboxChange}
        />
        <div className={styles.modalButtons}>
          <ButtonRed onClick={handleSubmit}>Сохранить</ButtonRed>
          <ButtonRed onClick={closeModal}>Закрыть</ButtonRed>
        </div>
      </div>
    </Modal>
  )
}