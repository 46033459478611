import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import Home from "../pages/Home/Home";
import Layout from "../components/Layout/Layout";
import News from "../pages/News/News";

import AddNews from "../pages/AddNews/AddNews";
import Users from "../pages/Users/Users";
import { Test } from "../pages/Test/Test";
import EventsSchedule from "../pages/EventsSchedule/EventsSchedule";
import RegionDepartments from "../pages/RegionDepartments/RegionDepartments";
import AddEvent from "../pages/AddEvent/AddEvent";
import MethodicMaterialGroups from "../pages/MethodicMaterialGroups/MethodicMaterialGroups";
import MethodicMaterials from "../pages/MethodicMaterials/MethodicMaterials";
import Documents from "../pages/Documents/Documents";
import Tourism from "../pages/Tourism/Tourism";
import Equipment from "../pages/Equipment/Equipment";
import Videos from "../pages/Videos/Videos";
import Digital from "../pages/Digital/Digital";
import Unarmiyans from "../pages/Unarmiyans/Unarmiyans";
import Profile from "../pages/Profile/Profile";
import RegRatingGeneral from "../pages/RegRating/RegRatingGeneral";
import Tasks from "../pages/Tasks/Tasks";
import Import from "../pages/Import/Import";
import LocalDepartments from "../pages/LocalDepartments/LocalDepartments";
import Detachments from "../pages/Detachments/Detachments";
import Plug from "../pages/Plug/Plug";
import Quests from "../pages/Quests/Quests";
import YunarmyStat from "../pages/YunarmyStat/YunarmyStat";
import AnalyticalReport from "../pages/AnalyticalReport/AnalyticalReport";
import About from "../pages/About/About";
import Regulations from "../pages/Regulation/Regulations";
import Regions from "../pages/Regions/Regions";
import YunarmyHouses from "../pages/YunarmyHouses/YunarmyHouses";
import RatingParams from "../pages/RatingParams/RatingParams";
import Awards from "../pages/Awards/Awards";
import Confirm from "../pages/Confirm/Confirm";
import Private from "../pages/Private/Private";
import RegRating from "../pages/RegRating/RegRating";
import ActivityRatingReport from "../pages/ActivityRatingReport/ActivityRatingReport";
import RegionGroups from "../pages/RegionGroups/RegionGroups";
import MailsJournal from "../pages/MailsJournal/MailsJournal";
import Hero from "../pages/Hero/Hero";
import Mobile_app from "../pages/Mobile_app/Mobile_app";
import Badges from "../pages/Badges/Badges";
import LeaderContactsReport from "../pages/LeaderContactsReport/LeaderContactsReport";
import { PrivateRoute } from "../components/Routing";
import Roles from "../pages/Roles";
import RoleSettings from "../pages/RoleSettings";

export const PATHS = {
  ROOT: "/",
  AUTH: {
    HOME: "/home",
    NEWS: "/news",
    ADD_NEWS: "/news/add_news",
    EDIT_NEWS: "/news/edit_news/:newId?",
    EVENT: "/event",
    ADD_EVENT: "/event/add_event",
    EDIT_EVENT: "/event/edit_event/:newId?",
    ADD_TASK: "/add_task",
    EDIT_TASK: "/edit_task",
    USERS: "/users",
    TEST: "/test",
    ADS: "/ads",
    ADD_ADS: "/ads/add_ads",
    EDIT_ADS: "/ads/edit_ads/:newId?",
    EVENTS_SCHEDULE: "/eventsschedule",
    REGION_DEPARTMENTS: "/reg_departments",
    LOCAL_DEPARTMENTS: "/loc_departments",
    MATERIAL_GROUPS: "/material_groups",
    METHODIC_MATERIALS: "/methodic_materials",
    NPA: "/npa",
    REFERENCES: "/references",
    REGLAMENT_DOCS: "/reglament_docs",
    MAIN_DOCS: "/main_docs",
    MEET_DOCS: "/meet_docs",
    PARTNER_DOCS: "/partner_docs",
    HERO: "/hero",
    VDL_DOCS: "/vdl_docs",
    TOURISM: "/tourism",
    EQUIPMENT: "/equipment",
    VIDEOS: "/videos",
    DIGITAL_ACADEMY: "/digital_academy",
    UNARMIYANS: "/unarmiyans_list",
    PROFILE: "/profile/:userId?",
    REG_RATING_GEN: "/reg_rating_general",
    REG_RATING: "/reg_rating",
    QUESTS: "/quests",
    TASKS: "/tasks/:taskId?",
    IMPORT: "/import",
    DETACHMENTS: "/detachments",
    PLUG: "/plug",
    YUN_STAT: "/yun_stat",
    ANALYTICAL_REPORT: "/analytical_report",
    ACTIVITY_REPORT: "/activity_rating_report",
    ABOUT: "/about",
    REGULATION: "/regulation",
    ORDERS: "/orders",
    REGIONS: "/regions",
    RATING_PARAMS: "/rating_params",
    AWARDS: "/awards",
    YUNARMY_HOUSES: "/yunarmy_houses",
    CONFIRM: "/confirm",
    PRIVATE: "/private",
    LEADER_CONTACTS: "/leader_contacts",
    REGION_GROUPS: "/region_groups",
    MAILS_JOURNAL: "/mails_journal",
    MOBILE_APP: "/mobile_app/login",
    BADGES: "/badges",
    ROLES: "/roles",
    ROLE_SETTINGS: "/roles/:roleId"
  },
};

export default function Routes() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path={PATHS.ROOT} component={Auth} />
          <PrivateRoute>
            <Route exact path={PATHS.AUTH.HOME} component={Home} />
            <Route exact path={PATHS.AUTH.NEWS} component={News} />
            <Route exact path={PATHS.AUTH.EVENT} component={News} />
            <Route exact path={PATHS.AUTH.ADD_NEWS} component={AddNews} />
            <Route exact path={PATHS.AUTH.EDIT_NEWS} component={AddNews} />
            <Route exact path={PATHS.AUTH.ADD_EVENT} component={AddEvent} />
            <Route exact path={PATHS.AUTH.EDIT_EVENT} component={AddEvent} />
            <Route exact path={PATHS.AUTH.USERS} component={Users} />
            <Route exact path={PATHS.AUTH.TEST} component={Test} />
            <Route exact path={PATHS.AUTH.ADS} component={News} />
            <Route exact path={PATHS.AUTH.ADD_ADS} component={AddNews} />
            <Route exact path={PATHS.AUTH.DIGITAL_ACADEMY} component={Digital} />
            <Route exact path={PATHS.AUTH.EDIT_ADS} component={AddNews} />
            <Route exact path={PATHS.AUTH.QUESTS} component={Quests} />
            <Route exact path={PATHS.AUTH.ABOUT} component={About} />
            <Route exact path={PATHS.AUTH.REGULATION} component={Regulations} />
            <Route exact path={PATHS.AUTH.ORDERS} component={Regulations} />
            <Route exact path={PATHS.AUTH.EVENTS_SCHEDULE} component={EventsSchedule} />
            <Route exact path={PATHS.AUTH.REGION_DEPARTMENTS} component={RegionDepartments} />
            <Route exact path={PATHS.AUTH.LOCAL_DEPARTMENTS} component={LocalDepartments} />
            <Route exact path={PATHS.AUTH.DETACHMENTS} component={Detachments} />
            <Route exact path={PATHS.AUTH.MATERIAL_GROUPS} component={MethodicMaterialGroups} />
            <Route exact path={PATHS.AUTH.METHODIC_MATERIALS} component={MethodicMaterials} />
            <Route exact path={PATHS.AUTH.NPA} component={Documents} />
            <Route exact path={PATHS.AUTH.REFERENCES} component={Documents} />
            <Route exact path={PATHS.AUTH.REGLAMENT_DOCS} component={Documents} />
            <Route exact path={PATHS.AUTH.MAIN_DOCS} component={Documents} />
            <Route exact path={PATHS.AUTH.MEET_DOCS} component={Documents} />
            <Route exact path={PATHS.AUTH.PARTNER_DOCS} component={Documents} />
            <Route exact path={PATHS.AUTH.VDL_DOCS} component={Documents} />
            <Route exact path={PATHS.AUTH.TOURISM} component={Tourism} />
            <Route exact path={PATHS.AUTH.EQUIPMENT} component={Equipment} />
            <Route exact path={PATHS.AUTH.VIDEOS} component={Videos} />
            <Route exact path={PATHS.AUTH.UNARMIYANS} component={Unarmiyans} />
            <Route exact path={PATHS.AUTH.PROFILE} component={Profile} />
            <Route exact path={PATHS.AUTH.REG_RATING_GEN} component={RegRatingGeneral} />
            <Route exact path={PATHS.AUTH.REG_RATING} component={RegRating} />
            <Route path={PATHS.AUTH.TASKS} component={Tasks} />
            <Route exact path={PATHS.AUTH.IMPORT} component={Import} />
            <Route exact path={PATHS.AUTH.PLUG} component={Plug} />
            <Route exact path={PATHS.AUTH.YUN_STAT} component={YunarmyStat} />
            <Route exact path={PATHS.AUTH.REGIONS} component={Regions} />
            <Route exact path={PATHS.AUTH.RATING_PARAMS} component={RatingParams} />
            <Route exact path={PATHS.AUTH.AWARDS} component={Awards} />
            <Route exact path={PATHS.AUTH.YUNARMY_HOUSES} component={YunarmyHouses} />
            <Route exact path={PATHS.AUTH.ANALYTICAL_REPORT} component={AnalyticalReport} />
            <Route exact path={PATHS.AUTH.HERO} component={Hero} />
            <Route exact path={PATHS.AUTH.ACTIVITY_REPORT} component={ActivityRatingReport} />
            <Route exact path={PATHS.AUTH.CONFIRM} component={Confirm} />
            <Route exact path={PATHS.AUTH.MOBILE_APP} component={Mobile_app} />
            <Route exact path={PATHS.AUTH.PRIVATE} component={Private} />
            <Route exact path={PATHS.AUTH.REGION_GROUPS} component={RegionGroups} />
            <Route exact path={PATHS.AUTH.MAILS_JOURNAL} component={MailsJournal} />
            <Route exact path={PATHS.AUTH.BADGES} component={Badges} />
            <Route exact path={PATHS.AUTH.LEADER_CONTACTS} component={LeaderContactsReport} />
            <Route exact path={PATHS.AUTH.ROLE_SETTINGS} component={RoleSettings} />
            <Route exact path={PATHS.AUTH.ROLES} component={Roles} />
          </PrivateRoute>
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}
