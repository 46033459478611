import React, { useState, useEffect } from 'react'
import styles from './AnimationComponent.module.css'
import { useRef } from 'react'
import Lottie from 'react-lottie';
import { BACKEND_DOMAIN } from '../../utils/const';


export default function AnimationComponent({
  options,
  value,
  onChange,
  disabled
}) {
  
  const [visiblePopup, setVisiblePopup] = useState(false)
  const popupRef = useRef(null)

  const valueId = value?.animation?.animation_id

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick)
    return () => {
      document.body.removeEventListener('click', handleOutsideClick)
    }
  }, [])  
  
  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (popupRef.current && !path.includes(popupRef.current)) {
      setVisiblePopup(false)
    }
  }

  const onClick = (animationData) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    onChange(animationData)
    setVisiblePopup(false)
  }

  const renderSelectedAnimation = () => {
    const value = options.find(item => item.id === valueId)
    return renderAnimation(value, true)
  }

  const renderAnimation = (animation, mainMode, ind) => {
    const animationData = animation.animation || animation.animation_file
    const animationId = animation.id
    const {path, name, id} = animationData
    const selected = animationId === valueId
    const animationUrl = `${BACKEND_DOMAIN || ''}/${path}/${name}`
    return (
        <div
          onClick={mainMode ? null : onClick(animationId)}
          style={{border: selected && !mainMode ? `1px solid #fb8383` : 'none', borderRadius: '.2rem'}}
          key={animationUrl}
        >
          <Lottie
            options={{
              loop: true, 
              path: animationUrl
            }}
            height={200}
            width={150}
            isClickToPauseDisabled
          />
        </div>
    )
  }

  const openPopup = () => {
    if (disabled) return;
    setVisiblePopup(true)
  }

  const plugClick = () => {
    onChange(null)
    setVisiblePopup(false)
  }

  return (
    <div className={styles.wrapper} ref={popupRef}>
        
        <div className={styles.currentAnimation} onClick={openPopup}>
          {valueId && renderSelectedAnimation(value.animation.animation_id)}
        </div>

        {visiblePopup &&
          <div className={styles.popup}>
            <div
              onClick={plugClick}
              className={styles.empty}
              style={{borderColor: valueId ? 'inherit' : 'red'}}
            />
            {options.map((it, ind) => renderAnimation(it, false, ind))}
          </div>
        }

    </div>
  )
}
