import React, { useState, useEffect } from "react";
import styles from "./SliderComp.module.css";
import { connect } from "react-redux";
import {
  IoIosPaper,
  IoMdTrash,
  IoMdSearch,
  IoIosCloseCircle,
} from "react-icons/io";
import { GrRotateLeft, GrRotateRight } from "react-icons/gr";
import { Slider } from "../Equipment/Slider";
import { SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.css";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import {
  editYunarmianProfile,
  uploadDocs,
  deleteDocs,
} from "../../actions/yunarmiyans";
import avatar from "../../assets/images/avatar.png";
import { BACKEND_DOMAIN } from "../../utils/const";

const SliderComp = ({
  editYunarmianProfile,
  photos,
  editMode,
  type,
  setEditMode,
  selectedItem,
  uploadDocs,
  deleteDocs,
  goEdit,
  setGoEdit,
  goAdd,
  newID,
  isAvatar = false,
  rotateDocumentImage,
}) => {
  const [isPopup, setIsPopup] = useState(false);
  const [imagesPreviews, setImagesPreviews] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [photosDocs, setPhotosDocs] = useState([]);
  const [toDeleteImages, setToDeleteImage] = useState([]);
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    setSelectedFiles([]);
    setPhotosDocs([]);
    setImagesPreviews([]);
    if (Array.isArray(photos)) {
      setImagesPreviews(photos);
    }
  }, [editMode]);

  useEffect(() => {
    if (goEdit) {
      handleEdit();
    }
  }, [goEdit]);

  useEffect(() => {
    if (goAdd && selectedFiles.length > 0) {
      selectedFiles.forEach((el) => uploadDocs(newID, { file: el.file }, type, el.rotate));
    }
  }, [goAdd]);

  const loopImage = (photo) => {
    setIsPopup(photo);
    setRotate(photo.rotate);
  };

  const handleUploadImage = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
        rotate: 0
      }));
      setSelectedFiles((prev) => [...prev, ...filesArray]);
      setImagesPreviews((prev) => [...prev, ...filesArray.map((el) => ({url: el.url, rotate: el.rotate}))]);
    }
  };

  const deletePhoto = (photo) => {
    if (photo.id) {
      setToDeleteImage((prev) => [...prev, photo]);
      setPhotosDocs((prev) => prev.filter((el) => el.id !== photo.id));
    } else {
      const file = imagesPreviews.find((el) => el.url === photo.url);

      URL.revokeObjectURL(file);

      setSelectedFiles(selectedFiles.filter((el) => el.url !== photo.url));
    }
    setImagesPreviews(imagesPreviews.filter((obj) => photo.url ? (obj.name || obj.url !== photo.url) : (obj.url || obj.name!==photo.name)));
  };

  const handleEdit = () => {
    setEditMode(false);

    if (selectedFiles.length > 0) {
      selectedFiles.forEach((el) =>
        uploadDocs(selectedItem.id, { file: el.file }, type, el.rotate)
      );
    }
    if (toDeleteImages.length > 0) {
      toDeleteImages.forEach((el) => deleteDocs(selectedItem.id, el.id));
    }
    setSelectedFiles([]);
    setToDeleteImage([]);
    setGoEdit(null);
  };

  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <SwiperSlide key={`${index}_docs`}>
          <div className={`photoWrapper`} style={{overflow: "hidden"}}>
            {editMode && (
              <i
                className={styles.deletePhoto}
                onClick={() => deletePhoto(photo)}
              >
                <IoMdTrash />
              </i>
            )}
            <i className={styles.search} onClick={() => loopImage(photo)}>
              <IoMdSearch />
            </i>
            <img
              src={
                !photo.id
                  ? photo.url
                  : `${BACKEND_DOMAIN || ""}${photo.path}${photo.name}`
              }
              alt="фото"
              style={{transform: `rotate(${photo.rotate}deg)`}}
              className={styles.imgUploder}
            />
          </div>
        </SwiperSlide>
      );
    });
  };

  const rotateImage = (direction) => {
    const angle = direction === "left" ? (Number(rotate) - 90) : (Number(rotate) + 90);
    const arr = [...imagesPreviews]
    if (!isPopup.id) {
      const index = arr.findIndex(it => it.url===isPopup.url)
      arr[index] = {...isPopup, rotate: angle}
      const uploadArr = [...selectedFiles]

      const uploadFileIndex = uploadArr.findIndex(it => it.url===isPopup.url)
      uploadArr[uploadFileIndex].rotate = angle
      setSelectedFiles(uploadArr)
    } else {
      const index = arr.findIndex(it => it.id===isPopup.id)
      arr[index] = {...isPopup, rotate: angle}
      rotateDocumentImage(isPopup.id, angle%360);
    }
    setImagesPreviews(arr)
    setRotate(angle);
  };

  const closePopup = () => {
    setIsPopup(null)
  }

  return (
    <>
      <>
        {isPopup && (
          <>
            <div className={styles.bg}></div>
            <div className={styles.popup} onClick={closePopup}>
              <i
                className={styles.popup_close}
                onClick={closePopup}
              >
                <IoIosCloseCircle />
              </i>
              <img
                src={
                  !isPopup.id
                    ? isPopup.url
                    : `${BACKEND_DOMAIN || ""}${isPopup.path}${isPopup.name}`
                }
                alt="o_O"
                style={{transform: `rotate(${rotate}deg)`, transition: '.5s'}}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            {editMode && 
            <div className={styles.rotateBlock}>
              <GrRotateLeft onClick={() => rotateImage("left")} />
              <GrRotateRight onClick={() => rotateImage("right")} />
            </div>}
          </>
        )}
        <div className={styles.wrapperSl}>
          {(photosDocs && photosDocs.length > 0) ||
          (imagesPreviews && imagesPreviews.length > 0) ? (
            <div className={styles.sliderWrapper}>
              <Slider sliderName={styles.photoSlider} autoHeight={true}>
                {renderPhotos([
                  ...(photosDocs || []),
                  ...(imagesPreviews || []),
                ])}
              </Slider>
            </div>
          ) : (
            <div className={styles.documentItem}>
              {!isAvatar && <IoIosPaper size={140} />}
              {isAvatar && <img src={avatar} width="200px" />}
            </div>
          )}
          {editMode && (
            <InputFile
              multiple
              title="Выберите изображение"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => handleUploadImage(e)}
            />
          )}
        </div>
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.yunarmians.profile,
});

export default connect(mapStateToProps, {
  editYunarmianProfile,
  uploadDocs,
  deleteDocs,
})(SliderComp);
