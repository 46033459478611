import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDepartments } from "../../actions/departments";
import { getAnalyticalReport } from "../../actions/reports";
import Loader from "../../components/Loader/Loader";
import styles from "./AnalyticalReport.module.css";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import logo from "../../assets/images/label.png";

import jsPDF from "jspdf";
import "jspdf-autotable";
import addFont from "../../fonts/fonts";
import moment from "moment";
import Select from "react-select";


const statusList = [
  {label: "Актив", value: "active"},
  {label: "Кандидат", value: "candidate"},
  {label: "Выбыл", value: "resigned"},
  {label: "Внесен ошибочно", value: "entered_by_mistake"},
  {label: "Неизвестный", value: "unknown"},
  {label: "Заявитель", value: "applicant"},
  {label: "Удален", value: "deleted"}
]

function AnalyticalReport({
  getDepartments,
  getAnalyticalReport,
  departments,
  data,
  loading,
  authData,
  detailsData,
  userRegions,
  accessRights
}) {

  const [regionName, setRegionName] = useState("");
  const [activeRows, setActiveRows] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [id, setId] = useState('');
  const [statuses, setStatuses] = useState(statusList);

  useEffect(() => {
    getDepartments();
    setId('')
  }, []);

  const title = "Аналитическая отчетность";
  const orientation = "l";

  const toPdf = () => {
    createDocData().then((doc) => doc.save(`${title}.pdf`));
  };

  const addFooters = (doc, img) => {
    const pageCount = doc.internal.getNumberOfPages();
    const ownDepartment = departments.find(
      (it) => it.id === authData.user.department_id
    );

    // doc.setFont('Montserrat')
    for (var i = 1; i <= pageCount; i++) {
      doc.setFontSize(6);
      doc.setPage(i);
      doc.addImage(img, "png", 5, 5, 25, 25);
      doc.text(
        `ШТАБ РЕГИОНАЛЬНОГО ОТДЕЛЕНИЯ ВСЕРОССИЙСКОГО ДЕТСКО-ЮНОШЕСКОГО ВОЕННО-ПАТРИОТИЧЕСКОГО ДВИЖЕНИЯ "ЮНАРМИЯ" ${ownDepartment?.name.toUpperCase()} - ${
          authData.user.phone
        } ${authData.user.email}`, 150, 205, { align: "center" });
      doc.text(`Отчет составлен пользователем: ${authData.displayname} ${moment().format("DD.MM.YYYY HH:mm")}`, 150, 208, "center")
      doc.setFontSize(10);
      doc.text(
        `ШТАБ РЕГИОНАЛЬНОГО ОТДЕЛЕНИЯ ВСЕРОССИЙСКОГО ДЕТСКО-ЮНОШЕСКОГО\n
          ВОЕННО-ПАТРИОТИЧЕСКОГО ДВИЖЕНИЯ "ЮНАРМИЯ" ${ownDepartment?.name.toUpperCase()}`,
        150,
        10,
        "center"
      );
    }
  };

  const createDocData = async () => {
    addFont(jsPDF.API);

    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: orientation,
    //   lineHeight: 0.5,
    });

    const img = new Image();
    img.src = logo;

    doc.setFont("Montserrat");

    doc.text("АНАЛИТИЧЕСКАЯ СПРАВКА", 150, 30, {
        align: "center",
    })

    doc.setFontSize(10).text(`по региону ${regionName}
    по состоянию на ${moment().format("DD.MM.YYYY")} года`, 150, 35, {
        align: "center",
    })
    
    doc.autoTable({
      html: `#analyticalReport`,
      margin: {
        top: 30
      },
      styles: { font: 'Montserrat', fontSize: 8 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        textColor: '#000',
      },
      footStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
      },
      showFoot: "lastPage",
      startY: 45
    //   columnStyles: {
    //     0: { cellWidth: 20 },
    //     1: { cellWidth: 60, halign: 'left' },
    //     2: { cellWidth: 35 },
    //     3: { cellWidth: 40 }
    //   }
    })

    addFooters(doc, img);

    
    doc.addPage("a4", orientation);
    doc.deletePage(doc.getCurrentPageInfo().pageNumber);
    
    return doc;
  };

  useEffect(() => {
    id && handleSelectDepartment(id)     
  }, [statuses])
  

  const handleSelectDepartment = async (e) => {
    setActiveRows([])
    const id = e.target.value
    if (e?.target?.value) {
      setId(e);
    }
    const name = departments.find(it => it.id==id)?.name
    if (name) {
        setLocalLoading(true)
        const response = await getAnalyticalReport(id, statuses.map(el => el.value).join())
        response && setRegionName(name)
        setLocalLoading(false)
    }
  };

  const handleRowClick = (ind) => () => {
    let newIndexes
    activeRows.includes(ind)
      ? newIndexes = activeRows.filter(index => index !== ind)
      : newIndexes = [...activeRows, ind]
    setActiveRows(newIndexes)
  }

  const filteredData = data && data.filter((el) => el.department_name!=="total")

  const depOptions = accessRights
    ? departments || []
    : departments?.filter(regionDep => userRegions.includes(regionDep.regionId)) || []


  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>

        <div className={styles.firstSelect}>
          <div>Выберите региональное отделение:</div>
          <select
            disabled={localLoading}
            onChange={handleSelectDepartment}
          >
            <option value=''>Не выбрано</option>
            {depOptions.map(dep => <option value={dep.id}>{dep.name}</option>)}
          </select>
        </div>

        <div className={styles.secondSelect}>
          <div>Выберите статусы:</div>
          <Select
            placeholder="Выберите статусы..."
            options={statusList}
            value={statuses}
            onChange={setStatuses}
            noOptionsMessage={() => "ничего не найдено"}
            // className={styles.filter}
            isMulti
          />
        </div>

        {filteredData && <ButtonRed onClick={toPdf} style={{margin: "0 0 .8rem 1rem"}}>Скачать PDF</ButtonRed>}
      
      </div>
        

      <div style={{marginTop: '2rem'}}>
        {loading ? (
          <Loader top="50%" left="50%" />
        ) : (filteredData && !!statuses.length && (
            <>
              <div className={styles.tableBlock}>
              <div className={styles.titleBlock}>
                  <h3>Аналитическая справка</h3>
                  <div>{`по региону "${regionName}" по состоянию на ${moment().format("DD.MM.YYYY")} года`}</div>
              </div>
              <div className={styles.tableWrapper}>
              <table id="analyticalReport">
                <thead>
                  <tr>
                    <th>Номер п/п</th>
                    <th>Местное отделение</th>
                    <th>Отрядов</th>
                    <th>Участники</th>
                    <th>Количество в АИС</th>
                    <th>Количество без фото</th>
                    <th>Количество без email</th>
                    <th>Не включены в отряды</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((it, i) => {
                      return (
                        <>
                          <tr key={i} onClick={handleRowClick(i)}>
                            <th>{it.row_number}</th>
                            <th style={{textAlign: "left"}}>{it.department_name}</th>
                            <td>{it.detachments_qty}</td>
                            <td>{it.yunarmians_qty}</td>
                            <td>{it.yunarmians_ais_qty}</td>
                            <td>{it.yunarmians_no_photos_qty}</td>
                            <td>{it.yunarmians_no_email_qty}</td>
                            <td>{it.yunarmians_with_no_detachment}</td>
                          </tr>
                          {activeRows.includes(i) &&
                            <>
                              {detailsData[i]?.length
                                ? detailsData[i].map((detItem, detInd) =>
                                    <tr className={styles.detailsRow}>
                                      <td>{++detInd}</td>
                                      <td>{detItem.name}</td>
                                      <td></td>
                                      <td>{detItem.yunarmians_qty}</td>
                                      <td></td>
                                      <td>{detItem.yunarmians_no_photos_qty}</td>
                                      <td>{detItem.yunarmians_no_email_qty}</td>
                                      <td></td>
                                    </tr>
                                  )
                                : <tr className={styles.detailsRow}>
                                    <td colSpan={8} style={{height: '26px', position: 'relative'}}>
                                      {detailsData[i]
                                        ? 'Данные отсутствуют'
                                        : <Loader size={20} top='2px' left="50%"/>
                                      }  
                                    </td>
                                  </tr>  
                              }
                            </>
                          }
                        </>
                      )
                    })}
                </tbody>
                <tfoot>
                  {data.find((el) => el.department_name==="total") && (
                    <tr>
                      <th colSpan={2}>ИТОГО:</th>
                      <th>
                        {data.find((el) => el.department_name==="total").detachments_qty}
                      </th>
                      <th>
                        {data.find((el) => el.department_name==="total").yunarmians_qty}
                      </th>
                      <th>
                        {data.find((el) => el.department_name==="total").yunarmians_ais_qty}
                      </th>
                      <th>
                        {
                          data.find((el) => el.department_name==="total")
                            .yunarmians_no_photos_qty
                        }
                      </th>
                      <th>
                        {
                          data.find((el) => el.department_name==="total")
                            .yunarmians_no_email_qty
                        }
                      </th>
                      <th>
                        {
                          data.find((el) => el.department_name==="total")
                            .yunarmians_with_no_detachment
                        }
                      </th>
                    </tr>
                  )}
                </tfoot>
              </table>
              </div>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  departments: state.departments.region_departments,
  data: state.reports.analytical_report,
  detailsData: state.reports.analytical_details,
  loading: state.reports.loading,
  detailsLoading: state.reports.detailsLoading,
  authData: state.auth,
  accessRights: state.auth.accessRights.find(i => i.name==='analytical_report')||{},
  userRegions: state.auth.userRegions
});

export default connect(mapStateToProps, {
  getDepartments,
  getAnalyticalReport,
})(AnalyticalReport);
