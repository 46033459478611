import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  getTourismCount,
  getTourismObject,
  deleteTourismObject,
  editTourismObject,
  addTourismObject,
  uploadImage,
  deleteImage,
  getRegions,
} from "../../actions/tourism";
import { setItemsOnPage } from "../../actions/auth";
import TourismModal from "./TourismModal";
import styles from "./Tourism.module.css";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import { Pagination } from "../../assets/common/Pagination/Pagination";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose, IoIosSearch } from "react-icons/io";
import { itemsCount } from "../../utils/const";
import { createNotification } from "../../components/Notifications/Notifications";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";

function Tourism({
  getTourismCount,
  getTourismObject,
  deleteTourismObject,
  editTourismObject,
  addTourismObject,
  uploadImage,
  deleteImage,
  pageCount,
  data,
  deleting = false,
  getRegions,
  setItemsOnPage,
  itemsOnPage,
  regions,
  accessRights,
  userRegions
}) {
  const [numberPage, setNumberPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [reg, setReg] = useState("");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  useEffect(() => {
    getRegions();
    getTourismObject(numberPage + 1, search, reg, sort);
  }, [numberPage, itemsOnPage, sort]);

  const openModal = (type, item) => () => {
    const userRegionEvents = type!=='ADD' && userRegions.filter(reg => item.regions.includes(reg))
    const readRights = accessRights.ac_read || (accessRights.ac_read_by_region && userRegionEvents.length)
    
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(type==='ADD'||readRights)
    !readRights && type!=='ADD' && createNotification('error', 'Доступ к данному туристическому объекту отсутствует')
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteDocument = (id) => {
    deleteTourismObject(id, numberPage + 1);
  };

  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const acceptConfirmation = () => {
    handleDeleteDocument(confirmation.id);
    setConfirmation(null);
  };

  const handleGet = (e) => {
    const value = e.target.value;
    setNumberPage(0);
    setReg(value);
    getTourismObject(1, search, value);
  };

  const handleSearch = () => {
    setNumberPage(0);
    getTourismObject(1, search, reg);
  };

  const handleCanselSearch = () => {
    setSearch("");
    setReg("");
    getTourismObject(numberPage + 1);
  };

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Познавательный туризм</div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>
            {(accessRights.ac_create || accessRights.ac_create_by_region) && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
            <div className={styles.searchBlock}>
              {!!regions && (
                <select
                  className={styles.regionSelect}
                  placeholder="Регион"
                  onChange={(e) => handleGet(e)}
                >
                  <option selected={reg === ""} value="">
                    Все
                  </option>
                  {regions.map((_reg) => (
                    <option
                      selected={_reg.id === reg}
                      key={_reg.id}
                      value={_reg.id}
                    >
                      {_reg.regionName + " " + _reg.federalUnit}
                    </option>
                  ))}
                </select>
              )}
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className={styles.searchInput}
              />
              {!!search && (
                <i className={styles.searchIcon} onClick={handleCanselSearch}>
                  <IoMdClose />
                </i>
              )}
              <i className={styles.searchIcon} onClick={handleSearch}>
                <IoIosSearch />
              </i>
            </div>
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Туристический объект</div>
            <div className={styles.username}></div>
            <div className={styles.superuser}></div>
            <div className={styles.actions} />
          </div>
          <div className={styles.table}>
            {data &&
              data.map((a) => {
                const deleteRights = accessRights.ac_delete || (accessRights.ac_delete_by_region && userRegions.includes(a.regionid))
                return (
                  <div key={a.id} className={styles.tableRow}>
                    <div
                      className={styles.displayname}
                      onClick={openModal("EDIT", a)}
                    >
                      <span>
                        {a.name}
                      </span>
                    </div>
                    <div className={styles.username}>{a.groupname}</div>
                    <div className={styles.superuser}></div>
                    <div className={styles.actions}>
                      {deleteRights
                        ? <IoMdClose onClick={() => setConfirmation({ text: a.name, id: a.id })}/>
                        : <div style={{height:'1.5rem'}}></div>
                      }
                    </div>
                  </div>
                )
              })}
          </div>
          <div className={styles.pagination}>
            <Pagination
              pageCount={pageCount}
              numberPage={numberPage}
              onPageChange={handlePageClick}
            />
          </div>
          <div className={styles.controls}>
            <div
              onClick={handleSortClick}
              className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
            </div>
            <div className={styles.itemsCount}>
              <select
                value={itemsOnPage}
                onChange={(e) => setItemsOnPage(e.target.value)}
              >
                {itemsCount.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <span>- строк на стр.</span>
            </div>
          </div>
        </div>
      </div>
      {confirmation && (
        <Confirmation
          confirmation={confirmation.text}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      )}
      {isModalOpen && (
        <TourismModal
          open={isModalOpen}
          type={modalType}
          onClose={closeModal}
          selectedItem={selectedItem}
          editObject={editTourismObject}
          addObject={addTourismObject}
          numberPage={numberPage}
          uploadImage={uploadImage}
          deleteImage={deleteImage}
          getRegions={getRegions}
          regions={regions}
          accessRights={accessRights}
          userRegions={userRegions}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  pageCount: state.tourism.page_count,
  data: state.tourism.data,
  regions: state.tourism.regions,
  lastUpdate: state.schedule.lastUpdate,
  itemsOnPage: state.auth.itemsOnPage,
  accessRights: state.auth.accessRights.find(i => i.name==='tourist_object')||{},
  userRegions: state.auth.userRegions
});

export default connect(mapStateToProps, {
  getTourismCount,
  getTourismObject,
  deleteTourismObject,
  editTourismObject,
  uploadImage,
  addTourismObject,
  deleteImage,
  setItemsOnPage,
  getRegions,
})(Tourism);
