import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import styles from "./Detachments.module.css";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import { MdPictureAsPdf } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import DetachmentParticipants from "./DetachmentParticipants";
import { IoIosArrowRoundForward } from "react-icons/io";
import { BACKEND_DOMAIN } from "../../utils/const";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { MdModeEdit } from "react-icons/md";
import Loader from "../../components/Loader/Loader";

const newDetachmentData = {
  chief: "",
  city: "",
  email: "",
  name: "",
  phone: "",
  position: "",
  domestic_detachment: true,
  parent: null ,
  detachment: true,
  regionId: null,
  decision: "",
  domestic_achievements: {
    vov: false,
    svo: false,
    afghanistan: false,
    local_wars: false,
    sportsman: false,
    cosmonaut: false,
    other: false,
    other_desc: '',
    achievement_desc: '',
  },
}

const DetachmentsModal = ({
  type,
  selectedItem,
  addDepartment,
  editDepartment,
  parentVal,
  localParentVal,
  uploadFile,
  deleteFile,
  data,
  getDetachmentDetails,
  removeDetachmentDetails,
  details,
  regionDep,
  localDep,
  getDepartments,
  removeLocalDep,
  troopTypes,
  parentDep,
  setParentDep,
  parentLocalDep,
  setParentLocalDep,
  domesticDep,
  setDomesticDep,
  numberPage,
  regions,
  history,
  profilesLoading,
  getAllYunarmiansData,
  removeDataForPrint,
  profiles,
  regionId,
  setRegionId,
  accessRights,
  userRegions,
  ...props
}) => {
  const [item, setItem] = useState(null);
  const [file, setFile] = useState({file: null, name: null});
  const [isDelete, setIsDelete] = useState(false);
  const [participantsMode, setParticipantsMode] = useState(false);
  const [options, setOptions] = useState([]);
  const [parentState, setParentState] = useState({reg: 0, local: 0});
  const [profilePopUp, setProfilePopUp] = useState(false)
  const [profileFilters, setProfileFilters] = useState({})
  const [profileName, setProfileName] = useState("Профиль не указан")
  
  const popUpRef = React.useRef();
  const updateRights = type==='ADD' || accessRights.ac_update || (accessRights.ac_update_by_region && userRegions.includes(selectedItem?.regionId))

  useEffect(() => {
    !Object.keys(profileFilters).length && removeDataForPrint()
    profileFilters.regions && getAllYunarmiansData(profileFilters)
  }, [profileFilters])
  
  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    }
  }, []);
  
  useEffect(() => {
    if (type === "ADD") {
      setItem({...newDetachmentData, parent: parentVal, regionId});
    } else if (selectedItem) {
      getDetachmentDetails(selectedItem.id)
      typeof(selectedItem.domestic_detachment) === "boolean"
        ? setItem(selectedItem)
        : setItem({ ...selectedItem, domestic_detachment: true });
    }
    setProfileName(selectedItem?.chief_id || "Профиль не указан")
  }, [selectedItem, parentVal]);  

  useEffect(() => {
    selectedItem
    && typeof(selectedItem.domestic_detachment) === "boolean"
    && details
    && details?.domestic_achievements
    && setItem({...selectedItem, domestic_achievements: details.domestic_achievements})
  }, [details])
  
  useEffect(() => {
    if (regionDep && item) {
      const regionDepartment = regionDep.find(dep => dep.regionId === item.regionId)
      item && parentDep == '0' && getDepartments(regionDepartment?.id)
    }
  }, [item?.parent, parentDep])
  
  const popUpFiltersChanges = (e) => {
    setProfileFilters(prev => ({...prev, [e.target.name] : e.target.value}))
  }
  
  let check = '';
  useEffect(() => {
    check = data && data.length && data.find(it => it.id === item?.id)
    setItem(check)
  }, [data])

  const profileClick = (profile) => {
    setItem(prev => ({...prev, chief_id: profile.id}))
    closePopUp()
    setProfileName(profile.id)
  }

  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (popUpRef.current && !path.includes(popUpRef.current)) {
      closePopUp()
    }
  };

  const closePopUp = () => {
    setProfilePopUp(false)
    removeDataForPrint()
  }

  const handleAddDepartment = () => {
    const obj = {...item, regionId: regionId}
    
    const par = (parentVal !== '0' && parentVal !== 0)
      ? parentVal
      : (parentState.local !== '0' &&  parentState.local !== 0)
        ? parentState.local
        : (parentState.reg !== '0' && parentState.reg !== 0)
          ? parentState.reg
          : '0' 
 
    if (parentVal==0) {
      obj.parent = par
    }
    if (par !== 'Выбрать' && par !== 0 && par !== '0' && item.name) {
      addDepartment(obj, parentVal, true, file, numberPage);
      setItem(null);
      props.onClose();
      setFile({file: null, name: null});
    } else {
      
    }
  };

  const handleEditDepartment = () => {
    delete item.parentname;
    editDepartment(item.id, item, item.parent, true, item.domestic_detachment, numberPage);

    if(file.file) {
      deleteFile(selectedItem.id, parentVal, false, numberPage, file, selectedItem.id);
      if (!selectedItem.imageurl) {
        uploadFile(item.id, file, item.parent);
        setFile({file: null, name: null});
      }
    }
    if (isDelete == true) {
      deleteFile(selectedItem.id, false, numberPage);
    }
    props.onClose();
    setItem(null);
  };
  
  const handleFile = (e) => {
    if(updateRights) {
      setFile({ file: e.target.files[0], name: e.target.value});
      setIsDelete(false);
    }
  };

  const handleDeleteFile = (e) => {
    if(updateRights) {
      setFile({file: null, name: null});
      setIsDelete(true);
    }
  };

  const handleClose = () => {
    setItem(null);
    setFile({file: null, name: null});
    setIsDelete(false);
    setParentState({reg: 0, local: 0})
    removeDetachmentDetails();
    props.onClose();
  }

  const handleInput = (name, e) => {
    if(updateRights && name != 'achievement_desc' && name != 'other_desc') {
      setItem((prev) => ({ ...prev, [name]: e.target.value }));
    } else if (updateRights && name == 'other_desc') {
      const newValue = {...item.domestic_achievements};
      newValue[name] = e.target.value;
      setItem((prev) => ({ ...prev, domestic_achievements: newValue }));
    } else if (updateRights && name == 'achievement_desc') {
      const newValue = {...item.domestic_achievements};
      newValue[name] = e.target.value;
      setItem((prev) => ({ ...prev, domestic_achievements: newValue }));
    }
  }

  const handleCheck = (name, e) => {
    if(updateRights) {
      const newValue = {...item.domestic_achievements};
      if (newValue.hasOwnProperty(name)) {
        newValue[name] = !item.domestic_achievements[name];
      } else {
        newValue[name] = true;
      }
      setItem((prev) => ({ ...prev, domestic_achievements: newValue }));
    }
  }

  useEffect(() => {
    if (regionDep && localDep && item) {
    const regionDepartment = regionDep.find(dep => dep.regionId === item.regionId)
    // const regionDepartment = regionDep.find(i => i?.id == ((parentDep !== '0' ? parentDep : item?.parent)))
    const arr = [regionDepartment, ...localDep]
    setOptions(arr)}
  }, [details, localDep, regionDep, item])

  const handleParent = (value, name) => {
    if (name == 'reg') {
      const regionId = regionDep.find(rd => rd.id==value).regionId
      setRegionId(regionId)
      setParentState(prev => ({...prev, reg: value}))
      getDepartments(value)
    } else if (name == 'local') {
      setParentState(prev => ({...prev, local: value}))
    }
  }

  return (
    <Modal
      onClose={handleClose}
      open={props.open}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {type === "ADD" ? "Добавление отряда" : "Редактирование отряда"}
      </div>
      {item && (
        participantsMode ?
        <DetachmentParticipants
          setParticipantsMode={setParticipantsMode}
          details={details}
        /> : 
        <div className={styles.modalBody}>
          <div>
            <CustomInput
              value={item?.name}
              title="Название отряда"
              disabled={!updateRights}
              onChange={(e) => handleInput('name', e)}
            />
            {
              type === "ADD" && parentVal == '0' && 
              <>
                <CustomSelect
                  withoutFirst
                  title="Региональное отделение"
                  options={regionDep && [{ name: 'Выбрать', value: 0 }, ...regionDep].map((it) => ({ name: it?.name, value: it?.id }))}
                  selectedValue={parentState.reg}
                  disabled={!updateRights}
                  onChange={(e) => handleParent(e.target.value, 'reg')}
                />

              {
                localDep && parentState.reg !== 0 &&
                <CustomSelect
                withoutFirst
                title="Родительское отделение"
                options={localDep && [{ name: 'Выбрать', value: 0 }, ...localDep].map((it) => ({ name: it?.name, value: it?.id }))}
                selectedValue={parentState.local}
                disabled={!updateRights}
                onChange={(e) => handleParent(e.target.value, 'local')}
              />
              }
              </>
            }

            {item.parent !== '0' && 
              <CustomSelect
              withoutFirst
              title="Родительское отделение"
              options={options && options.map((it) => ({ name: it?.name, value: it?.id }))}
              selectedValue={item.parent}
              disabled={!updateRights}
              onChange={(e) => handleInput('parent', e)}
            />}
            <div className={styles.radioWrap}>
              <div>Выберите категорию отряда:</div>
              <div className={styles.radioBlock}>
                <label>
                  <input
                    type="radio"
                    name="detachments"
                    value={false}
                    disabled={!updateRights}
                    onChange={(e) =>
                      setItem((prev) => ({
                        ...prev,
                        domestic_detachment: false,
                        profile_detachment: null
                      }))
                    }
                    checked={item.domestic_detachment===false && !item.profile_detachment}
                  />
                  Отряды
                </label>
                <label>
                  <input
                    type="radio"
                    name="detachments"
                    value={true}
                    disabled={!updateRights}
                    onChange={(e) =>
                      setItem((prev) => ({
                        ...prev,
                        domestic_detachment: true,
                        profile_detachment: null
                      }))
                    }
                    checked={item.domestic_detachment===true}
                  />
                  Именные отряды
                </label>
                <label>
                  <input
                    type="radio"
                    name="detachments"
                    value={true}
                    disabled={!updateRights}
                    onChange={(e) =>
                      setItem((prev) => ({
                        ...prev,
                        domestic_detachment: false,
                        profile_detachment: troopTypes[0]
                      }))
                    }
                    checked={item.domestic_detachment===false && item.profile_detachment}
                  />
                  Профильные отряды
                </label>
              </div>
            </div>
            {item.profile_detachment &&
            <div className={styles.radioWrap}>
              <div>Выберите профиль:</div>
              <div className={styles.radioBlock}>
                {troopTypes.map(type => (
                  <label>
                    <input
                      type="radio"
                      name="profileType"
                      disabled={!updateRights}
                      onChange={(e) =>
                        setItem((prev) => ({
                          ...prev,
                          profile_detachment: type
                        }))
                      }
                      checked={item.profile_detachment===type}
                    />
                    {type}
                  </label>
                ))}
              </div>
            </div>}
            <CustomInput
              value={item.city || ''}
              title="Населенный пункт"
              disabled={!updateRights}
              onChange={(e) => handleInput('city', e)}
            />
            <div style={{display: "flex", alignItems: "flex-start"}}>
              <CustomInput
                value={item.chief  || ''}
                title="Руководитель"
                width="75%"
                disabled={!updateRights}
                onChange={(e) => handleInput('chief', e)}
              />
              <div
                className={styles.profileBlock}
                ref={popUpRef}
              >
                <div>Профиль:</div>
                {updateRights && <MdModeEdit onClick={() => setProfilePopUp(true)}/>}
                <a
                  className={styles.profileName}
                  href={item?.chief_id && `/profile/${item.chief_id}`}
                  onClick={() => !item?.chief_id && setProfilePopUp(true)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {profileName}
                </a>
                {profilePopUp &&
                  <div className={styles.popup}>
                    <CustomSelect
                      selwidth="100%"
                      title="Выберите регион"
                      name="regions"
                      options={regions.map(it=>({name: `${it.regionName} ${it.federalUnit}`, value: it.id}))}
                      onChange={popUpFiltersChanges}
                      />
                    <CustomInput
                      title="Фамилия"
                      name="lastName"
                      onChange={popUpFiltersChanges}
                    />
                    <CustomInput
                      title="Имя"
                      name="firstName"
                      onChange={popUpFiltersChanges}
                    />
                    <CustomInput
                      title="Отчество"
                      name="fathersName"
                      onChange={popUpFiltersChanges}
                    />
                    <div className={styles.popupLabel}>Выберите профиль:</div>
                    <div className={styles.popupProfiles}>
                      {profilesLoading
                        ? <Loader size={40} top="20%" left="45%"/>
                        : !!profiles?.length && profiles.map(it =>
                            <p onClick={() => profileClick(it)}>
                              {`${it.lastName} ${it.firstName} ${it.fathersName} (${it.personalId})`}
                            </p>
                          )
                      }
                    </div>
                  </div>
                }
            </div>
            </div>
            <CustomInput
              value={item.position  || ''}
              title="Должность"
              disabled={!updateRights}
              onChange={(e) => handleInput('position', e)}
            />
            <CustomInput
              value={item.phone  || ''}
              title="Телефон"
              disabled={!updateRights}
              onChange={(e) => handleInput('phone', e)}
            />
            <CustomInput
              value={item.email  || ''}
              title="Email"
              disabled={!updateRights}
              onChange={(e) => handleInput('email', e)}
            />
            <CustomInput
              value={item.decision  || ''}
              title="Основание для создания"
              disabled={!updateRights}
              onChange={(e) => handleInput('decision', e)}
              max="300"
            />
            {
              item.domestic_detachment === true 
              ?
              <div className={styles.wrapperWar}>
                <CustomCheckbox
                label={`Участник ВОВ`}
                value={item.domestic_achievements?.vov}
                disabled={!updateRights}
                onChange={(e) => handleCheck('vov', e)}
                /> 
                <CustomCheckbox
                label={`Участник СВО`}
                value={item.domestic_achievements?.svo}
                disabled={!updateRights}
                onChange={(e) => handleCheck('svo', e)}
                /> 
                <CustomCheckbox
                label={`Участник Афганской войны`}
                value={item.domestic_achievements?.afghanistan}
                disabled={!updateRights}
                onChange={(e) => handleCheck('afghanistan', e)}
                /> 
                <CustomCheckbox
                label={`Участник локальных войн`}
                value={item.domestic_achievements?.local_wars}
                disabled={!updateRights}
                onChange={(e) => handleCheck('local_wars', e)}
                /> 
                <CustomCheckbox
                label={`Выдающийся спортсмен`}
                value={item.domestic_achievements?.sportsman}
                disabled={!updateRights}
                onChange={(e) => handleCheck('sportsman', e)}
                /> 
                <CustomCheckbox
                label={`Космонавт `}
                value={item.domestic_achievements?.cosmonaut}
                disabled={!updateRights}
                onChange={(e) => handleCheck('cosmonaut', e)}
                /> 
                <CustomCheckbox
                label={`Иное `}
                value={item.domestic_achievements?.other}
                disabled={!updateRights}
                onChange={(e) => handleCheck('other', e)}
                /> 
              </div>
              :
              <></>
            }

            { item.domestic_detachment === true && 
            (
              item.domestic_achievements?.other == true) 
              ?
              <CustomInput
              value={item.domestic_achievements?.other_desc}
              title="Описание иного"
              fontSize={'16px'}
              disabled={!updateRights}
              onChange={(e) => handleInput('other_desc', e)}
              max="50"
              />
              :
              <></>
            }

            { item.domestic_detachment === true && 
            ( item.domestic_achievements?.vov == true || 
              item.domestic_achievements?.svo == true ||
              item.domestic_achievements?.afghanistan == true ||
              item.domestic_achievements?.local_wars == true ||
              item.domestic_achievements?.sportsman == true ||
              item.domestic_achievements?.cosmonaut == true ||
              item.domestic_achievements?.other == true) 
              ?
              <CustomInput
              value={item.domestic_achievements?.achievement_desc}
              title="Описание подвига"
              fontSize={'16px'}
              disabled={!updateRights}
              onChange={(e) => handleInput('achievement_desc', e)}
              max="250"
              />
              :
              <></>
            }

            {updateRights &&
            <div onClick={() => setParticipantsMode(true)} className={styles.goForward}>
              Состав отряда
              <IoIosArrowRoundForward size={20} />
            </div>}
          </div>
            {updateRights && 
              <div className={styles.input}>
                {(item && item !== null && item?.imageurl && !isDelete) || (file.file !== null && !isDelete) ? 
                <div className={styles.pdf}>
                  <a
                    href={`${BACKEND_DOMAIN || ""}${item.imageurl}`}
                    className={styles.linkpdf}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={styles.imagePDF}><MdPictureAsPdf/></i>
                    <span className={styles.titlePDF}>{file?.name || item?.imageurl.split('/')[item?.imageurl.split('/').length-1]}</span>
                  </a>
                  <i className={styles.toTrash} onClick={(e) => handleDeleteFile(e)}><IoMdTrash/></i>
                </div>
              :
              <InputFile 
                    accept="application/pdf" 
                    title="Выберите файл для загрузки" 
                    onChange={(e) => handleFile(e)} />
              }
              </div>}
          <div className={styles.modalButtons}>
            {updateRights
              ? <ButtonRed onClick={type === "ADD" ? handleAddDepartment : handleEditDepartment}>
                  {type === "ADD" ? "Добавить" : "Сохранить"}
                </ButtonRed>
              : <></>
            }
            <ButtonRed onClick={handleClose}>Закрыть</ButtonRed>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DetachmentsModal;
