import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import {
  SET_TASK_COUNT,
  SET_TASK_DATA,
  SET_TASK_ARTICLES,
  SET_STEPS_DATA,
  ERROR,
  SET_TASK_LOADING,
  SET_TASK_PROFILES,
  SET_TASK_REALIZATION,
  SET_QUESTS_TASKS,
  SET_STEPS_DATA_LOAD
} from "./types.js";
import { createNotification } from "../components/Notifications/Notifications";
import moment from "moment";

export const getTaskCount = (questId=null, filters) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const newsOnPage = getState().auth.itemsOnPage
    const params = questId ? {quest: questId} : {}
    filters.events.length && (params.eventId = filters.events.map(i => i.value).join(','))
    filters.calendarDED && (params.calendarDED = true)
    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/task/count?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const pageCount = Math.ceil(data / newsOnPage);
      dispatch({
        type: SET_TASK_COUNT,
        payload: { data: data, pageCount: pageCount },
      });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getTaskObject =
  (page, questId=null, filters, sort ) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch(getArticles())
      const params = {}
      const newsOnPage = getState().auth.itemsOnPage
      const offset = page * newsOnPage - newsOnPage;
      
      page && (params.offset = offset)
      page && (params.limit = newsOnPage)
      questId && ( params.quest = questId )
      filters && filters.events.length && (params.eventId = filters.events.map(i => i.value).join(','))
      filters && filters.calendarDED && (params.calendarDED = true)
      sort && (params.alph = sort)

      const urlParams = new URLSearchParams(params).toString();
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/task?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        
        const data = await response.json();
        const actionType = questId ? SET_QUESTS_TASKS : SET_TASK_DATA
        dispatch({ type: actionType, payload: data });
       
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
};

  export const getArticles = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/articles/2?web=true`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({
          type: SET_TASK_ARTICLES,
          payload: data,
        });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteTaskObject =
  (id, numberPage, questId=null, filters) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/task/${id}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        dispatch(getTaskCount(questId, filters));
        dispatch(getTaskObject(numberPage, questId, filters));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const editTaskObject =
  (id, data, numberPage, questId=null, filters) => async (dispatch, getState) => {
    try {
      await dispatch(checkAndUpdateToken());
      dispatch({ type: SET_TASK_LOADING, payload: true });
      if (getState().auth.token) {
        const payload = {
          ...data,
          date_start: moment.utc(data.date_start).format("DD.MM.YYYY HH:mm:ss"),
          date_end: moment.utc(data.date_end).format("DD.MM.YYYY HH:mm:ss")
        }
        const response = await fetch(
          `${BACKEND_URL_PREFIX}/task/${id}`,
          {
            method: "PUT",
            body: JSON.stringify(payload),
            ...buildDefaultHeaders(getState),
          }
          );
          if (response.ok) {
            dispatch(getTaskObject(numberPage, questId, filters));
            return true
          } else {
            const respData = await response.json() 
            const errorText = !respData.error||respData.error==="Bad request"
              ? 'Непредвиденная ошибка'
              : respData.error
            createNotification('error', errorText)
            dispatch({ type: ERROR, payload: response });
          }
        }
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_TASK_LOADING, payload: false });
    }
    };
    
    export const addTaskObject =
    (data, numberPage, image, questId=null, filters) => async (dispatch, getState) => {
      await dispatch(checkAndUpdateToken());
      if (getState().auth.token) {
        questId && (data.quest = questId)
        const payload = {
          ...data,
          date_start: moment(data.date_start).format("DD.MM.YYYY HH:mm:ss"),
          date_end: moment(data.date_end).format("DD.MM.YYYY HH:mm:ss")
        }
      const response = await fetch(`${BACKEND_URL_PREFIX}/task`, {
        method: "POST",
        body: JSON.stringify(payload),
        ...buildDefaultHeaders(getState),
      });
      const respData = await response.json();
      if (response.ok && image.file) {
        dispatch(uploadImage(respData.id, image, numberPage, questId, filters));
        dispatch(getTaskCount(questId, filters));
        dispatch(getTaskObject(numberPage, questId, filters));
        return true
      } else {
        createNotification('error', respData.error||'Непредвиденная ошибка')
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const uploadImage =
  (id, image, numberPage, questId=null, filters) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    const fd = new FormData();
    fd.append("file", image.file);

    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/task/${id}/image`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
        }
      );
      if (response.ok) {
        dispatch(getTaskCount(questId, filters));
        dispatch(getTaskObject(numberPage, questId, filters));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteImage = (id, numberPage, questId=null, filters) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/task/${id}/image`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      dispatch(getTaskCount(questId, filters));
      dispatch(getTaskObject(numberPage, questId, filters));
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

  export const getSteps =
  (page=1, id) =>
  async (dispatch, getState) => {
    dispatch({ type: SET_STEPS_DATA_LOAD, payload: true });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch(getArticles())
      const newsOnPage = getState().auth.itemsOnPage
      const offset = page * newsOnPage - newsOnPage;
      const params = {
        offset: offset,
        limit: newsOnPage
      }
      const urlParams = new URLSearchParams(params).toString();
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/task/${id}/step?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        
        const data = await response.json();
        dispatch({ type: SET_STEPS_DATA, payload: data });
       
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch({ type: SET_STEPS_DATA_LOAD, payload: false });
  };

  export const addTaskStep =
  (id, data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/task/${id}/step`, {
        method: "POST",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        dispatch(getSteps(0,id));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const deleteTaskStep = (id, stepId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/task/${id}/step/${stepId}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        dispatch(getSteps(0,id));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const editTaskStep =
  (id, stepId, data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/task/${id}/step/${stepId}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        dispatch(getSteps(0,id));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
};

export const getTaskProfiles =
  (id, params) =>
  async (dispatch, getState) => {
    // dispatch({type: SET_TASK_LOADING, payload: true})
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const _params = {}
      
      params.regionId !== 'all' && (_params.profile_region_id = params.regionId)
      params.regionId !== 'all' && params.domestic_department !== 'all' && (_params.domestic_department = params.domestic_department)
      params.domestic_department !== 'all' && params.detachment !== 'all' && (_params.detachment = params.detachment)
      params.name && (_params.profile_name = params.name)
      params.status !== "all" && (_params.status = params.status)
      
      const urlParams = new URLSearchParams(_params).toString();
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/task/${id}/profile_task?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        
        const data = await response.json();
        dispatch({ type: SET_TASK_PROFILES, payload: data });
       
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    // dispatch({type: SET_TASK_LOADING, payload: false})
};

export const changeCompleteStatus =
(data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const taskId = data.task_id
    const profileId = data.profile_id
    const payload = {status: data.status}
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/task/${taskId}/profile/${profileId}`,
      {
        method: "PUT",
        body: JSON.stringify(payload),
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      createNotification('success', 'Статус изменен успешно')
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getTaskRealization = (taskId, profileId) => async (dispatch, getState) => {
  // dispatch({type: SET_TASK_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/task/${taskId}/profile/${profileId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({type: SET_TASK_REALIZATION, payload});
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  // dispatch({type: SET_TASK_LOADING, payload: false})
};