import styles from "./ButtonRed.module.css"

export const ButtonRed = ({
    onClick = () => {},
    style = {},
    type = "button",
    title = "",
    ...props
}) => {
    return (
        <button
            className={styles.button}
            title={title}
            style={style}
            type={type}
            onClick={onClick}
        >
            {props.children}
        </button>
    )
}

