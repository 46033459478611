import React, { useEffect, useState } from "react";
import styles from "./Import.module.css";
import { connect } from "react-redux";
import { uploadExcelFile, clearImportData, getImportsHistory, getImportsById, deleteImportsById } from "../../actions/import";
import { IoIosArrowDown } from "react-icons/io";
import { RiFileExcel2Line, RiDownloadFill } from "react-icons/ri";
import Loader from "../../components/Loader/Loader";
import { BACKEND_DOMAIN } from "../../utils/const";
import moment from "moment/moment";
import { ImTable } from "react-icons/im";

function Import({
  uploadExcelFile,
  data,
  loading,
  clearImportData,
  importsHistory,
  getImportsHistory,
  getImportsById,
  importsById,
  importsByIdLoad,
  deleteImportsById
}) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [visibleInstruction, setVisibleInstruction] = useState(false);
  const [visibleCheck, setVisibleCheck] = useState(null);
  const [duplicate, setDuplicate] = useState(null);
  const [historyId, setHistoryId] = useState(null);

  useEffect(() => {
    return () => {
      clearImportData()
    }
  }, [])


  // useEffect(() => {
  //   if (data) {
  //     const duplicateList = data.error.filter(el => el["Ошибка"]==="Дубликат")
  //     setDuplicate(duplicateList)
  //   }
  // }, [data])
  

  const handleUploadFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const agreement = () => {
    if (file) {
      uploadExcelFile({ file: file }).then((res) => {
        setFile(null);
        setFileName(null);
      });
    }
  };

  const refusal = () => {
    setFile(null);
    setFileName(null);
  };

  const handleImportHistory = () => {
    getImportsHistory();
    deleteImportsById();
    setHistoryId(null);
  }

  const getRuName = (name) => {
    if (name == 'in progress') {
      return 'В процессе'
    } else if (name == 'failed') {
      return 'Ошибка'
    } else if (name == 'success') {
      return 'Завершено'
    } else {
      return ''
    }
  }

  const handelGetByID = (id) => {
    setHistoryId(id);
    getImportsById(id);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <div>
          {loading ? (
            <Loader top="50%" left="50%" />
          ) : (
            <div className={styles.importBlock}>
              <div className={styles.buttonsBlock}>
                <div>
                <label>
                  <div>
                    <RiFileExcel2Line size={20} />
                    Импорт из Excel
                  </div>
                  <input type="file" onChange={handleUploadFile} />
                </label>
                <div className={styles.dlbutton} onClick={() => handleImportHistory()}><i className={styles.iconTable}><ImTable/></i>Результаты импорта</div>
                </div>
                <a
                  href={`${BACKEND_DOMAIN || ""}/files/profiles_template.xlsx`}
                >
                  <div className={styles.dlbutton}>
                    <RiDownloadFill size={20} />
                    Скачать шаблон xlsx
                  </div>
                </a>
                <div className={styles.dlbutton} onClick={() => setVisibleInstruction(prev => !prev)}>Инструкция</div>
              

              <div className={styles.tableWrapper}>
              {
                importsHistory && 
                <table>
                  <thead className={styles.tHead}>
                    <tr>
                        <th colSpan={4} style={{'textAlign': 'center', 'fontSize': '1.2rem', 'width': '150px'}}>Ваши импорты</th>
                    </tr>
                    <tr>
                      <th colSpan={1}>Дата создания импорта</th>
                      <th colSpan={1} width="200px">Статус</th>
                      <th colSpan={1}>Успешно</th>
                      <th colSpan={1}>Ошибок</th>
                    </tr>
                  </thead>
                  <tbody className={styles.tBody}>
                      {
                        importsHistory?.import_excel_details?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(el => 
                          <React.Fragment key={el.task_id}>
                          <tr onClick={() => handelGetByID(el.task_id)}>
                            <th
                            style={{
                              'width': '26%', 
                              'backgroundColor': `${historyId == el.task_id ? 'rgb(55, 104, 100)'  : ''}`,
                              'color': `${historyId == el.task_id ? 'rgb(245, 162, 107)'  : ''}`
                            }}
                            >{el?.created_at ? moment(el?.created_at).format("DD.MM.YYYY") : ''}</th>
                            <th 
                            style={{
                              'position': 'relative', 
                              'width': '25%', 
                              'backgroundColor': `${historyId == el.task_id ? 'rgb(55, 104, 100)'  : ''}`,
                              'color': `${historyId == el.task_id ? 'rgb(245, 162, 107)'  : ''}`
                            }} 
                            colSpan={1}
                            >
                              {getRuName(el?.status)}
                              {importsByIdLoad && historyId == el.task_id ? <Loader size={30} left={90} top={0} color={'rgb(245, 162, 107)'}/> : <></>}
                            </th>
                            <th
                            style={{
                              'position': 'relative', 
                              'width': '25%', 
                              'backgroundColor': `${historyId == el.task_id ? 'rgb(55, 104, 100)'  : ''}`,
                              'color': `${historyId == el.task_id ? 'rgb(245, 162, 107)'  : ''}`
                            }}>
                              {el?.success}
                            </th>
                            <th
                            style={{
                              'position': 'relative', 
                              'width': '25%', 
                              'backgroundColor': `${historyId == el.task_id ? 'rgb(55, 104, 100)'  : ''}`,
                              'color': `${historyId == el.task_id ? 'rgb(245, 162, 107)'  : ''}`
                            }}>
                              {el?.error}
                            </th>
                          </tr> 

                          {importsById?.import_excel_details?.task_id == el.task_id && importsById?.import_excel_details?.result?.sucess.length > 0 &&
                            <tr>
                            <th>Кол-во строк</th>
                            <th 
                            style={{'position': 'relative'}} 
                            colSpan>
                              ФИО
                            </th>
                            <th 
                            style={{'position': 'relative'}} 
                            colSpan="3">
                              Ошибка
                            </th>
                          </tr> 
                          }
                          {importsById?.import_excel_details?.task_id == el.task_id && importsById?.import_excel_details?.result?.sucess?.map(_el => 
                            <tr style={{"background": "#39d869"}}>
                              <th>{_el['row']}</th>
                              <th colSpan>{_el['Имя '] + ' ' + _el['Фамилия'] + ' ' + _el['Отчество']}</th>
                              <th 
                              style={{'position': 'relative'}} 
                              colSpan="3">
                                Без ошибок
                            </th>
                            </tr>
                          )
                          }

                          {importsById?.import_excel_details?.task_id == el.task_id && importsById?.import_excel_details?.result?.error.length > 0 &&
                            <tr>
                            <th>Кол-во строк</th>
                            <th 
                            style={{'position': 'relative'}} 
                            colSpan>
                              ФИО
                            </th>
                            <th 
                            style={{'position': 'relative'}} 
                            colSpan="3">
                              Ошибка
                            </th>
                          </tr> 
                          }

                          {importsById?.import_excel_details?.task_id == el.task_id && importsById?.import_excel_details?.result?.error?.map(_el => 
                            <tr style={{"background": "rgb(241, 70, 70)"}}>
                              <th>{_el['row']}</th>
                              <th>{_el['Имя '] + ' ' + _el['Фамилия'] + ' ' + _el['Отчество']}</th>
                              <th colSpan={3}>{_el['Ошибка']}</th>
                            </tr>
                          )
                          }
                          </React.Fragment> 
                        )
                      }
                      </tbody>
                </table>
              }
              </div>
            </div>
              {fileName && (
                <div
                  className={styles.confWrapper}
                  onClick={refusal}
                >
                  <div
                    className={styles.confirmation}
                    onClick={e => e.stopPropagation()}
                  >
                    <div>{`Вы уверены что хотите выполнить импорт профилей из файла ${fileName}?`}</div>
                    <div>
                      <button onClick={agreement}>Да</button>
                      <button onClick={refusal}>Нет</button>
                    </div>
                  </div>
                </div>
              )}
              {duplicate && !!duplicate.length && (
                <div
                  className={styles.confWrapper}
                  onClick={()=> setDuplicate(null)}
                >
                  <div
                    className={styles.confirmation}
                    onClick={e => e.stopPropagation()}
                  >
                    <h3>Список уже существующих профилей:</h3>
                    <ul>
                      {duplicate.map(item => <li>{`${item["Фамилия"]} ${item["Имя "]} ${item["Отчество"]}`}</li>)}
                    </ul>
                  </div>
                </div>
              )}
              {data && (
                <div className={styles.importedBlock}>
                  {data.sucess && (
                    <div>
                      <div
                        onClick={() => setOpenSuccess((prev) => !prev)}
                        className={styles.listTitle}
                      >
                        {`Успешно загруженные (${data.sucess.length}/${
                          data.sucess.length + data.error.length
                        })`}
                        <IoIosArrowDown size={20} />
                      </div>
                      {openSuccess &&
                        (data.sucess.length ? (
                          <div className={styles.peopleList}>
                            {data.sucess.map((it) => (
                              <div>
                                <span>{it.row}</span>
                                <span>{`${it["Фамилия"]} ${it["Имя "]} ${it["Отчество"]}`}</span>
                                <div></div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className={styles.peopleList}>
                            <div style={{ textAlign: "center" }}>
                              Отсутствуют
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {data.error && (
                    <div>
                      <div
                        onClick={() => setOpenError((prev) => !prev)}
                        className={styles.listTitle}
                        style={{ backgroundColor: "rgb(241, 70, 70)", width: "600px" }}
                      >
                        {`Неуспешно загруженные (${data.error.length}/${
                          data.sucess.length + data.error.length
                        })`}
                        <IoIosArrowDown size={20} />
                      </div>
                      {openError &&
                        (data.error.length ? (
                          <div className={styles.peopleList} style={{width: "600px"}}>
                            {data.error.map((it,i) => (
                              <div style={{ position: "relative" }}>
                                <span>{it.row}</span>
                                <span>{`${it["Фамилия"]} ${it["Имя "]} ${it["Отчество"]}`}</span>
                                <span onMouseEnter={() => setVisibleCheck(i)} onMouseLeave={() => setVisibleCheck(null)} >{it["Ошибка"]}</span>
                                { visibleCheck==i && <div className={styles.checkBlock}>{it["Ошибка"]}</div>}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className={styles.peopleList}>
                            <div style={{ textAlign: "center" }}>
                              Отсутствуют
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {visibleInstruction && <div>
          <div className={styles.instrWrapper} onClick={() => setVisibleInstruction(false)}>
            <div className={styles.instruction} onClick={(e) => {e.stopPropagation()}}>
              <h3>Инструкция</h3>
              <ul className={styles.firstLevel}>
                <li>
                  На вкладке "Импорт" данной страницы скачиваете файл{" "}
                  <a
                    href={`${BACKEND_DOMAIN || ""}/files/profiles_template.xlsx`}
                  >
                    xlsx
                  </a>
                  .
                </li>
                <li>
                  Редактируете его согласно формату данных:
                  <ul>
                    <li>
                      <b>
                        Поля "ID" и "Личный номер" не редактировать!
                        Генерируется автоматически для всех профилей
                      </b>
                    </li>
                    <li>
                      <b>"Пол"</b> - варианты ответа: мужской, женский
                    </li>
                    <li>
                      <b>"День рождения", "Дата заполнения анкеты"</b> - формат
                      даты ДД.ММ.ГГГГ
                    </li>
                    <li>
                      <b>"Статус"</b> - варианты ответа: Кандидат, Актив, Выбыл
                    </li>
                    <li>
                      <b>"Код региона"</b> - код региона со 2-й странички в xlsx
                      файле
                    </li>
                    <li>
                      Все поля <b>"Телефон"</b> - 79991112233
                    </li>
                    <li>
                      <b>
                        "Серия-номер основного документа (свидетельство о
                        рождении/паспорт)"
                      </b>{" "}
                      - варианты ответа: V-ФС № 123456 или 1234 567890
                    </li>
                    <li>
                      <b>"Образовательная организация Класс"</b> - только номер
                      класса, без букв
                    </li>
                    <li>
                      <b>
                        "Опыт руководства группой активности", "Опыт участия в
                        олимпиадах",
                      </b>
                      <br />
                      <b>
                        "Опыт участия в акциях и проектах", "Опыт участия в
                        спортивных соревнованиях",
                      </b>
                      <br />
                      <b>
                        "Опыт участью в мероприятиях ЮНАРМИИ", "Посещение
                        спортивных секций",
                      </b>
                      <br />
                      <b>"Флаг для руководителей", "В браке",</b>
                      <br />
                      <b>
                        "Есть ли ученая степень", "Имеется ли параллельное место
                        работы"
                      </b>{" "}
                      - варианты ответа: да, нет
                    </li>
                    <li>
                      <b>"Группа крови"</b> - варианты ответа:
                      <ul>
                        <li>O(I) Rh- (Первая отрицательная)</li>
                        <li>O(I) Rh+ (Первая положительная)</li>
                        <li>A(II) Rh- (Вторая отрицательная)</li>
                        <li>A(II) Rh+ (Вторая положительная)</li>
                        <li>B(III) Rh- (Третья отрицательная)</li>
                        <li>B(III) Rh+ (Третья положительная)</li>
                        <li>AB(IV) Rh- (Четвертая отрицательная)</li>
                        <li>AB(IV) Rh+ (Четвертая положительная)</li>
                      </ul>
                    </li>
                    <li>
                      <b>"Группа здоровья"</b> - варианты ответа:
                      <ul>
                        <li>I группа здоровья</li>
                        <li>II группа здоровья</li>
                        <li>III группа здоровья</li>
                        <li>IV группа здоровья</li>
                        <li>V группа здоровья</li>
                      </ul>
                    </li>
                    <li>
                      <b>"Образование"</b> - варианты ответа:
                      <ul>
                        <li>Основное общее образование</li>
                        <li>Среднее общее образование</li>
                        <li>Среднее профессиональное образование</li>
                        <li>Высшее образование — бакалавриат</li>
                        <li>Высшее образование — специалитет</li>
                        <li>Высшее образование — магистратура</li>
                        <li>
                          Высшее образование — подготовка кадров высшей
                          квалификации
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>"Выполнение норм ГТО"</b> - варианты ответа: золото,
                      бронза, серебро
                    </li>
                    <li>
                      <b>"Фото", "Фото документов", "Фото паспорта"</b> -
                      название файла с расширением jpg или png, например:
                      photo.jpg
                      <br />
                      Если несколько фото, то вводите их названия через запятую
                      <b> БЕЗ пробелов:</b> photo_1.jpg,photo_2.jpg,photo_3.png
                    </li>
                    <li>
                      <b>"Флаг для начальника"</b> - ставить да, если анкета
                      заполняется для начальника отряда
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.importProfiles.data,
  loading: state.importProfiles.loading,
  importsHistory: state.importProfiles.importsHistory,
  importsById: state.importProfiles.importsById,
  importsByIdLoad: state.importProfiles.importsByIdLoad,
});

export default connect(mapStateToProps, { uploadExcelFile, clearImportData, getImportsHistory, getImportsById, deleteImportsById })(Import);
