import React, {  useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  getDepartments,
  deleteDepartment,
  editDepartment,
  removeDetachments,
  addDepartment,
  uploadFile,
  deleteFile,
  getDetachmentDetails,
  removeDetachmentDetails,
  getRegions,
  getDepartmentsExcel,
  getDepartmentsAll,
  getDepartmentsCount,
  removeLocalDep,
  exelLoadingStop,
  getTroopTypes
} from "../../actions/departments";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import DetachmentsModal from "./DetachmentsModal";
import styles from "./Detachments.module.css"
import CustomSelect from "../../components/CustomSelect/CustomSelect"
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose } from "react-icons/io";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { RiFileExcel2Line, RiLoader2Fill, RiLoader2Line, RiLoader3Fill } from "react-icons/ri";
import { MdPictureAsPdf } from "react-icons/md";
import { createNotification } from "../../components/Notifications/Notifications";
import { Pagination } from "../../assets/common/Pagination/Pagination";
import departments from "../../reducers/departments";
import Loader from "../../components/Loader/Loader";
import { getAllYunarmiansData, removeDataForPrint } from "../../actions/yunarmiyans";
import { getRegions as getRegionsAll } from "../../actions/users";
import { getRegionDepsOptions, getUserRegionDepsOptions } from "../../utils/getOptions";

import logo from '../../assets/images/label.png';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import addFont from '../../fonts/fonts'
import { selects } from "../../utils/const";
import moment from "moment";
import CustomInput from "../../components/CustomInput/CustomInput";
import { getExcelFromTable } from "../../utils/excel";


const detachmentsCategories = [
  {name: "Все", value: ''},
  {name: "Именные отряды", value: true},
  {name: "Профильные отряды", value: false}
]

const Detachments = ({
  regionDep,
  localDep,
  detachments,
  getDepartments,
  deleteDepartment,
  editDepartment,
  addDepartment,
  uploadFile,
  deleteFile,
  getDetachmentDetails,
  detailsLoading,
  removeDetachmentDetails,
  details,
  getRegions,
  id,
  getDepartmentsExcel,
  excel,
  getDepartmentsAll,
  profilesLoading,
  count,
  getDepartmentsCount,
  regList,
  removeDetachments,
  removeLocalDep,
  loading,
  troopTypes,
  getTroopTypes,
  exelLoadingStop,
  excelLoading,
  regions,
  history,
  getAllYunarmiansData,
  removeDataForPrint,
  profiles,
  getRegionsAll,
  accessRights,
  userRegions
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [parentDep, setParentDep] = useState('0');
  const [parentLocalDep, setParentLocalDep] = useState('0');
  const [domesticDep, setDomesticDep] = useState("");
  const [profileType, setProfileType] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [numberPage, setNumberPage] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [regionId, setRegionId] = useState(null);
  const [downloadType, setDownloadType] = useState({type: null, id: null});
  const [name, setName] = useState('');

  const table = useRef();
  const tablePersonal = useRef();

  useEffect(() => {
    getRegionsAll()
    !troopTypes.length && getTroopTypes()
  }, [])

  useEffect(() => {
    parentDep=='0' && parentLocalDep=='0' && getDepartments()
  }, [numberPage, domesticDep, parentLocalDep]);

  useEffect(() => {
    parentDep!=='0' && getDepartments(parentDep)
  }, [parentDep]) 

  useEffect(() => {
    regionDep && regionDep.length &&
    setParentDep('0')
  }, [regionDep])
  
  useEffect(() => {
    getRegions(id);
  }, [id]);

  useEffect(() => {
    setNumberPage(0);
    if (parentLocalDep !== '0') {
      getDepartmentsCount(parentLocalDep, true, domesticDep, numberPage + 1, name, profileType)
    } else if (parentDep !== '0' && parentLocalDep == '0') {
      getDepartmentsCount(parentDep, true, domesticDep, numberPage + 1, name, profileType)
    } else if (parentDep == '0' && parentLocalDep == '0') {
      getDepartmentsCount(0, true, domesticDep, numberPage + 1, name, profileType)
    }
    
  }, [parentLocalDep, parentDep, domesticDep, name, profileType])
  
  useEffect(() => {
    if (parentLocalDep !== '0') {
      getDepartments(parentLocalDep, true, domesticDep, 10, numberPage + 1, name, profileType)
    } else if (parentDep !== '0' && parentLocalDep == '0') {
      getDepartments(parentDep, true, domesticDep,  10, numberPage + 1, name, profileType)
    } else if (parentDep == '0' && parentLocalDep == '0') {
      getDepartmentsAll(0, true, domesticDep, 10, numberPage + 1, name, profileType)
    }

  }, [parentDep, parentLocalDep, domesticDep, numberPage, name, profileType])

  
  const handleExel = (e) => {
    if (excelLoading) {
      e.stopPropagation();
    } else {
      if (parentLocalDep !== '0') {
        getDepartmentsExcel(parentLocalDep, true, domesticDep, name)
      } else if (parentDep !== '0' && parentLocalDep == '0') {
        getDepartmentsExcel(parentDep, true, domesticDep, name)
      } else if (parentDep == '0' && parentLocalDep == '0') {
        getDepartmentsExcel(0, true, domesticDep, name)
      }
      setIsSearch(true)
    }
  }
  
  const openModal = (type, item) => () => {
    const readRights = accessRights.ac_read || (accessRights.ac_read_by_region && userRegions.includes(item?.regionId))
    
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(type==='ADD'||readRights)
    !readRights && type!=='ADD' && createNotification('error', 'Доступ к данному отряду отсутствует')
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteDepartment = (id) => {
    deleteDepartment(id, parentDep, true, numberPage)
  }

  const acceptConfirmation = () => {
    handleDeleteDepartment(confirmation.id)
    setConfirmation(null)
  }

  const handleSelectRegionDep = (e) => {
    const value = e.target.value
    const regionId = regionDep.find(rd => rd.id==value)?.regionId
    setRegionId(regionId);
    
    if (value !== '0') {
      getDepartments(value)
      setParentDep(value)
      setParentLocalDep('0');
      setDomesticDep('')
    } else if (value === '0') {
      setParentDep('0')
      setParentLocalDep('0');
      setDomesticDep('')
    }
  }

  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const parse = () => {
    const table = document.getElementById("table")
    getExcelFromTable(table, 'Выгрузка отрядов')
    exelLoadingStop();
  }

  const parseTroop = () => {
    const table = document.getElementById("tablePersonal")
    getExcelFromTable(table, 'Состав отряда')
    removeDetachmentDetails();
  }

  useEffect(() => {
    if (isSearch) {
      if (excel && excel?.length > 0) {
        parse();
        setIsSearch(false)
        removeDetachments();
      } else {
        createNotification("error", "С данными фильтрами в выгрузку не попал ни один отряд")
        setIsSearch(false)
      }
    }
    
  }, [excel])

  const createDocData = async () => {
    
    addFont(jsPDF.API)
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'l', lineHeight: 0.5 })
		doc.setFont("Montserrat");

    doc.setFontSize(14)
    doc.text(`Списочный состав юнармейского отряда "${details?.detachment?.name}"`, 150, 10, "center" )
    doc.text(`Региональное отделение: ${regionDep?.filter(el => el.id == parentDep)[0]?.name || 'Все'}`, 150, 20, "center" )
    doc.text(`Местное отделение: ${localDep?.filter(el => el.id == parentLocalDep)[0]?.name || 'Все'}`, 150, 30, "center" )
    const img = new Image()
    img.src = logo
    doc.addImage(img, 'png', 8, 5, 25, 25)
    doc.autoTable({
      html: `#tablePersonalPdf`,
      startY: doc.pageCount > 1? doc.autoTableEndPosY() + 10 : 35,
      styles: {
        font: 'Montserrat',
        fontSize: 8
      },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        textColor: '#000',
      },
      didParseCell: (data) => {
        if (data.cell.colSpan===3) {
          data.cell.styles.fillColor = 'rgb(248, 207, 249)';
        }
      }
    })

    return doc;
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`Состав отряда.pdf`))
    removeDetachmentDetails();
  }

  useEffect(() => {
    if (details && details?.detachment?.id && details?.participants?.length > 0) {
      if (details?.participants?.length && downloadType.type == 'pdf') {
        toPdf();
        setDownloadType({type: null, id: null});
      } else if (details?.participants?.length && downloadType.type == 'excel') {
        parseTroop();
        setDownloadType({type: null, id: null});
      } 
    } else if (details?.participants?.length == 0 && downloadType.type != null) {
      createNotification("error", "В отряде нет ни одного юнармейца");
      removeDetachmentDetails();
      setDownloadType({type: null, id: null});
    }
  }, [downloadType, details?.detachment?.id])
  

  const handleGetParticipantsToPdf = (id) => {
    setDownloadType({type: 'pdf', id: id});
    getDetachmentDetails(id);
  }
  
  const handleGetParticipantsToExcel = (id) => {
    setDownloadType({type: 'excel', id: id});
    getDetachmentDetails(id);
  }

  const categoryFilterClick = (e) => {
    let newDomesticDepValue
    let newProfileTypeValue
    switch (e.target.value) {
      case '':
        newDomesticDepValue = '';
        newProfileTypeValue = null;
        break;
      case 'true':
        newDomesticDepValue = true;
        newProfileTypeValue = null;
        break;
      case 'false':
        newDomesticDepValue = false;
        newProfileTypeValue = 'all';
        break;
      default:
        break;
      }
      setDomesticDep(newDomesticDepValue)
      setProfileType(newProfileTypeValue)
  }

  const profileOptions = troopTypes ? [{name: 'Все', value: 'all'}, ...troopTypes.map(it => ({name: it, value: it}))] : []

  return (
    <>
      <table 
        id="table" 
        ref={table} 
        style={{display: "none"}}
        >
        <thead>
          <tr>
            <th colSpan="1">Реестр отрядов</th>
          </tr>
          <tr>
            <th colSpan="2">Региональное отделение: {regionDep?.filter(el => el.id == parentDep)[0]?.name || 'Все'}</th>
          </tr>
          <tr>
            <th colSpan="2">Местное отделение: {localDep?.filter(el => el.id == parentLocalDep)[0]?.name || 'Все'}</th>
          </tr>
          <tr>
            <th colSpan="1"></th>
          </tr>
          <tr>
            <th>Категория</th>
            <th>Название отряда</th>
            <th>Региональное отделение</th>
            <th>Местное отделение</th>
            <th>Населенный пункт</th>
            <th>Руководитель отряда</th>
            <th>Должность руководителя</th>
            <th>Телефон руководителя</th>
            <th>e-mail руководителя</th>
            <th>Основание для создания</th>
            <th>Статус героя</th>
            <th>Описание подвига</th>
          </tr>
        </thead>
        <tbody>
        {excel && excel?.map(el => 
          <tr key={el.id}>
            <td>{el?.domestic_detachment ? 'Именной' : 'Местный'}</td>
            <td>{el?.name}</td>

            <td>{ [regList.find(_el => _el.domestic_departments?.map(__el => __el.id).includes(el.parent))].map(__el => __el?.regionName + ' ' + __el?.federalUnit)[0] !== 'undefined undefined' ? 
                  [regList.find(_el => _el.domestic_departments?.map(__el => __el.id).includes(el.parent))].map(__el => __el?.regionName + ' ' + __el?.federalUnit)[0] : 
                    el.parentname}
            </td>
            
            <td>{
                  [regList.find(_el => _el.domestic_departments?.map(__el => __el.id).includes(el.parent))].map(__el => __el?.regionName + ' ' + __el?.federalUnit)[0] === 'undefined undefined'
                  ? '' : el.parentname}</td>
            <td>{el?.city}</td>
            <td>{el?.chief}</td>
            <td>{el?.position}</td>
            <td>{el?.phone}</td>
            <td>{el?.email}</td>
            <td>{el?.decision}</td>
            <td>{
            `${
              [
                {value: el?.vov, text: 'Участиник ВОВ'}, 
                {value: el?.svo, text: 'Участиник СВО'}, 
                {value: el?.local_wars, text: 'Участиник локальных войн'}, 
                {value: el?.cosmonaut, text: 'Космонавт'}, 
                {value: el?.afghanistan, text: 'Участиник Афганской войны'}, 
                {value: el?.sportsman, text: 'Выдающийся спортсмен'}, 
                {value: el?.other, text: el?.other_desc}]
                
                .filter(el => el.value)
                .map(el => el.text + ',')
               ?.slice(0, -1)
            }`
            }
            </td>
            <td>{el?.achievement_desc}</td>
          </tr>
        )}
        </tbody>
      </table>

      <table 
        id="tablePersonal" 
        ref={tablePersonal} 
        style={{display: "none"}}
        >
        <thead>
          <tr>
            <th colSpan="8">Списочный состав юнармейского отряда "{`${details?.detachment?.name}`}"</th>
          </tr>
          <tr>
            <th colSpan="8">Региональное отделение: {regionDep?.filter(el => el.id == parentDep)[0]?.name || 'Все'}</th>
          </tr>
          <tr>
            <th colSpan="8">Местное отделение: {localDep?.filter(el => el.id == parentLocalDep)[0]?.name || 'Все'}</th>
          </tr>
          <tr>
            <th>№ п/п</th>
            <th>ФИО</th>
            <th>Личный номер</th>
            <th>Дата рождения</th>
            <th>Телефон</th>
            <th>E-mail</th>
            <th>Адрес</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
        {details && details?.participants && details?.participants?.map((el, i) => 
          <tr key={el.id}>
            <td>{i + 1}</td>
            <td>{el?.lastName + ' ' + el?.firstName + ' ' + el?.fathersName}</td>

            <td>{ el?.personalId}</td>
            <td>{ el?.birthday ? moment(el?.birthday).format("MM/DD/YYYY") : ''}</td>
            <td>{ el?.phone}</td>
            <td>{ el?.email}</td>
            <td>{ el?.address}</td>
            <td>{ selects?.status.find(({ value }) => value === el?.status)?.name}</td>
          </tr>
        )}
        </tbody>
      </table>

      <table 
        id="tablePersonalPdf" 
        ref={tablePersonal} 
        style={{display: "none"}}
        >
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>ФИО</th>
            <th>Личный номер</th>
            <th>Дата рождения</th>
            <th>Телефон</th>
            <th>E-mail</th>
            <th>Адрес</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
        {details && details?.participants && details?.participants?.map((el, i) => 
          <tr key={el.id}>
            <td>{i + 1}</td>
            <td>{el?.lastName + ' ' + el?.firstName + ' ' + el?.fathersName}</td>

            <td>{ el?.personalId}</td>
            <td>{ el?.birthday ? moment(el?.birthday).format("MM/DD/YYYY") : ''}</td>
            <td>{ el?.phone}</td>
            <td>{ el?.email}</td>
            <td>{ el?.address}</td>
            <td>{ selects?.status.find(({ value }) => value === el?.status)?.name}</td>
          </tr>
        )}
        </tbody>
      </table>

       <div className={styles.container}>
        <div className={styles.title}>Реестр юнармейских отрядов</div>
        <div className={styles.newsList}>
        <div className={styles.newsListHeader}>
            {(accessRights.ac_create || accessRights.ac_create_by_region) && parentDep!=0
            ? <>
                <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>
                <div className={styles.downloadExcel} onClick={e => handleExel(e)}>
                  <i className={styles.excelLogo}><RiFileExcel2Line/></i><span>Скачать .xlsx</span>
                  {!!excelLoading && <i className={styles.excelLoad}><RiLoader3Fill/></i>}
                </div>
              </>
            : <></>}
          </div>
          <div className={styles.filters}>
          <div className={styles.filterBlock}>
          <CustomSelect
            title="Выберите региональное отделение"
            onChange={handleSelectRegionDep}
            selectedValue={parentDep}
            // withoutFirst
            selwidth='100%'
            padding='.5rem'
            options={accessRights.ac_create
              ? getRegionDepsOptions(regionDep)
              : getUserRegionDepsOptions(regionDep, userRegions)  
            }
          />
          <CustomInput
            value={name}
            name="name"
            width="100%"
            padding='.5rem'
            title="Наименование отряда"
            onChange={(e) => setName(e.target.value)}
          />
          </div>

          <div className={styles.filterBlock}>
          
          <CustomSelect
            title="Выберите местное отделение"
            onChange={(e) => setParentLocalDep(e.target.value)}
            selwidth='100%'
            defaultValName={'Все'}
            padding='.5rem'
            selectedValue={parentLocalDep}
            options={parentDep !== '0' && localDep ? localDep.map(it => ({name: it?.name, value: it?.id})) : []}
          />

          <CustomSelect
            title="Категория отряда"
            onChange={categoryFilterClick}
            withoutFirst
            selectedValue={domesticDep}
            options={detachmentsCategories}
            selwidth='100%'
            padding='.5rem'
          />
          
          {!(profileType===null) &&
          <CustomSelect
            title="Профиль"
            onChange={(e) => setProfileType(e.target.value)}
            selectedValue={profileType}
            withoutFirst
            options={profileOptions}
            selwidth='100%'
            padding='.5rem'
          />}

          </div>

          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Отряды:</div>
            <div className={styles.superuser}>Подчиненность:</div>
            <div className={styles.count}>Кол-во детей:</div>
            <div className={styles.actions} />
          </div>
          {
            loading ?
              <Loader top="50%" left="45%"/>
            :
            <>
            <div className={styles.table}>
            {detachments &&
              detachments.map((a) => {
                const deleteRights = accessRights.ac_delete || (accessRights.ac_delete_by_region && userRegions.includes(a.regionId))
                return (
                  <div key={a.id} className={styles.tableRow}>
                    <div
                      className={styles.displayname}
                      onClick={openModal("EDIT", a)}
                    >
                      <span>
                        {a.name}
                      </span>
                    </div>
                    <div className={styles.superuser} style={{color: "grey"}}>{a.parentname}</div>
                    <div className={styles.count}>
                      <p>{a.yun_count}</p>
                    <i 
                      onClick={() => handleGetParticipantsToPdf(a.id)}
                      title="Скачать в .pdf"
                      className={styles.iconDownload}><MdPictureAsPdf color="red"/></i>
                    
                    <i onClick={() => handleGetParticipantsToExcel(a.id)}
                      title="Скачать в .excel"
                      className={styles.iconDownload}><RiFileExcel2Line color="rgb(117, 194, 181)"/></i>
                    </div>

                    <div className={styles.actions}>
                    {deleteRights
                      ? <IoMdClose onClick={() => setConfirmation({text: a.name, id: a.id})}/>
                      : <div style={{height:'1.5rem'}}></div>
                    }
                    </div>
                    {detailsLoading && a.id == downloadType.id && 
                    <Loader size="20" right="0" top="6px" />}
                  </div>
                )
              })}
          </div>
          
           <div className={styles.pagination}>
              <Pagination 
                pageCount={count?.pageCount} 
                numberPage={numberPage} 
                onPageChange={handlePageClick}
              />
            </div>
            </>
          }
        </div>
      </div>
      {confirmation &&
      <Confirmation 
        confirmation={confirmation.text}
        acceptConfirmation={acceptConfirmation}
        rejectConfirmation={() => setConfirmation(null)}

      />}

      <DetachmentsModal
        open={isModalOpen}
        type={modalType}
        parentVal={parentDep}
        localParentVal={parentLocalDep}
        onClose={closeModal}
        selectedItem={selectedItem}
        editDepartment={editDepartment}
        addDepartment={addDepartment}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        data={detachments}
        getDetachmentDetails={getDetachmentDetails}
        details={details}
        removeDetachmentDetails={removeDetachmentDetails}
        regionDep={regionDep}
        localDep={localDep}
        getDepartments={getDepartments}
        troopTypes={troopTypes}
        removeLocalDep={removeLocalDep}
        parentDep={parentDep}
        setParentDep={setParentDep}
        parentLocalDep={parentLocalDep}
        setParentLocalDep={setParentLocalDep}
        domesticDep={domesticDep}
        setDomesticDep={setDomesticDep}
        numberPage={numberPage}
        regions={regions}
        history={history}
        regionId={regionId}
        setRegionId={setRegionId}
        getAllYunarmiansData={getAllYunarmiansData}
        removeDataForPrint={removeDataForPrint}
        profiles={profiles}
        profilesLoading={profilesLoading}
        accessRights={accessRights}
        userRegions={userRegions}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  regionDep: state.departments.region_departments,
  localDep: state.departments.local_departments,
  detachments: state.departments.detachments,
  details: state.departments.detachment_details,
  detailsLoading: state.departments.detachment_details_load,
  agsh: state.auth.user.agsh,
  nrsh: state.auth.user.nrsh,
  superuser: state.auth.superuser,
  regs: state.departments.regs,
  id: state.auth.id,
  excel: state.departments.excel,
  count: state.departments.count,
  regList: state.auth.regions,
  loading: state.departments.loading,
  excelLoading: state.departments.excelLoading,
  profilesLoading: state.yunarmians.pdf_loading,
  regions: state.users.regionList,
  profiles: state.yunarmians.all_profiles,
  userReg: state.auth.user.department_id,
  troopTypes: state.departments.troopTypes,
  accessRights: state.auth.accessRights.find(i => i.name==='detachment')||{},
  userRegions: state.auth.userRegions
})

export default connect(mapStateToProps, {
  getDepartments,
  deleteDepartment,
  editDepartment,
  addDepartment,
  uploadFile,
  deleteFile,
  getDetachmentDetails,
  removeDetachmentDetails,
  getRegions,
  getDepartmentsExcel,
  getDepartmentsAll,
  getDepartmentsCount,
  removeDetachments,
  removeLocalDep,
  exelLoadingStop,
  getAllYunarmiansData,
  removeDataForPrint,
  getTroopTypes,
  getRegionsAll
})(
  Detachments
);
