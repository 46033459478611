import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { Stage, Layer } from "react-konva";
import styles from "./Konva.module.css";
import { TextBlock, ImageBlock } from "./KonvaComponents";

export default function Konva({
  data,
  setData,
  selectedId,
  setSelectedId,
  badgeSizes
}) {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const [containerSizes, setContainerSizes] = useState({ width: 0, height: 0 });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keyDownFunction = (e) => {
    if (selectedId) {
      [37, 38, 39, 40].includes(e.keyCode) && navigateKeyPress(e.keyCode);
      [46, 110].includes(e.keyCode) && keyDelPress()
    }
  }

  useEffect(() => {
    document.body.addEventListener('keydown', keyDownFunction)
    document.body.addEventListener('click', handleOutsideClick)
    return () => {
      document.body.removeEventListener('keydown', keyDownFunction)
      document.body.addEventListener('click', handleOutsideClick)
    }
  }, [keyDownFunction])

  useEffect(() => {
    if (containerRef.current) {
      setContainerSizes({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, [containerRef]);

  const keyDelPress = () => {
    const components = [...data.components]
    const newComponentsList = components.filter(it => it.id !== selectedId)
    setData(prev => ({...prev, components: newComponentsList}))
    setSelectedId(null)
  }

  const navigateKeyPress = (keyCode) => {
    const index = data.components.findIndex(comp => comp.id === selectedId)
    const compShape = {...data.components[index].shape}
    switch (keyCode) {
      case 37:
        --compShape.x
        break;
      case 39:
        ++compShape.x
        break;
      case 38:
        --compShape.y
        break;
      case 40:
        ++compShape.y
        break;
      default:
        break;
    }
    const newData = JSON.parse(JSON.stringify(data))
    newData.components[index].shape = compShape
    setData(newData)
  }

  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (selectedId && containerRef.current && !path.includes(containerRef.current)) {
      setSelectedId(null)
    }
  }

  const setNewAttributes = (newAttrs, ind) => {
    const elem = [...data.components].slice();
    elem[ind].shape = newAttrs;
    setData({ ...data, components: elem });
  };

  const switchElements = (element, ind) => {
    switch (element.type) {
      case "image":
        return (
          <ImageBlock
            key={ind}
            data={element}
            shapeProps={element.shape}
            isSelected={element.id === selectedId}
            onSelect={() => setSelectedId(element.id)}
            onChange={(data) => setNewAttributes(data, ind)}
          />
        );
      case "text":
        return (
          <TextBlock
            key={ind}
            data={element}
            shapeProps={element.shape}
            isSelected={element.id === selectedId}
            onSelect={() => setSelectedId(element.id)}
            onChange={(data) => setNewAttributes(data, ind)}
          />
        );
      default:
        return;
    }
  };

  const checkDeselect = (e) => {
    const clickedOnEmpty =
      e.target === e.target.getStage() || !e.target.attrs.draggable;
    if (clickedOnEmpty) {
      setSelectedId(null);
    }
  };

  console.log(badgeSizes)

  return (
    <div
      ref={containerRef}
      className={styles.container}
      style={badgeSizes}
    >
      <Stage
        ref={canvasRef}
        className={styles.canvasBlock}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
        width={containerSizes.width}
        height={containerSizes.height}
      >
        <Layer>
          <ImageBlock
            key={"background"}
            data={{ data: data.background }}
            shapeProps={{
              x: 0,
              y: 0,
              width: containerSizes.width,
              height: containerSizes.height,
            }}
            onSelect={checkDeselect}
            notDraggable
          />
          {data.components.map(switchElements)}
        </Layer>
      </Stage>
    </div>
  );
}
