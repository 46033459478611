import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { InputFile } from "../../assets/common/InputFile/InputFile"
import { IoMdTrash } from 'react-icons/io';
import styles from "./Hero.module.css";
import {Slider} from './Slider';
import Loader from '../../components/Loader/Loader';

import { SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.css';
import { BACKEND_DOMAIN } from "../../utils/const";
import { createNotification } from "../../components/Notifications/Notifications";
import Select from "react-select";
import ReactQuill from "react-quill";
import Yamap from "../../components/shared/Yamap";
import moment from "moment";

const errorList = {
  name: '"Населенный пункт"',
  region_id: '"Регион"',
  department_id: '"Ответственный отряд"',
  type: '"Имя Героя"',
  description: '"Описание"',
  place: '"Местоположение"',
  search: '"Кто нашел"'
}

const HeroModal = ({
  type,
  numberPage,
  selectedItem,
  editObject,
  addHero,
  uploadImage,
  deleteImage,
  regions,
//   accessRights,
  loading,
  troops = [],
  editHero,
  getHeroImage,
  removeTroops,
  hero_image,
  param,
  removeHeroImage,
  getTroops,
  ...props
}) => {
  
  // const updateRights = type === "ADD";
  const [item, setItem] = useState({region_id: 0, department_id: '', type: '', description: '', geolocation: '', search: ''});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [toDeleteImages, setToDeleteImage] = useState([]);
  const [imagesPreviews, setImagesPreviews] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    item?.id && getHeroImage(item.id);

  }, [item?.id]);


  useEffect(() => {
    if ((!!item?.region_id || !!selectedItem?.region_id && search)) { 
      getTroops(item?.region_id || selectedItem?.region_id, search, item?.department_id);
    } 
  }, [item?.region_id, search, selectedItem?.region_id]);
  
  useEffect(() => {
    if (item?.region_id && (item?.region_id != selectedItem?.region_id)) {
      setSearch('');
      setItem(prev => ({...prev, department_id: null}))
      removeTroops();
    }
  }, [item?.region_id]);
  
    useEffect(() => {
      setImagesPreviews([])
      setToDeleteImage([]);
      setSelectedFiles([]);
      if (type === "ADD") {
        setItem({
          name: '',
          region_id: '',
          type: '',
          description: '',
          place: '',
          department_id: '',
          search: ''
        });
      } else {
        selectedItem && setItem({...selectedItem});
        if (hero_image?.length > 0) {
            const img = [...hero_image.map(el => `${BACKEND_DOMAIN || ''}${el.path_file}`)];
            setImagesPreviews(img);
          } else {
            setImagesPreviews([]);
          }
        }
      }, [selectedItem, type, hero_image]);

  // проверка на пустоту
  const strip = (html) => {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText;
  };

  // создание записи
  const addHeroItem = async () => {
    if (item.name && strip(item?.description) && item.place && item.region_id && item.type && item.search && item.department_id) {
      await addHero(item, numberPage+1, param, selectedFiles.map(el => el.file));
      handleClose();
    } else {
      // обработка пустых полей
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          const element = item[key];
          if (!element) {
            createNotification("error", `Заполните поле ${errorList[key]}`);
          } else if (!strip(element) && key=='description') {
            createNotification("error", `Заполните поле ${errorList[key]}`);
          }
        }
      }
    }
  };

  // редактирование записи
  const editHeroItem = async () => {
    if (item.name && strip(item?.description) && item.place && item.region_id && item.type && item.search && item.department_id) {
      await editHero(item.id, item, numberPage+1, param);

      if (toDeleteImages.length > 0) {
        toDeleteImages.forEach(el => deleteImage(item.id, hero_image?.find(_el => _el.path_file == `${'/files/' + el.split('/files/')[1]}`)?.id))
      }
      
      if (selectedFiles.length > 0) {
        selectedFiles.forEach(el => uploadImage(selectedItem.id, { file: el.file }));
      } 

      handleClose();
    } else {
      // обработка пустых полей
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          const element = item[key];
          if (!element) {
            createNotification("error", `Заполните поле ${errorList[key]}`);
          } else if (!strip(element) && key=='description') {
            createNotification("error", `Заполните поле ${errorList[key]}`);
          }
        }
      }
    }
  };

  // загрузка картинок
  const handleUploadImage = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map(file => ({ file, url: URL.createObjectURL(file) }));

      setSelectedFiles(prev => [...prev, ...filesArray]);
      setImagesPreviews(prev => [...prev, ...filesArray.map(el => el.url)])
    }
  };

  const deletePhoto = (photo) => {
    if (!/^blob/g.test(photo)) {
      setToDeleteImage(prev => [...prev, photo])
    } else {
      const file = imagesPreviews.find(el => el.url === photo)

      URL.revokeObjectURL(file)

      setSelectedFiles(selectedFiles.filter(el => el.url !== photo))
    }

    setImagesPreviews(imagesPreviews.filter(name => name !== photo))
  };

  // отрисовка слайдера
  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <SwiperSlide key={photo}>
          <div className={`photoWrapper`}>
            <i className={styles.deletePhoto} onClick={() => deletePhoto(photo)}>
              {<IoMdTrash />}
            </i>
            <img
              src={photo}
              alt="фото"
              className={styles.imgUploder}
            />
          </div>
        </SwiperSlide>
      )
    })
  }


  // скрыть + очистить попап
  const handleClose = () => {
    setItem(null);
    removeHeroImage();
    removeTroops();
    props.onClose();
  }

  // получение начального Ответственного отряда
  const getDefaultvalue = () => {
    return troops?.find(el => el?.id == item?.department_id)?.name;
  }

  const handleGetReg = (e) => {
    if (e) {
      setSearch(e);
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {type === "ADD"
          ? "Добавление героя"
          : "Редактирование героя"}
      </div>
      <div className={styles.modalBody}>
        <div>
          <div className={styles.firstBlock}>
            <div className={styles.firstBlockInner}>
            
            <p className={styles.news__label}>Населенный пункт</p>
              <input
                required
                maxLength="200"
                value={item?.name}
                // disabled={!updateRights}
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, name: e.target.value }))
                }
                className={styles.input}
                placeholder="Населенный пункт"
              />
              <br />

            <p className={styles.news__label}>Регион</p>
            <div className={styles.selectBlock}>
                <select
                    className={styles.regionSelect}
                    style={{maxWidth: '100%'}}
                    placeholder="Регион"
                    onChange={e => setItem(prev => ({...prev, region_id: e.target.value})) }
                    >
                        <option selected={item?.region_id === ''} value=''>Выбрать</option>
                        {
                        regions && regions.map(it => 
                            <option selected={it.id === item?.region_id} key={it.id} value={it.id}>{`${it.regionName} ${it.federalUnit}`}</option>)
                        }
                </select>
            </div>
            <p className={styles.news__label}>Ответственный отряд</p> 
            <div className={styles.selectBlock}>
                {((item && type != 'ADD') || (type == 'ADD')) &&
                <Select
                styles={{fontSize: '14px'}}
                placeholder="Выберите ответственный отряд..."
                value={{value: item?.department_id, label: troops?.find(el => el?.id == item?.department_id)?.name} || {value: 0, label: 'Выбрать'}}
                options={[{value: '', name: 'Выбрать'}, ...troops].map((i) => ({ label: i.name, value: i.id }))}
                onChange={e => setItem(prev => ({...prev, department_id: e.value}))}
                noOptionsMessage={() => "ничего не найдено"}
                className={styles.filter}
                onInputChange={(e) => handleGetReg(e)}
                //   isMulti
                />
              }
                </div>

            <p className={styles.news__label}>Имя Героя</p>
            <input
            required
            maxLength="200"
            value={item?.type}
            // disabled={!updateRights}
            onChange={(e) =>
                setItem((prev) => ({ ...prev, type: e.target.value }))
            }
            className={styles.input}
            placeholder="Имя Героя"
            />

            <p className={styles.news__label}>Кто нашел</p>
            <input
            required
            maxLength="200"
            value={item?.search}
            // disabled={!updateRights}
            onChange={(e) =>
                setItem((prev) => ({ ...prev, search: e.target.value }))
            }
            className={styles.input}
            placeholder="Кто нашел"
            />
            </div>            

            <div className={styles.imageBlock}>

                <div className={styles.wrapperSliderEquipment}>
                  {
                    !loading ? 
                    <>
                    <Slider>{imagesPreviews.length > 0 && renderPhotos(imagesPreviews)}</Slider>
                    <div className={styles.wrapperBut}>
                      <InputFile
                        multiple
                        title="Выберите изображение"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => handleUploadImage(e)}
                      />
                    </div>
                    </>
                    :
                    <div className={styles.load}><Loader top="30px" left="40%"/></div>
                  }
                  
                <div className={styles.wrapperSliderEquipmentSmall}>
                </div>
              </div>
            </div>
          </div>
          <p className={styles.news__label}>Описание</p>
            <ReactQuill
              value={item?.description || ''}
              onChange={(e) => setItem((prev) => ({ ...prev, description: e }))}
            />

            <div className={styles.heroMap}>
            <p className={styles.news__label}>Местоположение</p>
                <div className={styles.mapWrapper}>
                <Yamap coordinate={item?.place} width={'100%'} heigth="300px" setItem={setItem} name="place"/>
                </div>
            </div>

            { type!=="ADD" &&
            <div>
              <div className={styles.infoBlock}>
            <span>Создал:</span>
            <span>{item?.created_by}</span>
            <span>{item?.created ? moment(item?.created).format("LLL") : ""}</span>
            </div>
            <div className={styles.infoBlock}>
              <span>Обновил:</span>
              <span>{item?.updated_by}</span>
              <span>{item?.updated ? moment(item?.updated).format("LLL") : ""}</span>
            </div>
              </div>
            }
        </div>
        <div className="settingModal__btns">
          {
          <button
            className="newsListHeader__btn btn_grey"
            type="button"
            onClick={type === "ADD" ? addHeroItem : editHeroItem}
          >
            {type === "ADD" ? "Добавить" : "Сохранить"}
          </button>}
          <button
            className="newsListHeader__btn btn_grey"
            onClick={handleClose}
            type="button"
          >
            Закрыть
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default HeroModal;
