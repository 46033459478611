import React from "react";
import { useState, useEffect } from "react";
import {Image, Transformer} from 'react-konva';
import { useSelector } from "react-redux";
import { buildDefaultHeaders } from "../../../actions/helper";
import { BACKEND_DOMAIN } from "../../../utils/const";




export default function ImageBlock({
  data,
  shapeProps, 
  onSelect,
  isSelected,
  onChange,
  notDraggable
}) {
  
  const [image, setImage] = useState(null)
  
  const token = useSelector(state => state.auth.token)

  const shapeRef = React.useRef();
  const trRef = React.useRef();
  

  useEffect(() => {
    const image = new window.Image();
    const localImage = ['/bg_white.jpg', '/img/photoplace.png'].includes(data.data)
    image.src = localImage ? data.data : `${BACKEND_DOMAIN || ''}${data.data}`;
    image.crossOrigin = 'Anonymous';
    image.onerror = "this.onerror=null;this.src='/favicon.png';"
    console.log(image)
    setImage(image);
  }, [data.data]);

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        image={image}
        ref={shapeRef}
        onTap={onSelect}
        onClick={onSelect}
        {...shapeProps}
        draggable={!notDraggable}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
}
