import * as types from "../actions/types";

const initialState = {
  data: null,
  rating_params: null,
  all_rating_parameters: null,
  rating_value: null,
  cellLoading: false,
  criterion: null,
  closed_date: null,
  confirm_loading: false,
  loading: false,
  isNew: false,
  regById: {}
};

export default function region_rating(state = initialState, action) {
  switch (action.type) {
    case types.SET_REGION_RATING_DATA:
      return {
        ...state,
        data: action.payload.rating,
        closed_date: action.payload.date
      };
    case types.SET_REGION_RATING_PARAMS:
      return {
        ...state,
        rating_params: action.payload,
      };
    case types.SET_ALL_REGION_RATING_PARAMS:
      return {
        ...state,
        all_rating_parameters: action.payload,
      };
    case types.SET_RATING_VALUE:
      return {
        ...state,
        rating_value: action.payload,
      };
    case types.SET_REGION_RATING_CRITERION:
      return {
        ...state,
        criterion: action.payload
      };
    case types.SET_REGION_RATING_CELL_LOADING:
      return {
        ...state,
        cellLoading: action.payload,
      };
    case types.SET_CONFIRM_MODE_LOADING:
      return {
        ...state,
        confirm_loading: action.payload,
      };
    case types.SET_REGION_RATING_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_REGION_RATING_BY_ID:
      return {
        ...state,
        regById: action.payload,
      };
    case types.SET_REGION_RATING_IS_NEW:
      return {
        ...state,
        isNew: action.payload,
      };
    default:
      return state;
  }
}
