import React, { useState } from 'react'
import Button from '../../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import Li from '../../../components/Li'
import { thrash } from '../../../assets/svg/other'
import styles from '../styles.module.css'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addGroupUsers, deleteGroupUsers, getGroupAvailableUsers, getGroupUsers } from '../../../actions/permissions'
import { getDepartments } from '../../../actions/departments'
import Loader from '../../../components/Loader/Loader'
import { ButtonRed } from '../../../assets/common/ButtonRed/ButtonRed'
import Modal from 'react-responsive-modal'
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox'
import CustomSelect from '../../../components/CustomSelect/CustomSelect'
import { getRegionDepsOptions } from '../../../utils/getOptions'


export function Employees ({roleId}) {
  
  const dispatch = useDispatch()
  const {groupUsers: peoples, loading} = useSelector(state => state.roles.permission_groups)

  const [modal, setModal] = useState(false)

  const pageLoading = loading && !modal

  useEffect(() => {
    dispatch(getGroupUsers(roleId))
  }, [])

  const openModal = () => setModal(true)

  const closeModal = () => setModal(false)

  const handleDeleteUser = (userId) => () => {
    dispatch(deleteGroupUsers(roleId, userId))
  }

  const renderLi = ({displayname, user_id}) => {
    return (
      <Li>
        <div className={styles.liTitle}>{displayname}</div>
        <div onClick={handleDeleteUser(user_id)}>{thrash()}</div>
      </Li>
    )
  }

  return (
    <>
      <EmployeesModal
        open={modal}
        closeModal={closeModal}
        roleId={roleId}
      />
      {pageLoading
          ? <Loader top='50%' left='50%'/>
          : <ul className={styles.peoplesList}>{peoples?.map(renderLi)}</ul>
      }
      <Button onClick={openModal} className={styles.addUserButton}>
        <IoIosAdd size={30}/>
        Добавить сотрудника
      </Button>
    </>
  )
}


function EmployeesModal ({open, closeModal, roleId}) {
  
  const dispatch = useDispatch()
  const {availableUsers: usersList, loading} = useSelector(state => state.roles.permission_groups)
  const {region_departments: departments} = useSelector(state => state.departments)

  const [selectedUsers, setSelectedUsers] = useState([])
  const [regionDepFilter, setRegionDepFilter] = useState('')

  useEffect(() => {
    dispatch(getDepartments())
  }, [])
  
  useEffect(() => {
    const department_id = regionDepFilter == 0 ? undefined : regionDepFilter 
    dispatch(getGroupAvailableUsers(roleId, { department_id }))
  }, [regionDepFilter])
  
  const handleCloseModal = () => {
    closeModal()
    setSelectedUsers([])
    setRegionDepFilter('')
  }

  const handleRowClick = (userId) => () => {
    const removeMode = selectedUsers.includes(userId)
    const newUsersList = removeMode 
        ? selectedUsers.filter(id => id !== userId)
        : [...selectedUsers, userId]
    setSelectedUsers(newUsersList)
  }

  const renderRow = ({displayname, id, department_name}) => {
    const checked = selectedUsers.includes(id)
    return (
      <tr onClick={handleRowClick(id)}>
        <td>
          <CustomCheckbox onClick={e => e.stopPropagation()} value={checked}/>
        </td>
        <td width='40%'>{displayname}</td>
        <td>{department_name}</td>
      </tr>
    )
  }

  const handleSaveClick = async () => {
    if (!selectedUsers.length) return;
    const payload = selectedUsers.map(user_id => ({user_id, permission_group_id: roleId}))
    await dispatch(addGroupUsers(roleId, payload))
    handleCloseModal()
  }

  const handleDepartmentClick = (e) => {
    setRegionDepFilter(e.target.value)
  }

  return (
    <Modal
      open={open}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      classNames={{
        modal: styles.modal
      }}
    >
      <div className={styles.title}>Добавление пользователей</div>
      <div className={styles.modalBody}>
        <CustomSelect
          title='Региональное отделение'
          options={getRegionDepsOptions(departments)}
          onChange={handleDepartmentClick}
          selectedValue={regionDepFilter}
        />
        <div className={styles.tableBlock}>
          {loading
            ? <Loader left='40%' top='42%'/>
            : <table>{usersList?.map(renderRow)}</table>
          }
        </div>
        <div className={styles.modalButtons}>
          <ButtonRed onClick={handleSaveClick}>Добавить</ButtonRed>
          <ButtonRed onClick={handleCloseModal}>Закрыть</ButtonRed>
        </div>
      </div>
    </Modal>
  )
}