import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import { IoMdCreate, IoMdClose } from "react-icons/io";
import Input from '../../components/CustomInput/CustomInput'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import Loader from "../../components/Loader/Loader";
import styles from "./Videos.module.css";
import { BACKEND_DOMAIN } from "../../utils/const";
import previewImage from "../../assets/images/preview.png"


const VideosModal = ({
  type,
  numberPage,
  selectedItem,
  editVideoItem,
  addVideoItem,
  loading,
  accessRights,
  userRegions,
  ...props
}) => {
  
  const updateRights = type==='ADD' || accessRights.ac_update || (accessRights.ac_update_by_region && userRegions.includes(selectedItem?.regionId))


  const [item, setItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [existingFile, setExistingFile] = useState(null)
  const [addFile, setAddFile] = useState(null)
  const [preview, setPreview] = useState(null)


  useEffect(() => {
    if (type === "ADD") {
      setItem({ name: "" })
    } else {
      if (selectedItem) {
        setItem(selectedItem)
        const file = selectedItem.files ? selectedItem.files[0] : null
        setExistingFile(file)
      }
    }
  }, [selectedItem, type]);

  const addVideo = () => {
    addVideoItem(item, numberPage+1, addFile, preview)
    props.onClose()
  };

  const editVideo = () => {
    const incomingFile = selectedItem.files[0]
    const removeFile = incomingFile && !existingFile && incomingFile.file
    const removePreview = existingFile && !existingFile.preview && incomingFile.preview?.name
    editVideoItem(item, numberPage+1, removeFile, addFile, preview, removePreview);
    props.onClose();
  };

  const handleSelectFile = (e) => {
    const file = e.target.files[0]
    const name = file.name
    setAddFile({file, name})
    setExistingFile(null) 
  };

  const handleDeleteFile = (e) => {
    const name = existingFile?.file || addFile.name
    e.stopPropagation()
    setConfirmation(name);
  };

  const acceptConfirmation = (name) => {
    setConfirmation(null)
    setExistingFile(null)
    setAddFile(null)
  };

  const rejectConfirmation = () => {
    setConfirmation(null)
  };
  
  const downloadFile = () => {
    if (existingFile) {
      const link = document.createElement('a')
      link.setAttribute('href', `${BACKEND_DOMAIN || window.location.origin}/${existingFile.fileurl}`);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', 'download');
      link.click()
    }
  }
  
  console.log(item)

  const handleSelectPreview = (e) => {
    e.stopPropagation()
    const input = document.getElementById('inputPreview')
    input.click()
  }

  const handleSetPreview = (e) => {
    const file = e.target.files[0]
    setPreview(file)
    existingFile &&
      setExistingFile(prev => ({...prev, preview: null}))
  }

  const clearPreview = (e) => {
    e.stopPropagation()
    setPreview(null)
    existingFile &&
      setExistingFile(prev => ({...prev, preview: null}))
  }

  const statusToggleClick = () => {
    const status = item.status === 2 ? 1 : 2
    setItem(prev => ({...prev, status}))
  }

  return (
    <Modal
      {...props}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {loading && <Loader top="40%" left="40%" />}
      <div className={styles.title}>
        {type === "ADD" ? "Добавление документа" : "Редактирование документа"}
      </div>
      <div className={styles.modalBody}>
          <Input
            title="Название"
            value={item?.name}
            disabled={!updateRights}
            onChange={(e) =>
              setItem((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          {(existingFile || addFile) &&
          <div className={styles.fileBlock}>
            <p>Файл:</p>
            <input
              type="file"
              id="inputPreview"
              accept="image/png, image/jpeg"
              onChange={handleSetPreview}
              hidden
            />
            <div
              className={styles.fileItem}
              onClick={downloadFile}
            >
              {updateRights && <IoMdCreate onClick={handleSelectPreview}/>}
              {updateRights && <IoMdClose onClick={handleDeleteFile}/>}
              <div className={styles.filePreview}>
                <img
                  src={(existingFile && existingFile.preview) 
                        ? `${BACKEND_DOMAIN || window.location.origin}/${existingFile.preview.path}/${existingFile.preview.name}`
                        : (addFile && addFile.preview)
                          ? addFile.preview
                          : preview
                            ? URL.createObjectURL(preview)
                            : previewImage
                      }
                  alt="o_O"
                />
                {(existingFile?.preview || preview) && updateRights &&
                <button
                  onClick={clearPreview}
                >
                  Очистить
                </button>}
              </div>
              <div className={styles.fileTitle}>{existingFile ? existingFile.file : addFile.name}</div>
            </div>
          </div>}
          <CustomCheckbox
            label='Отображать в мобильном приложении'
            value={item?.status === 2}
            onClick={statusToggleClick}
          />
          {!(existingFile || addFile) && updateRights &&
            <div className={styles.input}>
            <InputFile
              title="Добавить видео"
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              onChange={(e) => handleSelectFile(e)}
            />
          </div>}
        <div className={styles.modalButtons}>
          <ButtonRed onClick={type === "ADD" ? addVideo : editVideo}>
            {type === "ADD" ? "Добавить" : "Сохранить"}
          </ButtonRed>
          <ButtonRed onClick={props.onClose}>Закрыть</ButtonRed>
        </div>
      </div>
    </Modal>
  );
};

export default VideosModal;