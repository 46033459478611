import React, {useState} from 'react'
import { IoIosArrowRoundBack } from "react-icons/io";
import styles from "./AddEvent.module.css"
import { FaRegFilePdf } from "react-icons/fa"
import {  IoIosClose, IoIosCloseCircle } from "react-icons/io";
import { InputFile } from '../../assets/common/InputFile/InputFile';
import Confirmation from '../../assets/common/Confirmation/Confirmation';
import { CgSoftwareUpload } from "react-icons/cg";

export default function FilesMode({
  setFilesMode,
  files=[1, 2],
  uploadEventFiles,
  eventId,
  deleteEventFile,
  userInfo
}) {

  const [addFilesList, setAddFilesList] = useState([])
  const [confirmation, setConfirmation] = useState(null);


  const handleUploadFiles = () => {
    uploadEventFiles(eventId, addFilesList)
    setAddFilesList([])
  }

  const deleteCurrentFile = (ind) => {
    const arr = [...addFilesList]
    arr.splice(ind, 1)
    setAddFilesList(arr)
  }

  
  const acceptConfirmation = (item) => {
    deleteEventFile(item.id, eventId)
    setConfirmation(null)
  };

  const rejectConfirmation = () => {
    setConfirmation(null)
  };

  return (
    <div className={styles.container}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation.name}
          acceptConfirmation={() => acceptConfirmation(confirmation)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <div onClick={() => setFilesMode(false)} className={styles.goBack}>
        <IoIosArrowRoundBack size={20} />
        назад
      </div>
      <div>
        <h2>Файлы, относящиеся к мероприятию:</h2>
        <div className={styles.filesBlock}>
          {
            files.map(item =>
            <div className={styles.fileItem}>
              {userInfo.superuser && <IoIosClose className={styles.closeSvg} onClick={() => setConfirmation(item)}/>}
              <FaRegFilePdf className={styles.pdfSvg}/>
              <div>{item.name}</div>
            </div>)
          }
        </div>
        <div className={styles.filesList}>
        {
          !!addFilesList.length && addFilesList.map((item, i) => <div key={i}>
            <span>{item.name}</span>
            <span onClick={() => deleteCurrentFile(i)}><IoIosCloseCircle size={16}/></span>
          </div>)
        }
      </div>
        <div className={styles.fileButtons}>
          
          {
            !!addFilesList.length &&
            <button onClick={handleUploadFiles} className={styles.addFileButton}>
              <span>Загрузить</span>
              <i><CgSoftwareUpload/></i>
            </button>
          }

          {userInfo.superuser &&
          <InputFile
            multiple
            title="Выберите файлы"
            type="file"
            accept="application/pdf"
            onChange={(e) => {setAddFilesList(Object.values(e.target.files))}}
          />}
        </div>
      </div>
    </div>
  )
}
