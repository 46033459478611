import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken, signOut } from "./auth";
import { ERROR, SET_VIDEOS_COUNT, SET_VIDEOS_DATA, SET_VIDEO_FILES_LIST, SET_VIDEO_LOADING } from "./types";
import { createNotification } from "../components/Notifications/Notifications";

export const getVideoCount = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const newsOnPage = getState().auth.itemsOnPage
    const response = await fetch(`${BACKEND_URL_PREFIX}/video/count`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const pageCount = Math.ceil(data / newsOnPage);
      dispatch({
        type: SET_VIDEOS_COUNT,
        payload: { data: data, pageCount: pageCount },
      });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getVideoItems =
  (page=1, sort) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const newsOnPage = getState().auth.itemsOnPage
      const offset = page * newsOnPage - newsOnPage;
      const params = {
        offset: offset,
        limit: newsOnPage
      }
      sort && ( params.alph = sort )
      const urlParams = new URLSearchParams(params).toString();
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/video?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_VIDEOS_DATA, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const deleteVideoItem =
  (id, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/video/${id}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        dispatch(getVideoCount());
        dispatch(getVideoItems(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const editVideoItem = (data, page, removeFile, addFile, preview, removePreview) =>
    async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const { id, name, status } = data
      const payload = {name, status}
      const response = await fetch(`${BACKEND_URL_PREFIX}/video/${id}`, {
        method: "PUT",
        body: JSON.stringify(payload),
        ...buildDefaultHeaders(getState),
    });
        if (response.ok) {
          removeFile && await dispatch(deleteFile(id, removeFile))
          addFile && await dispatch(uploadFile(id, addFile, preview))
          if (!removeFile && !addFile) {
            removePreview && await dispatch(deleteFile(id, removePreview))
            preview && await dispatch(uploadPreview(id, preview))
          }
          dispatch(getVideoCount());
          dispatch(getVideoItems(page));
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
  }
  
  export const addVideoItem = (data, page, file, preview) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/video`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
            if (file) {
              const data = await response.json();
              await dispatch(uploadFile(data.id, file, preview));
            }
            dispatch(getVideoCount());
            dispatch(getVideoItems(page));
          } else {
            dispatch({ type: ERROR, payload: response });
          }
    }
  }

  export const uploadFile = (id, data, preview) => async (dispatch, getState) => {
    dispatch({type: SET_VIDEO_LOADING, payload: true})
    await dispatch(checkAndUpdateToken())
  
    const fd = new FormData()
    fd.append('file', data.file)
  
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/video/${id}/file`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
        })
        if (response.ok) {
          createNotification('success', 'Файл успешно добавлен')
          preview && await dispatch(uploadPreview(id, preview))
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
    dispatch({type: SET_VIDEO_LOADING, payload: false})
  }
  
  export const deleteFile = (id, name) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/video/${id}/file/${name}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
          createNotification('success', 'Файл успешно удален')
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
  }

  export const uploadPreview = (id, file) => async (dispatch, getState) => {
    dispatch({type: SET_VIDEO_LOADING, payload: true})
    await dispatch(checkAndUpdateToken())
  
    const fd = new FormData()
    fd.append('file', file)
  
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/video/${id}/upload_image`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
        })
        if (response.ok) {
          createNotification('success', 'Превью успешно добавлено')
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
    dispatch({type: SET_VIDEO_LOADING, payload: false})
  }