import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import { IoMdClose } from "react-icons/io";
import { BACKEND_DOMAIN } from "../../utils/const";
import styles from "./Documents.module.css"
import { IoIosCloseCircle } from "react-icons/io";


const DocumentsModal = ({
  type,
  history,
  numberPage,
  selectedItem,
  typesList,
  article,
  addDocument,
  editDocument,
  getFilesList,
  accessRights,
  filesList,
  uploadFile,
  deleteFile,
  sUser,
  ...props
}) => {

  const updateRights = type === "ADD" || accessRights.ac_update

  const [item, setItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [_filesList, setFilesList] = useState([...filesList])
  const [addFilesList, setAddFilesList] = useState([])


  useEffect(() => {
    setFilesList(filesList)
  }, [filesList])
  

  useEffect(() => {
    if (type === "ADD") {
      setItem({ name: "", type: article });
    } else {
      if (selectedItem) {
        getFilesList(selectedItem.id)
        setItem({...selectedItem, deletedFiles: []});
      }
    }
  }, [selectedItem]);

  const addDocumentItem = () => {
    addDocument({ name: item.name, type: item.type }, numberPage+1, addFilesList);
    setAddFilesList([])
    props.onClose();
  };

  const editDocumentItem = () => {
    editDocument(item.id, article, item, numberPage+1, addFilesList);
    setAddFilesList([])
    props.onClose();
  };

  const handleUploadFile = (e) => {
    if (e.target.files) {
      const files = Object.values(e.target.files)
      setAddFilesList(files)
    }
  };

  const handleDeleteFile = (ind) => {
    const name = _filesList[ind].file
    setConfirmation({name: name, index: ind});
  };

  const acceptConfirmation = (name) => {
    const arr = [..._filesList]
    arr.splice(confirmation.index, 1)
    setItem(prev => ({...prev, deletedFiles: [...prev.deletedFiles, name]}))
    setFilesList(arr)
    setConfirmation(null)
  };

  const rejectConfirmation = () => {
    setConfirmation(null)
  };

  const deleteCurrentFile = (ind) => {
    const arr = [...addFilesList]
    arr.splice(ind, 1)
    setAddFilesList(arr)
  }

  const closeModal = () => {
    setAddFilesList([])
    props.onClose()
  }

  return (
    <Modal
      {...props}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation.name}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <div className={styles.title}>
        {type === "ADD" ? "Добавление документа" : "Редактирование документа"}
      </div>
      {item && <div className={styles.modalBody}>
        <div>
          <input
            value={item.name}
            onChange={(e) =>
              setItem((prev) => ({ ...prev, name: e.target.value }))
            }
            className={styles.input}
            disabled={!updateRights}
            placeholder="Название"
            style={{ marginBottom: "10px" }}
          />
          <br />
          <select
            className={styles.select}
            disabled={!updateRights}
            onChange={(e) =>
              setItem((prev) => ({ ...prev, type: e.target.value }))
            }
          >
            {typesList &&
              typesList.map((it) => (
                <option value={it.id} selected={it.id === article}>
                  {it.name}
                </option>
              ))}
          </select>
          <div className={styles.filesBlock}>
            {type!=="ADD" && filesList && (
              <div className={styles.filesList}>
                {_filesList.map((it, ind) => (
                  <div className={styles.fileItem}>
                    <a
                      href={`${BACKEND_DOMAIN || ""}/files/document/${item.id}/${it.file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {it.file}
                    </a>
                    {updateRights
                      ? <div className={styles.imgBlock} onClick={() => handleDeleteFile(ind)}>
                          <i><IoMdClose color={'red'} fontSize={24}/></i>
                        </div>
                      : <div style={{height:'2rem'}}></div>

                    }
                  </div>
                ))}
              </div>
            )}
            <div className={styles.addFilesList}>
              {
                !!addFilesList.length && addFilesList.map((item, i) => <div key={i}>
                  <span>{item.name}</span>
                  <span onClick={() => deleteCurrentFile(i)}><IoIosCloseCircle size={16}/></span>
                </div>)
              }
            </div>
              <div className={styles.input}>
                <InputFile 
                  multiple
                  disabled={!updateRights}
                  title="Выберите файл для загрузки"
                  onChange={(e) => handleUploadFile(e)}
                />
              </div>
          </div>
        </div>
        <div className={styles.modalButtons}>
          {updateRights &&
          <ButtonRed onClick={type === "ADD" ? addDocumentItem : editDocumentItem}>
            {type === "ADD" ? "Добавить" : "Сохранить"}
          </ButtonRed>}
          <ButtonRed onClick={closeModal}>Закрыть</ButtonRed>
        </div>
      </div>}
    </Modal>
  );
};

export default DocumentsModal;
