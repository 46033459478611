import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import styles from "./RegionDepartments.module.css";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import { MdPictureAsPdf } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { BACKEND_DOMAIN } from "../../utils/const";
import { MdModeEdit } from "react-icons/md";
import { getRegionOptions, getUserRegionsOptions } from "../../utils/getOptions";


const RegionDepartmentsModal = ({
  type,
  selectedItem,
  addDepartment,
  editDepartment,
  regions,
  uploadFile,
  deleteFile,
  loading,
  filesList = [],
  data,
  removeDataForPrint,
  getAllYunarmiansData,
  getDepartments,
  history,
  profiles,
  accessRights,
  userRegions,
  ...props
}) => {
  const [item, setItem] = useState(null);
  const [regionValid, setRegionValid] = useState(true);
  const [file, setFile] = useState({file: null, name: null});
  const [isDelete, setIsDelete] = useState(false);
  const [profilePopUp, setProfilePopUp] = useState(false)
  const [profileFilters, setProfileFilters] = useState({})
  const [profileName, setProfileName] = useState("Профиль не указан")
  
  const popUpRef = React.useRef();
  const updateRights = type==='ADD' || accessRights.ac_update || (accessRights.ac_update_by_region && userRegions.includes(selectedItem?.regionId))

  useEffect(() => {
    if (type === "ADD") {
      setItem({
        chief: "",
        city: "",
        email: "",
        regionId: "",
        name: "",
        phone: "",
        position: "",
        decision: "",
      });
    } else {
      selectedItem && setItem(selectedItem);
      setProfileName(selectedItem?.chief_id || "Профиль не указан")
    }
    setRegionValid(true)
  }, [selectedItem, loading]);

  useEffect(() => {
    !Object.keys(profileFilters).length && removeDataForPrint()
    profileFilters.regions && getAllYunarmiansData(profileFilters)
  }, [profileFilters])
  
  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    }
  }, []);
  
  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (popUpRef.current && !path.includes(popUpRef.current)) {
      closePopUp()
    }
  };

  const popUpFiltersChanges = (e) => {
    setProfileFilters(prev => ({...prev, [e.target.name] : e.target.value}))
  }

  const profileClick = (profile) => {
    setItem(prev => ({...prev, chief_id: profile.id}))
    closePopUp()
    setProfileName(profile.id)
  }


  const closePopUp = () => {
    setProfilePopUp(false)
    removeDataForPrint()
  }

  let check = '';
  useEffect(() => {
    check = data && data.length && data.find(it => it.id === item?.id)
    setItem(check)
  }, [data])
  
  const handleAddDepartment = () => {
    if (Number(item.regionId)) {
      const obj = { ...item, parent: 0 };
      addDepartment(obj, 0, false, file);
      setItem(null);
      props.onClose();
      setFile({file: null, name: null});
    } else {
      setRegionValid(false);
    }
  };

  const handleEditDepartment = () => {
    if (Number(item.regionId)) {
      const val = selectedItem?.imageurl;
      const parentVal = selectedItem.id;
      editDepartment(item.id, item);
          if (!val && file.name) {
            uploadFile(item.id, file, item.parent);
            setFile({file: null, name: null});
          } else if (val && file.name) {
              deleteFile(selectedItem.id, item.parent, false, 0, file, parentVal);
          }
    } if (isDelete == true) {
      deleteFile(selectedItem.id);
    } else {
      setRegionValid(false);
    }
    props.onClose();
    setItem(null); 
  };

  const handleFile = (e) => {
      setFile({ file: e.target.files[0], name: e.target.value});
      setIsDelete(false);
  };

  const handleDeleteFile = () => {
    setFile({file: null, name: null});
    setIsDelete(true);
  };

  const handleClose = () => {
    setItem(null);
    setFile({file: null, name: null});
    setIsDelete(false);
    props.onClose();
  }
  

  return (
    <Modal
      onClose={handleClose}
      open={props.open}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {type === "ADD" ? "Добавление отделения" : "Редактирование отделения"}
      </div>
      {item && (
        <div className={styles.modalBody}>
          <div>
            <CustomInput
              value={item?.name}
              title="Название отделения"
              disabled={!updateRights}
              onChange={(e) => setItem((prev) => ({ ...prev, name: e.target.value }))
              }
            />
            <CustomSelect
              options={accessRights.ac_create
                ? getRegionOptions(regions)
                : getUserRegionsOptions(regions, userRegions)
              }
              selectedValue={item.regionId}
              title="Регион"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, regionId: e.target.value }))
              }
              validate={!regionValid}
            />
            <CustomInput
              value={item.city}
              title="Населенный пункт"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, city: e.target.value }))
              }
            />
            <div style={{display: "flex", alignItems: "flex-start"}}>
            <CustomInput
              width="75%"
              value={item.chief}
              title="Руководитель"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, chief: e.target.value }))
              }
            />
            <div
              className={styles.profileBlock}
              ref={popUpRef}
            >
              <div>Профиль:</div>
              {updateRights && <MdModeEdit onClick={() => setProfilePopUp(true)}/>}
              <a
                className={styles.profileName}
                href={item?.chief_id && `/profile/${item.chief_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {profileName}
              </a>
              {profilePopUp &&
                <div className={styles.popup}>
                  <CustomSelect
                    selwidth="100%"
                    title="Выберите регион"
                    name="regions"
                    options={regions.map(it=>({name: `${it.regionName} ${it.federalUnit}`, value: it.id}))}
                    onChange={popUpFiltersChanges}
                    />
                  <CustomInput
                    title="Фамилия"
                    name="lastName"
                    onChange={popUpFiltersChanges}
                  />
                  <CustomInput
                    title="Имя"
                    name="firstName"
                    onChange={popUpFiltersChanges}
                  />
                  <CustomInput
                    title="Отчество"
                    name="fathersName"
                    onChange={popUpFiltersChanges}
                  />
                  <div className={styles.popupLabel}>Выберите профиль:</div>
                  <div className={styles.popupProfiles}>
                    {!!profiles?.length && profiles.map(it =>
                      <p onClick={() => profileClick(it)}>
                        {`${it.lastName} ${it.firstName} ${it.fathersName} (${it.personalId})`}
                      </p>
                    )}
                  </div>
                </div>
              }
          </div>
            </div>
            <CustomInput
              value={item.position}
              title="Должность"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, position: e.target.value }))
              }
            />
            <CustomInput
              value={item.phone}
              title="Телефон"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, phone: e.target.value }))
              }
            />
            <CustomInput
              value={item.email}
              title="Email"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, email: e.target.value }))
              }
            />
            <CustomInput
              value={item.decision}
              title="Основание для создания"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, decision: e.target.value }))
              }
              max="300"
            />
            <CustomCheckbox
              label="исключить из рейтинга"
              value={item.exclude_from_rating}
              onChange={e => setItem(prev => ({...prev, exclude_from_rating: !prev.exclude_from_rating}))}
            />
            {updateRights && <div className={styles.input}>
              {(item && item !== null && item?.imageurl && !isDelete) || (file.file !== null && !isDelete) ? 
              <div className={styles.pdf}>
                <a
                  href={`${BACKEND_DOMAIN || ""}${item.imageurl}`}
                  className={styles.linkpdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={styles.imagePDF}><MdPictureAsPdf/></i>
                  <span className={styles.titlePDF}>{file?.file?.name || item?.imageurl.split('/')[item?.imageurl.split('/').length-1]}</span>
                </a>
                <i className={styles.toTrash} onClick={handleDeleteFile}><IoMdTrash/></i>
              </div>
            :
            <InputFile 
                  accept="application/pdf" 
                  title="Выберите файл для загрузки" 
                  onChange={(e) => handleFile(e)} />
              }
            </div>}
          </div>
          <div className={styles.modalButtons}>
            {updateRights &&
            <ButtonRed
              onClick={
                type === "ADD" ? handleAddDepartment : handleEditDepartment
              }
            >
              {type === "ADD" ? "Добавить" : "Сохранить"}
            </ButtonRed>}
            <ButtonRed onClick={handleClose}>Закрыть</ButtonRed>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RegionDepartmentsModal;
