import React from "react";
import styles from "./CustomSelect.module.css";

export default function CustomSelect({
  options = [],
  title = "",
  onChange = () => {},
  onFocus,
  selectedValue = 0,
  withoutFirst,
  validate = false,
  disabled = false,
  name ="",
  width = "",
  selwidth = '90%',
  selMaxWiddth = '100%',
  margin = '0',
  padding = '0',
  border = "1px solid red",
  defaultValue = 0,
  defaultValName = 'Выбрать'
}) {
  
  return (
    <div className={styles.select} style={{maxWidth: width, width: selMaxWiddth, margin: margin, padding: padding}}>
        {title ? <div>{title}:</div> : <></>}
      <select
        name={name}
        value={selectedValue}
        onChange={onChange}
        style={{width: selwidth, maxWidth: selMaxWiddth, border: validate && border}}
        onFocus={onFocus}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {!withoutFirst && <option value='0'>{defaultValName}</option>}
        {options?.map(({name, value}) => (
          <option key={value} value={value}>{name}</option>
        )) || []}
      </select>
      {validate && <div className={styles.validate}>! поле является обязательным</div>}
    </div>
  );
}
