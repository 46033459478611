import * as types from '../actions/types';

const initialState = {
    count: null,
    page_count: null,
    page_number: 0,
    data: null,
    all_profiles: null,
    filters: {
      regions: [],
      localDep: []
    },
    filtered: {
      nodet: false,
      nodep: false,
      abroad: false,
      regions: [],
      localDep: [],
      school: '',
      city: '',
      nophone: false,
      noemail: false,
      noNotif: false,
      noCert: false,
      sex: '',
      status: '',
      age: '',
      birthday_year: '',
      unaprofile_type: '',
      lastName: '',
      from_date: '',
      to_date: '',
      detachment: "",
      email: "",
      profile_filled_from_date: '',
      profile_filled_to_date : '',
      on_date: '',
      is_age: '',
      personal_id: '',
      is_adult: false,
      country: [],
    },    
    profile: null,
    photos: null,
    sport: null,
    activity: null,
    newID: null,
    troopHistory: null,
    troopList: null,
    isBack: false,
    depsList: null,
    docsInfo: null,
    customDocsInfo: null,
    modal_loading : false,
    pdf_loading : false,
    loading : false,
    awardsList: []
  };

export default function unarmians (state = initialState, action) {
  switch (action.type) {
    case types.SET_UNARMIANS_COUNT:
      return {
        ...state,
        count: action.payload.data,
        page_count: action.payload.pageCount
      };
      case types.SET_UNARMIANS_DATA:
      return {
        ...state,
        data: action.payload
      };
      case types.SET_UNARMIAN_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
      case types.CLEAR_UNARMIAN_PROFILE:
      return {
        ...state,
        profile: null
      };
      case types.SET_UNARMIAN_LOADING:
      return {
        ...state,
        loading: action.payload
      };
      case types.SET_UNARMIAN_PDF_LOADING:
      return {
        ...state,
        pdf_loading: action.payload
      };
      case types.SET_FILTERED_REGIONS:
      return {
        ...state,
        filters: {...state.filters, regions: action.payload}
      };
      case types.SET_FILTERED_COUNTRY:
      return {
        ...state,
        filters: {...state.filters, country: action.payload}
      };
      case types.SET_FILTERED_LOCAL_DEP:
      return {
        ...state,
        filters: {...state.filters, localDep: action.payload}
      };
      case types.REMOVE_REGIONS_FILTER:
      return {
        ...state,
        filters: {...state.filters, regions: []}
      };
      case types.SET_AWARD_PROFILE:
      return {
        ...state,
        awardsList: action.payload
      };
      case types.REMOVE_COUNTRY_FILTER:
      return {
        ...state,
        filters: {...state.filters, country: []}
      };
      case types.REMOVE_LOCAL_DEP_FILTER:
      return {
        ...state,
        filters: {...state.filters, localDep: []}
      };
      case types.SET_FILTERS:
      return {
        ...state,
        filtered: action.payload
      };
      case types.DELETE_FILTERS:
      return initialState;
      case types.SET_UNARMIAN_IMAGES:
      return {
        ...state,
        photos: action.payload
      };
      case types.SET_UNARMIAN_SPORT:
      return {
        ...state,
        sport: action.payload
      };
      case types.SET_UNARMIAN_ACTIVITY:
      return {
        ...state,
        activity: action.payload
      };
      case types.GET_TROOP_HISTORY:
        return {
          ...state,
        troopHistory: action.payload
        };
      case types.SET_UNARMIAN_PAGE_NUMBER:
      return {
        ...state,
        page_number: action.payload
      };
      case types.SET_UNARMIAN_NEW_ID:
      return {
        ...state,
        newID: action.payload
      };
      case types.SET_ALL_UNARMIANS_DATA:
      return {
        ...state,
        all_profiles: action.payload
      };
      case types.GET_TROOP_LIST:
      return {
        ...state,
        troopList: action.payload
      };
      case types.GET_DEPS_LIST:
      return {
        ...state,
        depsList: action.payload
      };
      case types.SET_UNARMIAN_BACK_UPDATE:
      return {
        ...state,
        isBack: action.payload
      };
      case types.SET_UNARMIAN_DOCS_INFO:
      return {
        ...state,
        docsInfo: action.payload
      };
      case types.SET_CUSTOM_DOCS_INFO:
      return {
        ...state,
        customDocsInfo: action.payload
      };
      case types.SET_UNARMIAN_MODAL_LOADING:
      return {
        ...state,
        modal_loading: action.payload
      };
      case types.SET_COUNTRY:
      return {
        ...state,
        country: action.payload
      };
    default:
      return state;
  }
}