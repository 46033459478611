import React from 'react'
import styles from "./CustomInput.module.css"



export default function CustomInput({
    title,
    max,
    width,
    inputWidth,
    fontSize = '18px',
    padding = '0',
    validate = false,
    margin,
    ...props
}) {
  return (
    <div className={styles.input} style={{width: width, padding: padding, margin: margin}}>
        <div>{title}:</div>
        <input
            maxLength={max}
            placeholder={title}
            style={{width: inputWidth, fontSize: fontSize}}
            {...props}
            />
            {validate && <span className={styles.validate}>! поле является обязательным</span>}
    </div>
  )
}