import React, { useState, useEffect } from 'react'

import styles from "./Hero.module.css";
import {
    getHeroes,
    getHero,
    getTroops,
    addHero,
    deleteHero,
    editHero,
    uploadImage,
    deleteImage,
    getHeroesCount,
    removeTroops,
    removeHeroImage,
    getHeroImage
} from '../../actions/hero'
import { connect } from 'react-redux'
import Loader from '../../components/Loader/Loader';
import {getRegions} from '../../actions/auth';
import { Pagination } from '../../assets/common/Pagination/Pagination';


import { IoMdClose } from 'react-icons/io';
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import HeroModal from './HeroModal';
import Confirmation from '../../assets/common/Confirmation/Confirmation';

const Hero = ({
    getHeroes,
    heroes,
    getHero,
    deleteHero,
    hero = {},
    loading,
    regionList,
    getRegions,
    getTroops,
    troops,
    addHero,
    editHero,
    uploadImage,
    deleteImage,
    getHeroesCount,
    pageCount,
    removeTroops,
    getHeroImage,
    hero_image,
    removeHeroImage
}) => {

    const [numberPage, setNumberPage] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [params, setParams] = useState({reg: '', name: ''});
    const [modalType, setModalType] = useState("");
    const [confirmation, setConfirmation] = useState(null);
    
    useEffect(() => {
        setSelectedItem(hero);
    }, [hero])
    
    const openModal = (type, item) => () => {
        if (type == 'EDIT') {
            getHero(item.id)
        }
        if (type == 'ADD') {
            setSelectedItem({});
        }
        setModalType(type);
        setIsModalOpen(true);
      };

    useEffect(() => {
        getRegions();
    }, []);

    useEffect(() => {
        getHeroes(numberPage + 1, params);
        getHeroesCount(numberPage + 1, params);
    }, [params, numberPage])
    

    const handlePageClick = (data) => {
        setNumberPage(data.selected);
      };

      const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
      };

      const acceptConfirmation = () => {
        deleteHero(confirmation.id, numberPage + 1, params);
        setConfirmation(null);
      }

    return (
        <div className={styles.container}>
            {loading && !isModalOpen && <Loader top="45%" left="45%" />}
            <div className={styles.title}>Верни герою имя</div>
            <div className={styles.newsList}>
                <div className={styles.newsListHeader}>
                <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>
                    <div className={styles.selectBlockModal}>
                        <span>Регион</span>
                        <select
                            className={styles.regionSelect}
                            placeholder="Регион"
                            onChange={e => setParams(prev => ({...prev, reg: e.target.value})) }>
                                <option selected={params.reg === ''} value=''>Все</option>
                                {
                                regionList && regionList.map(it => 
                                    <option selected={it.id === params.reg} key={it.id} value={it.id}>{`${it.regionName} ${it.federalUnit}`}</option>)
                                }
                        </select>
                    </div>

                    <div className={styles.selectBlockModal}>
                        <span>Герои</span>
                        <input value={params.name} onChange={e => setParams(prev => ({...prev, name: e.target.value}))} placeholder="Поиск по названию"/>
                    </div>
                </div>

                <div className={styles.tableHeader}>
                    <div className={styles.displayname}>Населенный пункт</div>
                    <div className={styles.displayname}>Имя</div>
                </div>

                <div className={styles.table}>
                    {heroes &&
                    heroes?.map((a) => (
                        <div key={a.id} className={styles.tableRow}>
                        <div 
                        onClick={openModal("EDIT", a)}
                        className={styles.displayname}>{a.name}</div>
                        <div 
                        onClick={openModal("EDIT", a)}
                        className={styles.displayname}>{a.type}</div>
                        <div className={styles.actions}>
                        {1
                            ? <IoMdClose
                            onClick={() => setConfirmation({name: a.name, id: a.id})}
                            />
                            : <div style={{height:'1.5rem'}}></div>
                        }
                        </div>
                        </div>
                    ))}
                </div>

            </div>

          
            <div className={styles.pagination}>
            <Pagination 
            pageCount={pageCount?.pageCount} 
            numberPage={numberPage} 
            onPageChange={handlePageClick}
            />
          </div>
          <div className={styles.itemsCount}>
            </div>
            {isModalOpen && (
            <HeroModal
                open={isModalOpen}
                type={modalType}
                onClose={closeModal}
                selectedItem={selectedItem}
                regions={regionList}
                numberPage={numberPage}
                troops={troops}
                addHero={addHero}
                editHero={editHero}
                uploadImage={uploadImage}
                deleteImage={deleteImage}
                removeTroops={removeTroops}
                getHeroImage={getHeroImage}
                hero_image={hero_image}
                removeHeroImage={removeHeroImage}
                param={params}
                getTroops={getTroops}
            />
          )}
        {confirmation && <Confirmation
              confirmation={confirmation.text}
              acceptConfirmation={acceptConfirmation}
              rejectConfirmation={() => setConfirmation(null)}

      />}
        </div>
  )
}

const mapStateToProps = (state) => ({
    heroes: state.hero.heroes,
    hero: state.hero.hero,
    pageCount: state.hero.pageCount,
    hero_image: state.hero.hero_image,
    troops: state.hero.troops,
    loading: state.hero.loading,
    regionList: state.auth.regions,
    data: state.private_stream.data
  });
  
  export default connect(mapStateToProps, {
    getHeroes,
    getHero,
    getRegions,
    getTroops,
    addHero,
    editHero,
    uploadImage,
    deleteImage,
    removeTroops,
    deleteHero,
    getHeroesCount,
    getHeroImage,
    removeHeroImage
  })(Hero);