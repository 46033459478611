import React, { useState, useEffect } from "react";
import Calendar from "../../components/Calendar";
import { connect } from "react-redux";
import { getNews, setCurrentEvent } from "../../actions/news";
import styles from "./EventsSchedule.module.css";
import moment from "moment";
import { IoMdCreate } from "react-icons/io";
import { NavLink } from "react-router-dom";



const colors = ["#fd3153", "#1ccb9e", "#3694DF"];

function EventsSchedule({ schedule, getNews, setCurrentEvent, history }) {
  const [eventId, setEventId] = useState(null);

  useEffect(() => {
    setCurrentEvent(1)
    getNews(2);
    return () => {
      setCurrentEvent(null)
    }
  }, []);

  const restructEvents = (arr) => {
    const newArr = arr.map((item, i) => ({
      id: i,
      color: colors[i % 3],
      from: moment(item.startdate),
      to: moment(item.enddate).add(3, 'hours'),
      title: item.title,
    }));
    return newArr;
  };

  const strip = (html) => {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText;
  };

  return (
    <div style={{ width: "100%", maxWidth: "80vw"}}>
      {schedule.newsList && (
        <Calendar
          events={restructEvents(schedule.newsList)}
          onClickEvent={(e) => setEventId(e)}
        />
      )}
      <div
        className={styles.description}
        onClick={() => setEventId(null)}
        style={
          typeof eventId == "number"
            ? { opacity: "1", zIndex: "10" }
            : { opacity: "0", zIndex: "-10" }
        }
      >
        {typeof eventId == "number" && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {strip(schedule.newsList[eventId].description)}
            <NavLink to={`/event/edit_event/${schedule.newsList[eventId].id}`}>
              <IoMdCreate/>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    schedule: state.schedule,
  };
};

export default connect(mapStateToProps, { getNews, setCurrentEvent })(EventsSchedule);
