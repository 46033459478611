import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { getFormValues, initialize } from "redux-form";
import {
  fetchUsers,
  addUser,
  editUser,
  changePassword,
  getRegions,
  getUserData,
  getUserRegions,
  editUserRegions,
  deleteUserImage,
  uploadUserImage,
  getUserDepartments,
  editUserDepartments,
  editUserDeps,
  editChangedDeps,
  resetUserData
} from "../../actions/users";
import { change, reduxForm } from "redux-form";
import { getAllYunarmiansData, removeDataForPrint } from "../../actions/yunarmiyans";
import { getDepartments } from "../../actions/departments"
import { getPermissionGroups } from "../../actions/permissions"
import Modal from "./AddEditUserModal";
import FindField from "../../assets/common/FindField/FindField";
import Confirmation from '../../assets/common/Confirmation/Confirmation';
import { createNotification } from "../../components/Notifications/Notifications";
import Loader from "../../components/Loader/Loader";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";
import CustomSelect from "../../components/CustomSelect/CustomSelect";


const regex = /[^a-zA-Z0-9/@%!"#?¨'-_.,]/

const Users = ({
  editUser,
  fetchUsers,
  addUser,
  history,
  users,
  changePassword,
  loading,
  getUserData,
  isSuperUser,
  deleting,
  getRegions,
  getUserRegions,
  initialValues,
  rolesList,
  regions,
  handleSubmit,
  userRegions,
  userDeps,
  editUserRegions,
  deleteUserImage,
  accessRights,
  uploadUserImage,
  getDepartments,
  getPermissionGroups,
  pageCount,
  getUserDepartments,
  editUserDepartments,
  departments,
  editUserDeps,
  changedDeps,
  editChangedDeps,
  resetUserData,
  getAllYunarmiansData,
  removeDataForPrint,
  profiles,
  formValues
}) => {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [confirmation, setConfirmation] = useState(null);
  const [params, setParams] = useState({ word: '', sort: '', role: '', page: 0})

  useEffect(() => {
    getDepartments()
    getRegions();
    getPermissionGroups()
  }, []);

  useEffect(() => {
    fetchUsers(history, params);
  }, [params])

  const handleEditUser = ({id, serial_number, ...values}) => () => {
    const payload = {...values, serial_number: serial_number || null}
    editUser(id, payload, params, history);
    isModalOpen && closeModal()
  };

  const handleAddUser = (values, regions, avatarFile) => {
    const user = { ...values };
    let errorMessage
    const necessarilyFields = ["superuser", "agsh", "nrsh", "management", "nmo"]

    necessarilyFields.forEach(fieldName =>
      !values.hasOwnProperty(fieldName) && ( user[fieldName] = false ))
    
    !values.department_id && (errorMessage = 'Укажите региональное отделение')
    !values.email && (errorMessage = 'Не заполнен e-mail')
    !values.displayname && (errorMessage = 'Укажите ФИО')
    regex.test(values.password) && (errorMessage = 'Пароль должен состоять из букв латинского алфавита, цифр и специальных символов')
    !values.password && (errorMessage = 'Не заполнен пароль')
    regex.test(values.username) && (errorMessage = 'Логин должен состоять из букв латинского алфавита, цифр и специальных символов')
    !values.username && (errorMessage = 'Укажите логин')
    
    if (errorMessage) {
      createNotification("error", errorMessage);
    } else {
      addUser(user, params, history, regions, avatarFile);
      closeModal();
    }
  };

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    const sort = values[newValueIndex]
    setParams(prev => ({...prev, sort}))
  }

  const handleChangePassword = (values) => {
    const { username, password } = values;

    changePassword({ username, newpassword: password }, history);
  };

  const openModal = (type, user) => () => {
    setModalType(type);
    setIsModalOpen(true);
    if (type!=="ADD") {
      getUserRegions(user.id);
      getUserData(user.id);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePageClick = ({selected: page}) => {
    setParams(prev => ({...prev, page}))
  };

  
  const acceptConfirmation = () => {
    const payload = { active: false }
    editUser(confirmation.id, payload, params, history);
    setConfirmation(null);
  }

  const setSearchInput = (word) => {
    setParams(prev => ({...prev, word}))
  }

  const handleChangeRole = (e) => {
    const role = e.target.value
    setParams(prev => ({...prev, role}))
  }
  
  
  if (!(accessRights.ac_read || accessRights.ac_read_by_region)) return null;


  return (
    <>
      <div className="container newsListContainer">
        <div className="newsListContainer__title">Пользователи</div>
        <div className="newsList">
          <div className="newsListHeader">
            <button
              className="newsListHeader__btn btn_grey"
              onClick={openModal("ADD")}
            >
              Добавить
            </button>
            <CustomSelect
              options={rolesList?.map(({displayname: name, id: value}) => ({name, value}))}
              selectedValue={params.role}
              onChange={handleChangeRole}
              width="300px"
            />
            <FindField
              searchInput={params.word}
              setSearchInput={setSearchInput}
            />
          </div>
          <div className="newsListRow newsListRow__header userListRow">
            <div className="userListRow__displayname">ФИО</div>
            <div className="userListRow__username">Логин</div>
            <div className="userListRow__roles">Роли</div>
            <div className="userListRow__actions" />
          </div>
          {loading ? <Loader top="50%" left="45%" /> :
            <div className="newsListTable">
            {users?.map((a) => {
              const roles = a.roles?.join(' | ')
              return (
                <div key={a.id} className="newsListRow">
                  <div
                    className="userListRow__displayname fio"
                    onClick={openModal("EDIT", a)}
                  >
                    {a.displayname}
                  </div>
                  <div className="userListRow__username">{a.username}</div>
                  <div className="userListRow__roles">{roles}</div>
                  <div className="userListRow__actions">
                    <i
                      onClick={() => setConfirmation({a: a.username, id: a.id, text: a.username})}
                      className={`deletefile-icon ${deleting && "deleting"}`}
                    >
                      <IoMdClose fontSize={24} color={'red'}/>
                    </i>
                  </div>
                </div>
              )
            })}
            <div className="newsListPagination">
              <ReactPaginate
                previousLabel="В начало"
                nextLabel="В конец"
                breakLabel={"..."}
                breakClassName="break-me"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                forcePage={params.page}
              />
            </div>
          </div>}
          <div className='controls'>
          <div
            onClick={handleSortClick}
            className={`sortBlock ${params.sort ? 'sortActive' : ''}`}
          >
              {params.sort && params.sort==='desc'
              ? <BsSortDown/>
              : <BsSortDownAlt/>}
          </div>
        </div>
        </div>
      </div>
      { isModalOpen &&
      <Modal
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        addUser={handleAddUser}
        getUserData={getUserData}
        editUser={handleEditUser}
        handleSubmit={handleSubmit}
        loading={loading}
        changePassword={handleChangePassword}
        initialValues={initialValues}
        regions={regions}
        userRegions={userRegions}
        editUserRegions={editUserRegions}
        deleteUserImage={deleteUserImage}
        uploadUserImage={uploadUserImage}
        departments={departments}
        getUserDepartments={getUserDepartments}
        userDeps={userDeps}
        editUserDeps={editUserDeps}
        editUserDepartments={editUserDepartments}
        changedDeps={changedDeps}
        editChangedDeps={editChangedDeps}
        resetUserData={resetUserData}
        getUnarmyansData={getAllYunarmiansData}
        profiles={profiles}
        history={history}
        removeDataForPrint={removeDataForPrint}
        formValues={formValues}
      />}
      {confirmation &&
        <Confirmation 
          confirmation={confirmation.text}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      }
    </>
  );
};

const UsersWithForm = reduxForm({ form: "user", enableReinitialize: true })(Users)


export default connect(
  ({ users, auth, departments, yunarmians, roles, ...state }) => {
    const form = getFormValues("user")(state);
    return {
    users: users.userList,
    deleting: users.deleting,
    isSuperUser: auth.superuser,
    regions: users.regionList,
    userRegions: users.userRegions,
    userDeps: users.userDepartments,
    loading: users.loading,
    departments: departments.region_departments,
    changedDeps: users.changedDepartments,
    profiles: yunarmians.all_profiles,
    initialValues: users.userData,
    formValues: form,
    rolesList: roles.permission_groups.permissionGroups,
    pageCount: users.usersPageCount,
    accessRights: auth.accessRights.find(i => i.name==='users')||{}
  }},
  {
    fetchUsers,
    addUser,
    editUser,
    changePassword,
    getRegions,
    getUserRegions,
    editUserRegions,
    getUserData,
    deleteUserImage,
    uploadUserImage,
    getDepartments,
    getUserDepartments,
    editUserDepartments,
    editUserDeps,
    editChangedDeps,
    resetUserData,
    getPermissionGroups,
    getAllYunarmiansData,
    removeDataForPrint
  }
)(UsersWithForm);
