import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import Modal from "react-responsive-modal";
import { Button } from "../../assets/common/Button/Button";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import Yamap from "../../components/shared/Yamap";
import styles from "./Tourism.module.css";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { BACKEND_DOMAIN, itemsItems } from "../../utils/const";
import { createNotification } from "../../components/Notifications/Notifications";
import moment from "moment";
import {
  getRegionOptions,
  getUserRegionsOptions,
} from "../../utils/getOptions";

const goScroll = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const TourismModal = ({
  type,
  numberPage,
  selectedItem,
  editObject,
  addObject,
  uploadImage,
  deleteImage,
  getRegions,
  regions,
  accessRights,
  userRegions,
  ...props
}) => {
  const updateRights =
    type === "ADD" ||
    accessRights.ac_update ||
    (accessRights.ac_update_by_region &&
      userRegions.includes(selectedItem?.regionid));

  const [item, setItem] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [image, setImage] = useState(null);
  const [regionValid, setRegionValid] = useState(true);
  const [isCheckedAll, setIsCheckedAll] = useState(false);

  useEffect(() => {
    getRegions();
    return () => {};
  }, []);

  useEffect(() => {
    if (type === "ADD") {
      setItem({
        name: "",
        address: "",
        worktime: "",
        contacts: "",
        geolocation: "",
        description: "",
        responsible: "",
        emailresponsible: "",
        website: "",
        hidden: false,
        regions: [],
      });
      setImage(null);
      setImageURL(null);
    } else {
      selectedItem && setItem(selectedItem);
      if (selectedItem?.imageurl) {
        setImageURL(selectedItem.imageurl);
      } else {
        setImage(null);
        setImageURL(null);
      }
    }
    setRegionValid(true);
  }, [selectedItem, type]);

  const addTourismItem = () => {
    if (Number(item?.regionid) && item.regions && item.regions.length) {
      addObject(item, numberPage + 1, { file: image });
      handleClose();
    } else {
      (!item.regionid || !Number(item.regionid)) && setRegionValid(false);
      (!item.regions || !item.regions.length) &&
        createNotification("error", "Список регионов не должен быть пустым");
    }
  };

  const editTourismItem = () => {
    if (Number(item?.regionid) && item.regions && item.regions.length) {
      editObject(item.id, item, numberPage + 1);
      if (image) {
        uploadImage(selectedItem.id, { file: image }, numberPage + 1);
      } else if (selectedItem.imageurl && !imageURL) {
        deleteImage(item.id, numberPage + 1);
      }
      handleClose();
    } else {
      (!item.regionid || !Number(item.regionid)) && setRegionValid(false);
      (!item.regions || !item.regions.length) &&
        createNotification("error", "Список регионов не должен быть пустым");
    }
  };

  const handleUploadImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setImageURL(reader.result);
        setImage(e.target.files[0]);
      };
      reader.readAsDataURL(file);
    } else {
      setImageURL(null);
      setImage(null);
    }
  };

  const selectAll = () => {
    let allRegs = regions.map((reg) => reg.id);
    let newCheck = isCheckedAll;
    setIsCheckedAll(!newCheck);
    if (isCheckedAll) {
      setItem((prev) => ({ ...prev, regions: [] }));
    } else {
      setItem((prev) => ({ ...prev, regions: allRegs }));
    }
  };

  const handleDeleteImage = () => {
    setImageURL(null);
    setImage(null);
    selectedItem.imageurl && deleteImage(item.id, numberPage + 1);
  };

  const handleClose = () => {
    setItem(null);
    props.onClose();
  };

  const handleCheck = (code) => {
    let newArray;
    if (item.regions.includes(Number(code))) {
      newArray = item.regions.filter((item) => item != code);
    } else {
      newArray = [...item.regions, Number(code)];
    }
    setItem((prev) => ({ ...prev, regions: newArray }));
  };

  const handleCheckboxClick = (fieldName) => () => {
    setItem((prev) => ({ ...prev, [fieldName]: !prev[fieldName] }));
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {type === "ADD"
          ? "Добавление туристического объекта"
          : "Редактирование туристического объекта"}
      </div>
      <div className={styles.modalBody}>
        {item && (
          <>
            <div className={styles.hideBlock}>
              <CustomCheckbox
                label={"Не отображать в мобильном приложении"}
                value={item?.hidden}
                disabled={!updateRights}
                onChange={handleCheckboxClick("hidden")}
              />
              <CustomCheckbox
                label={"Требуется регистрация"}
                value={item?.registration_required}
                disabled={!updateRights}
                onChange={handleCheckboxClick("registration_required")}
              />
              <CustomCheckbox
                label="обьект массового посещения"
                value={item?.is_public}
                onChange={handleCheckboxClick("is_public")}
              />
            </div>
            <div className={styles.firstBlock}>
              <div className={styles.inputsBlock}>
                <div className={styles.inputBlock}>
                  <span className={styles.titleInput}>Название:</span>
                  <input
                    value={item?.name}
                    onChange={(e) =>
                      setItem((prev) => ({ ...prev, name: e.target.value }))
                    }
                    disabled={!updateRights}
                    className={styles.input}
                    placeholder="Название"
                  />
                </div>

                {regions && regions.length && (
                  <div className={styles.inputBlock} style={{marginTop: '1rem'}}>
                    <span className={styles.titleInput}>Регион:</span>
                    <CustomSelect
                      options={
                        accessRights.ac_create
                          ? getRegionOptions(regions)
                          : getUserRegionsOptions(regions, userRegions)
                      }
                      defaultValue={item?.regionid}
                      selectedValue={item.regionid}
                      disabled={!updateRights}
                      onChange={(e) =>
                        setItem((prev) => ({
                          ...prev,
                          regionid: e.target.value,
                        }))
                      }
                      margin="0 0 0 .5rem"
                      selwidth="100%"
                      validate={!regionValid}
                    />
                  </div>
                )}

                <div className={styles.inputBlock}>
                  <span className={styles.titleInput}>
                    ФИО <br />
                    ответственного:
                  </span>
                  <input
                  onKeyPress={e => console.log(e)}
                    value={item?.responsible}
                    disabled={!updateRights}
                    onChange={(e) =>
                      setItem((prev) => ({
                        ...prev,
                        responsible: e.target.value,
                      }))
                    }
                    className={styles.input}
                    placeholder="ФИО ответственного"
                  />
                </div>

                <div className={styles.inputBlock}>
                  <span className={styles.titleInput}>
                    E-mail ответственного:
                  </span>
                  <input
                    value={item?.emailresponsible}
                    onChange={(e) =>
                      setItem((prev) => ({
                        ...prev,
                        emailresponsible: e.target.value,
                      }))
                    }
                    disabled={!updateRights}
                    className={styles.input}
                    placeholder="E-mail ответственного"
                  />
                </div>

                <div className={styles.inputBlock}>
                  <span className={styles.titleInput}>Адрес:</span>
                  <input
                    value={item?.address}
                    onChange={(e) =>
                      setItem((prev) => ({ ...prev, address: e.target.value }))
                    }
                    disabled={!updateRights}
                    className={styles.input}
                    placeholder="Адрес"
                  />
                </div>

                <div className={styles.inputBlock}>
                  <span className={styles.titleInputTime}>Время работы:</span>
                  <input
                    value={item?.worktime}
                    disabled={!updateRights}
                    onChange={(e) =>
                      setItem((prev) => ({ ...prev, worktime: e.target.value }))
                    }
                    className={styles.input}
                    placeholder="Время работы"
                  />
                </div>

                <div className={styles.inputBlock}>
                  <span className={styles.titleInput}>Контакты:</span>
                  <input
                    value={item?.contacts}
                    onChange={(e) =>
                      setItem((prev) => ({ ...prev, contacts: e.target.value }))
                    }
                    disabled={!updateRights}
                    className={styles.input}
                    placeholder="Контакты"
                  />
                </div>

                <div className={styles.inputBlock}>
                  <span className={styles.titleInput}>Сайт:</span>
                  <input
                    value={item?.website}
                    onChange={(e) =>
                      setItem((prev) => ({ ...prev, website: e.target.value }))
                    }
                    disabled={!updateRights}
                    className={styles.input}
                    placeholder="Сайт"
                  />
                </div>
              </div>
              <div className={styles.imageBlock}>
                <div className={styles.image}>
                  {imageURL && (
                    <img
                      src={
                        imageURL && image
                          ? imageURL
                          : `${BACKEND_DOMAIN || ""}${imageURL}`
                      }
                      alt="o_O"
                    />
                  )}
                </div>
                {imageURL && updateRights ? (
                  <Button
                    title="Очистить"
                    type="button"
                    onClick={handleDeleteImage}
                  />
                ) : (
                  <InputFile
                    title="Выберите изображение"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => handleUploadImage(e)}
                  />
                )}
              </div>
            </div>
            <div className={styles.secondBlock}>
              <div className={styles.mapBlock}>
                <span className={styles.mapTitle}>Местоположение:</span>
                <Yamap
                  coordinate={item?.geolocation}
                  setItem={setItem}
                  width = '450px'
                />
              </div>
              <div className={styles.regionsBlock}>
                <span className={styles.mapTitle}>
                  Доступен для регионов:
                </span>
                <CustomCheckbox
                  label={`Выбрать все`}
                  value={isCheckedAll}
                  disabled={!updateRights}
                  onChange={selectAll}
                />
                <div className={styles.regionsList}>
                  {item &&
                    regions &&
                    regions.map((it) => (
                      <CustomCheckbox
                        label={`${it.regionName} ${it.federalUnit}`}
                        value={item.regions?.includes(Number(it.id))}
                        disabled={!updateRights}
                        onChange={() => handleCheck(it.id)}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div>
              <span className={styles.aboutTitle}>Описание:</span>
              <ReactQuill
                value={item?.description || ""}
                readOnly={!updateRights}
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, description: e }))
                }
              />
            </div>
            {type !== "ADD" && (
              <div>
                <div className={styles.infoBlock}>
                  <span>Создал:</span>
                  <span>{item.created_by_name[0]?.displayname}</span>
                  <span>
                    {item.created ? moment(item.created).format("LLL") : ""}
                  </span>
                </div>
                <div className={styles.infoBlock}>
                  <span>Обновил:</span>
                  <span>{item.updated_by_name[0]?.displayname}</span>
                  <span>
                    {item.updated ? moment(item.updated).format("LLL") : ""}
                  </span>
                </div>
              </div>
            )}
            <div className="settingModal__btns">
              {updateRights && (
                <button
                  className="newsListHeader__btn btn_grey"
                  type="button"
                  onClick={type === "ADD" ? addTourismItem : editTourismItem}
                >
                  {type === "ADD" ? "Добавить" : "Сохранить"}
                </button>
              )}
              <button
                className="newsListHeader__btn btn_grey"
                onClick={handleClose}
                type="button"
              >
                Закрыть
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default TourismModal;
