import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken, signOut } from "./auth";
import { SET_BADGES_IMAGES, SET_BADGES_LOADING, ERROR, SET_BADGES_DATA } from "./types.js";
import { createNotification } from "../components/Notifications/Notifications";



export const getBadges = () => async (dispatch, getState) => {
    
  dispatch({type: SET_BADGES_LOADING, payload: true})
  await dispatch(checkAndUpdateToken())

  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/badge`, {
          method: "GET",
          ...buildDefaultHeaders(getState)
      })
      if (response.ok) {
          const data = await response.json();
          const payload = data.badges
          dispatch({ type: SET_BADGES_DATA, payload });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
  
  dispatch({type: SET_BADGES_LOADING, payload: false})

}

export const updateBadge = (badgeId, data, previewFile) => async (dispatch, getState) => {
  dispatch({type: SET_BADGES_LOADING, payload: true})
  await dispatch(checkAndUpdateToken())
  console.log(previewFile)
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/badge/${badgeId}`, {
          method: "PUT",
          headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: JSON.stringify(data)
      })
      if (response.ok) {
        await dispatch(uploadPreview(badgeId, previewFile))
        dispatch(getBadges())
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
  dispatch({type: SET_BADGES_LOADING, payload: false})
}

export const createBadge = (data, previewFile) => async (dispatch, getState) => {
  dispatch({type: SET_BADGES_LOADING, payload: true})
  await dispatch(checkAndUpdateToken())

  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/badge`, {
          method: "POST",
          headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: JSON.stringify(data)
      })
      if (response.ok) {
        createNotification('success', 'Бейджик успешно добавлен!')
        const data = await response.json();
        await dispatch(uploadPreview(data.badge.id, previewFile))
        dispatch(getBadges())
      } else {
        createNotification('error', 'Ошибка добавления!')
        dispatch({ type: ERROR, payload: response });
      }
  }
  dispatch({type: SET_BADGES_LOADING, payload: false})
}

export const deleteBadge = (badgeId) => async (dispatch, getState) => {
  dispatch({type: SET_BADGES_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  console.log(badgeId)
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/badge/${badgeId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      createNotification('success', 'Бейджик успешно удален!')
      dispatch(getBadges());
    } else {
      dispatch({ type: ERROR, payload: response });
      createNotification('error', 'Ошибка удаления!')
    }
  }
  dispatch({type: SET_BADGES_LOADING, payload: false})
};

export const getImages = (type) => async (dispatch, getState) => {
    
    dispatch({type: SET_BADGES_LOADING, payload: true})
    await dispatch(checkAndUpdateToken())

    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/badge_images/${type}`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        })
        if (response.ok) {
            const data = await response.json();
            dispatch({ type: SET_BADGES_IMAGES, payload: data.images });
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
    
    dispatch({type: SET_BADGES_LOADING, payload: false})
  
}


export const uploadImages = (imagesList, type) => async (dispatch, getState) => {

  dispatch({type: SET_BADGES_LOADING, payload: true})
  await dispatch(checkAndUpdateToken())

  const imageType = type ? 'other' : 'background'

  for (const image of imagesList) {
    const fd = new FormData()
    fd.append('file', image)
  
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/badge_images/upload/${imageType}`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
    })
    
    dispatch(getImages(imageType))

    }
  }
  dispatch({type: SET_BADGES_LOADING, payload: false})
}

export const deleteImage = (image) => async (dispatch, getState) => {
  dispatch({type: SET_BADGES_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const imageId = image.id
    const imageType = image.type.includes('other') ? 'other' : 'background'
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/badge_images/${imageId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      dispatch(getImages(imageType));
    } else {
      dispatch({ type: ERROR, payload: response });
      createNotification('error', 'Ошибка удаления!')
    }
  }
  dispatch({type: SET_BADGES_LOADING, payload: false})
};

export const uploadPreview = (badgeId, previewFile) => async (dispatch, getState) => {
    dispatch({type: SET_BADGES_LOADING, payload: true})
    await dispatch(checkAndUpdateToken())
  
    const fd = new FormData()
    fd.append('file', previewFile)
    fd.append('badge_id', badgeId)
  
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/badge_images/upload/preview`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
        })
        if (response.ok) {
          console.log("ok")
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
    dispatch({type: SET_BADGES_LOADING, payload: false})
  }


