import React, { useEffect } from 'react'
import styles from './ActivityRatingReport.module.css'
import { useDispatch, useSelector } from "react-redux"
import { getActivityRating } from '../../actions/reports'
import { getRegions } from '../../actions/users'
import { getDepartments, getDepartmentsByRegion } from "../../actions/departments";
import CustomSelect from '../../components/CustomSelect/CustomSelect'
import { useState } from 'react'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../components/Loader/Loader'
import NoData from '../../components/NoData/NoData'
import { getRegionOptions } from '../../utils/getOptions'
import CustomDatepicker from '../../components/CustomDatepicker/CustomDatepicker'
import { BiSortUp, BiSortDown } from "react-icons/bi";


const initialParams = {
    regionId: 'all',
    domestic_departmentId: 'all',
    detachmentId: 'all',
    date_from: null,
    date_to: null,
    order_by: null,
    desc: null
}

const tableTitles = [
  {name: '№ п/п', sortValue: ''},
  {name: 'ФИО', sortValue: ''},
  {name: 'Возраст', sortValue: 'birthday'},
  {name: 'Личный номер', sortValue: 'personal_id'},
  {name: 'Регион', sortValue: 'region'},
  {name: 'Название отряда', sortValue: 'detachment_name'},
  {name: 'Выполнено заданий / баллы', sortValue: 'task_count'},
  {name: 'Выполнено заданий по КДЕД / баллы', sortValue: 'calendar_ded_points'},
  {name: 'Опубликовано личных событий / Количество лайков за эти события', sortValue: 'streams_publication'},
  {name: 'Количество поставленных лайков', sortValue: 'streams_liking'},
  {name: 'Количество просмотренных цифровых курсов', sortValue: 'digital_academy_visit'},
  {name: 'Участие в мероприятиях', sortValue: 'activities_participation'}
]

export default function ActivityRatingReport() {
  
    const [params, setParams] = useState(initialParams)

    const { regionList: regions } = useSelector(state => state.users)
    const { activity_rating: data, loading } = useSelector(state => state.reports)
    const { depsByReg, detachments } = useSelector(state => state.departments)


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getActivityRating(params, true))
        !regions.length && dispatch(getRegions())
    }, [params])

    
    const renderTh = ({name, sortValue}) => {
      const sortByThis = sortValue && sortValue === params.order_by
      return (
        <th onClick={handleThClick(sortValue)}>
          {name}
          {sortByThis && (params.desc ? <BiSortUp/> : <BiSortDown/>)}
        </th>
      )
    }

    const handleThClick = (order_by)  => () => {
      const reClick = params.order_by === order_by
      const desc = !reClick || (reClick && params.desc) ? null : 'desc'
      setParams(prev => ({...prev, order_by, desc}))
    }

    const handleParamsChange = (e) => {
        setParams(prev => ({...prev, [e.target.name]: e.target.value}))
    }
    
    const handleChangeRegion = (e) => {
        const {value} = e.target
        handleParamsChange(e)
        setParams(prev => ({...prev, domestic_departmentId: "all", detachmentId: "all"}))
        value !== 'all' && dispatch(getDepartmentsByRegion(value))
    }
    
    const handleChangeDomDep = (e) => {
        const {value} = e.target
        handleParamsChange(e)
        setParams(prev => ({...prev, detachmentId: "all"}))
        value !== 'all' && dispatch(getDepartments(value, true))
    }
        
    const getMoreItems = () => {
        dispatch(getActivityRating(params))
    }

    const openProfilePage = (profile) => {
        const link = document.createElement('a')
        link.setAttribute('href', `/profile/${profile.profile_id}`);
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener norefferrer');
        link.click()
    }
    
    const domDepsArray = depsByReg?.map(({name, id}) => ({name, value: id})) || []
    const detachArray = detachments?.map(({name, id}) => ({name, value: id})) || []

    const getRegionName = () => {
      if (params.regionId === 'all') return ''
      const {federalUnit, regionName: name} = regions.find(reg => reg.id == params.regionId)
      return `по региону "${name} ${federalUnit}"`
    }

    return (
    <div className={styles.wrapper}>
        <div className={styles.filtersBlock}>
            <div>
              <CustomSelect
                width="500px"
                title="Выберите регион"
                selectedValue={params.regionId}
                options={[{name: "Все", value: "all"}, ...getRegionOptions(regions)]}
                onChange={handleChangeRegion}
                name='regionId'
                withoutFirst
                />
              
              <CustomSelect
                width="500px"
                title="Выберите местное отделение"
                options={[{name: "Все", value: "all"}, ...domDepsArray]}
                onChange={handleChangeDomDep}
                name='domestic_departmentId'
                selectedValue={params.domestic_departmentId}
                disabled={params.regionId === 'all'}
                withoutFirst
                />
              
              <CustomSelect
                width="500px"
                title="Выберите отряд"
                options={[{name: "Все", value: "all"}, ...detachArray]}
                onChange={handleParamsChange}
                name='detachmentId'
                selectedValue={params.detachmentId}
                disabled={params.regionId === 'all' || params.domestic_departmentId === 'all'}
                withoutFirst
              />
            </div>
            <div>
              <CustomDatepicker
                  value={params.date_from}
                  title='Дата с'
                  name='date_from'
                  onChange={handleParamsChange}
              />
              
              <CustomDatepicker
                  value={params.date_to}
                  title='Дата по'
                  name='date_to'
                  onChange={handleParamsChange}
              />
            </div>
        </div>
            <div className={styles.titleBlock}>
                <h3>Рейтинг активности юнармейцев</h3>
                <div>{`${getRegionName()} по состоянию на ${moment().format("DD.MM.YYYY")} года`}</div>
            </div>
            {loading
                ? <Loader/>
                : (data?.length
                    ? <div className={styles.tableBlock}>
                        <InfiniteScroll
                            dataLength={data.length}
                            next={getMoreItems}
                            hasMore={data.length && !(data.length%100)}
                            loader={<h4>Загрузка еще 100 профилей...</h4>}
                            >
                            <table id="analyticalReport">
                                <thead>
                                    <tr>{tableTitles.map(renderTh)}</tr>
                                </thead>
                                <tbody>
                                    {data.map((it, i) => {
                                        return (
                                            <tr key={i}>
                                                <th>{++i}</th>
                                                <th onClick={() => openProfilePage(it)}>{it.fio}</th>
                                                <td>{moment().diff(it.birthday, 'years')}</td>
                                                <td>{it.personal_id}</td>
                                                <td>{it.region}</td>
                                                <td>{it.detachment_name}</td>
                                                <td>{it.task_count}/{it.tasks_completion}</td>
                                                <td>{it.calendar_ded_count}/{it.calendar_ded_points}</td>
                                                <td>{it.streams_publication}/{it.personal_streams_likes}</td>
                                                <td>{it.streams_liking}</td>
                                                <td>{it.digital_academy_visit}</td>
                                                <td>{it.activities_participation}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                      </div>
                    : <NoData text='данные по региону отсутствуют'/>
                  )
            }
    </div>
  )
}
