import * as types from "../actions/types";

const initialState = {
  analytical_report: null,
  analytical_details: [],
  activity_rating: null,
  contacts_report: {},
  detailsLoading: false,
  loading: false
};

export default function reports (state = initialState, action) {
  switch (action.type) {
    case types.SET_ANALYTICAL_REPORT_DATA:
      return {
        ...state,
        analytical_report: action.payload,
      };
    case types.SET_ANALYTICAL_REPORT_DETAILS:
      const newDetailsList = action.payload
        ? [...state.analytical_details, action.payload]
        : []
      return {
        ...state,
        analytical_details: newDetailsList
      };
    case types.SET_ACTIVITY_RATING_DATA:
      return {
        ...state,
        activity_rating: action.payload,
      };
    case types.ADD_ACTIVITY_RATING_DATA:
      return {
        ...state,
        activity_rating: [...state.activity_rating, ...action.payload]
      };
    case types.SET_REPORT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_DEPARTMENT_CONTACTS:
      const {key, data} = action.payload
      return {
        ...state,
        contacts_report: {...state.contacts_report, [key]: data}
      };
    case types.SET_REPORT_DETAILS_LOADING:
      return {
        ...state,
        detailsLoading: action.payload,
      };
    default:
      return state;
  }
}
