import React, { useEffect, useState } from "react";
import { change, reduxForm } from "redux-form";
import Modal from "react-responsive-modal";
import TextField from "../../components/shared/TextField";
import CheckboxField from "../../components/shared/CheckboxField";
import DropdownField from "../../components/shared/DropdownField";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomInput from "../../components/CustomInput/CustomInput";
import styles from "./Users.module.css";
import Loader from "../../components/Loader/Loader";
import {
  IoMdCreate,
  IoMdDownload,
  IoIosClose,
  IoMdTrash,
} from "react-icons/io";
import avatar from "../../assets/images/avatar.png"
import SelectField from "../../components/shared/SelectField";
import DepartmentsModal from "./DepartmentsModal";
import { BACKEND_DOMAIN } from "../../utils/const";
import WysiwygField from "../../components/shared/WysiwygField";
import { MdModeEdit } from "react-icons/md";




const  UserModal =
  ({
    type,
    addUser,
    editUser,
    changePassword,
    handleSubmit,
    getUserData,
    userData,
    loading,
    regions,
    userRegions,
    editUserRegions,
    initialValues,
    deleteUserImage,
    uploadUserImage,
    values,
    departments,
    getUserDepartments,
    editUserDepartments,
    userDeps,
    editUserDeps,
    changedDeps,
    editChangedDeps,
    resetUserData,
    profiles,
    getUnarmyansData,
    removeDataForPrint,
    change,
    history,
    formValues,
    ...props
  }) => {
    let action, title, label, editDisabled;
    if (type === "ADD") {
      title = "Добавление";
      label = "Добавить";
      action = (values) => {
        return addUser(values, isChecked, avatarFile)
      }
      editDisabled = false;
    } else {
      title = "Редактирование";
      label = "Сохранить";
      action = (values) => editUser(values)();
      editDisabled = true;
    }

    const [isChecked, setIsChecked] = useState([]);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [currentRegion, setCurrentRegion] = useState(null)
    const [avatarFile, setAvatarFile] = useState(null)
    const [avatarURL, setAvatarURL] = useState(null)
    const [profilePopUp, setProfilePopUp] = useState(false)
    const [profileFilters, setProfileFilters] = useState({})
    const [profileName, setProfileName] = useState("Профиль не указан")

    const popUpRef = React.useRef();


    useEffect(() => {
      return () => {
        setAvatarFile(null)
        setAvatarURL(null)
        resetUserData()
      }
    }, [])

    useEffect(() => {
      if (userRegions) setIsChecked(userRegions);
    }, [userRegions]);

    useEffect(() => {
      !Object.keys(profileFilters).length && removeDataForPrint()
      profileFilters.regions && getUnarmyansData(profileFilters)
    }, [profileFilters])
    
    useEffect(() => {
      document.body.addEventListener('click', handleOutsideClick);
      return () => {
        document.body.removeEventListener('click', handleOutsideClick);
      }
    }, []);

    useEffect(() => {
      setProfileName(initialValues?.unaprofile_id || "Профиль не указан")
    }, [initialValues])
    

    const handleCheck = (code) => {
      if (isChecked.includes(Number(code))) {
        setIsChecked(isChecked.filter((item) => item != code));
      } else {
        setIsChecked((prev) => [...prev, Number(code)]);
      }
    };

    const handleSend = (values) => {
      action(values);
      if (type!=="ADD") {
        editUserRegions(initialValues.id, isChecked);
      }
    };

    const selectAll = () => {
      if (isCheckedAll) {
        setIsChecked([]);
      } else {
        const arr = regions.map((item) => Number(item.id));
        setIsChecked(arr);
      }
      setIsCheckedAll((prev) => !prev);
    };

    const handleEdit = () => {
      setIsShow(!isShow)
    }
  
    const handleLoad = (e) => {
      const file = e.target.files[0]
      if (type==="ADD") {
        const reader = new FileReader()
        reader.onloadend = () => {
          setAvatarFile(file)
          setAvatarURL(reader.result)
        }
        reader.readAsDataURL(file)
      } else {
        uploadUserImage(initialValues.id, file);
      }
      setIsShow(false);
    } 
  
    const handleDel = () => {
      deleteUserImage(initialValues.id);
      setIsShow(false);
    }

    const closeModal = () => {
      props.onClose()
      editChangedDeps([])
    }

    const popUpFiltersChanges = (e) => {
      setProfileFilters(prev => ({...prev, [e.target.name] : e.target.value}))
    }

    const profileClick = (profile) => {
      change("unaprofile_id", profile.id)
      closePopUp()
      setProfileName(`${profile.lastName} ${profile.firstName} ${profile.fathersName}`)
    }

    const handleOutsideClick = (event) => {
      const path = event.path || (event.composedPath && event.composedPath());
      if (popUpRef.current && !path.includes(popUpRef.current)) {
        closePopUp()
      }
  };

    const closePopUp = () => {
      setProfilePopUp(false)
      removeDataForPrint()
    }

    const renderRoleLi = (role) => {
      console.log(role)
      return (
        <li>
          <a
            className={styles.roleItem}
            href="/roles"
            target="_blank"
          >
            {role}
          </a>
        </li>
      )
    }

    return (
      <Modal
        {...props}
        classNames={{
          modal: "modal-container settingModal",
          closeButton: "display-none",
        }}
      >
        <div className="newsListContainer__title">Пользователи. {title}</div>
        <div className={styles.firstBlock}>
          <div className={styles.avatar}>
            <div className={styles.photoBlock}>
              <span className={styles.editBlock} onClick={handleEdit}>
                <i className={styles.edit}>
                  {isShow ? <IoIosClose /> : <IoMdCreate />}
                </i>
              </span>
              <div className={styles.avatarBlock}>
                {loading ? (
                  <Loader top="32%" left="35%" color="#f2c689" />
                ) : (
                  <img
                    src={
                      avatarURL ? avatarURL :  initialValues?.imageurl ?
                        `${BACKEND_DOMAIN || ""}${initialValues.imageurl}`
                          : avatar
                    }
                    alt="о_О"
                  />
                )}
              </div>
              {isShow && (
                <>
                  <div className={styles.input__wrapper}>
                    <input
                      name="file"
                      type="file"
                      id="input__file"
                      className={`${styles.input}  ${styles.input__file}`}
                      onChange={handleLoad}
                      accept="image/jpeg,image/png"
                    />
                    <label
                      for="input__file"
                      className={styles.input__file_button}
                    >
                      <span className={styles.input__file_icon_wrapper}>
                        <IoMdDownload />
                      </span>
                      <span className={styles.input__file_button_text}>
                        Выберите фото
                      </span>
                    </label>
                  </div>

                  {initialValues?.imageurl && (
                    <button
                      className={styles.button}
                      onClick={() => handleDel()}
                    >
                      <i className={styles.trash}>
                        <IoMdTrash />
                      </i>
                      <span className={styles.delTxt}>Удалить фото</span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.rolesBlock}>
            <span>Роли:</span>
            <ul>
              {initialValues?.roles?.map(renderRoleLi)}
            </ul>
          </div>
        </div>
        <form className="modalBody settingModalBody">
          <div className={styles.modalBodyBlock}>
          <div>
          <TextField
            required
            label="Фамилия Имя Отчество"
            labelClass="news__label settingModal__label"
            name="displayname"
            autoComplete="off"
            className="news-source settingModal__input"
          />
          <TextField
            label="Должность"
            labelClass="news__label settingModal__label"
            name="position"
            autoComplete="off"
            className="news-source settingModal__input"
          />
          <TextField
            label="Телефон"
            labelClass="news__label settingModal__label"
            name="phone"
            autoComplete="off"
            className="news-source settingModal__input"
          />
          <div className="settingModal__group">
            <TextField
              required
              label="Пароль"
              labelClass="news__label settingModal__label"
              name="password"
              className="news-source settingModal__input"
              type="password"
              autoComplete="off"
            />
            {editDisabled && (
              <button
                className="settingModal__btnChange btn_grey"
                onClick={handleSubmit(changePassword)}
                type="button"
              >
                Изменить
              </button>
            )}
          </div>
          </div>
          <div>
          <TextField
            required
            label="Логин"
            labelClass="news__label settingModal__label"
            name="username"
            disabled={editDisabled}
            className="news-source settingModal__input"
            autoComplete="off"
          />
          <div className={styles.selectTitle}>Региональное отделение</div>
          <SelectField
            className={styles.departmentSelect}
            placeholder="Региональное отделение"
            options={departments ? departments.map(it => ({label: it.name, value: it.id})) : []}
            name="department_id"
            onChange={() => {}}
          />
          <TextField
            label="E-mail"
            labelClass="news__label settingModal__label"
            name="email"
            autoComplete="off"
            className="news-source settingModal__input"
          />
          <div className={styles.operationStateBox}>
            <div className={styles.operationStateBoxTitle}>Отображение в информационной панели</div>
            <div style={{marginTop: "15px"}}>
              <TextField
                label="Порядок сортировки"
                labelClass="news__label settingModal__label"
                name="serial_number"
                autoComplete="off"
                className="news-source settingModal__input"
                type="number"
                onWheel={(e) => e.target.blur()}
                style={{width: "50px"}}
              />
            </div>
            <CheckboxField
              className="settingModal__checkbox"
              labelClass="news__label settingModal__labelCheck"
              fieldStyle={{marginTop: "15px"}}
              label="Руководящий состав"
              name="management"
              color="#d7001b"
            />
          </div>
          <div
            className={styles.profileBlock}
            ref={popUpRef}
          >
              <div>Профиль юнармейца</div>
              <MdModeEdit onClick={() => setProfilePopUp(true)}/>
              <a
                className={styles.profileName}
                href={formValues?.unaprofile_id && `/profile/${formValues.unaprofile_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {profileName}
              </a>
              {profilePopUp &&
                <div className={styles.popup}>
                  <CustomSelect
                    selwidth="100%"
                    title="Выберите регион"
                    name="regions"
                    options={regions.map(it=>({name: `${it.regionName} ${it.federalUnit}`, value: it.id}))}
                    onChange={popUpFiltersChanges}
                    />
                  <CustomInput
                    title="Фамилия"
                    name="lastName"
                    onChange={popUpFiltersChanges}
                  />
                  <CustomInput
                    title="Имя"
                    name="firstName"
                    onChange={popUpFiltersChanges}
                  />
                  <CustomInput
                    title="Отчество"
                    name="fathersName"
                    onChange={popUpFiltersChanges}
                  />
                  <div className={styles.popupLabel}>Выберите профиль:</div>
                  <div className={styles.popupProfiles}>
                    {!!profiles?.length && profiles.map(it =>
                      <p onClick={() => profileClick(it)}>
                        {`${it.lastName} ${it.firstName} ${it.fathersName} (${it.personalId})`}
                      </p>
                    )}
                  </div>
                </div>
              }
          </div>          
          </div>
          </div>
          <p className="news__label" style={{marginTop: "10px"}}>Описание</p>
                <WysiwygField className="newsText" name="description" />
          <span className="regionTitle">Регионы доступные пользователю:</span>

          <CustomCheckbox
            label={`Выбрать все`}
            value={isCheckedAll}
            onChange={selectAll}
          />

          <div className="regionWrapper">
            {regions &&
              regions.map((region, index) => (
                <div key={`${region.regionName}_${index}`} className={styles.regionItem}>
                  <CustomCheckbox
                    label={`${region.regionName} ${region.federalUnit}`}
                    value={isChecked.includes(Number(region.id))}
                    onChange={() => handleCheck(region.id)}
                  />
                  {isChecked.includes(Number(region.id)) &&
                  <i className={styles.depIcon} onClick={() => setCurrentRegion(region.id)}>
                    <IoMdCreate color="rgb(85, 83, 83)"/>
                  </i>}
                  {currentRegion === region.id &&
                    <DepartmentsModal
                      data={region}
                      setCurrentRegion={setCurrentRegion}
                      getUserDepartments={getUserDepartments}
                      id={initialValues.id}
                      userDeps={userDeps}
                      editUserDeps={editUserDeps}
                      editUserDepartments={editUserDepartments}
                      changedDeps={changedDeps}
                      editChangedDeps={editChangedDeps}
                    />
                  }
                </div>
              ))}
          </div>

          <div className="settingModal__btns">
            <button
              className="newsListHeader__btn btn_grey"
              type="submit"
              onClick={handleSubmit(handleSend)}
            >
              {label}
            </button>
            <button
              className="newsListHeader__btn btn_grey"
              onClick={closeModal}
              type="button"
            >
              Закрыть
            </button>
          </div>
        </form>
      </Modal>
    );
  }


  export default UserModal