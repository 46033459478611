import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import Loader from "../../components/Loader/Loader";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import styles from "./Digital.module.css";
import ReactQuill from "react-quill";
import { BACKEND_DOMAIN, BACKEND_URL_PREFIX } from "../../utils/const";
import { IoMdCreate, IoMdClose } from "react-icons/io";
import previewImage from "../../assets/images/preview.png"
import { createNotification } from "../../components/Notifications/Notifications";
import moment from "moment/moment";

// import FineUploaderTraditional from "fine-uploader-wrappers";
// import Gallery from "react-fine-uploader";
// import FileInput from 'react-fine-uploader/file-input'

// import "react-fine-uploader/gallery/gallery.css";
const chunkSize = 10 * 1024;

const DigitalModal = ({
  type,
  numberPage,
  selectedItem,
  setSelectedItem,
  editDigitalItem,
  addDigitalItem,
  getDigitalItems,
  data,
  loading,
  getCategory,
  accessRights,
  category,
  uploaBigdFile,
  token,
  setText,
  ...props
}) => {
  

  // const uploader = new FineUploaderTraditional({
  //   options: {
  //     chunking: {
  //       enabled: true
  //     },
  //     // acceptFiles: '.mp4',
  //     deleteFile: {
  //       enabled: true,
  //       endpoint: "/uploads"
  //     },
  //     validation: {
  //       allowedExtensions: ['mp4'],
  //     },
  //     request: {
  //       endpoint: `${BACKEND_URL_PREFIX}/digital_academy/${selectedItem?.id}/video`,
  //       customHeaders: {Authorization: `Basic ${btoa(`nnz:${token}`)}`}
  //     },
  //     retry: {
  //       enableAuto: false
  //     },
  //   }
  // });

  const updateRights = type === "ADD" || accessRights.ac_update

  const [item, setItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [categoriesList, setCategoriesList] = useState('');
  const [categories, setCategories] = useState(null);
  const [isCheck, setIsCheck] = useState(null);
  const [existingFile, setExistingFile] = useState(null)
  const [addFile, setAddFile] = useState(null)
  const [preview, setPreview] = useState(null)


  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);

  function handleDrop(e) {
    e.preventDefault();
    setFiles([...files, ...e.dataTransfer.files]);
  }

  function readAndUploadCurrentChunk() {
    const reader = new FileReader();
    const file = files[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = e => uploadChunk(e);
    reader.readAsDataURL(blob);
  }

  async function uploadChunk(readerEvent) {
    const file = files[currentFileIndex];
    const data = readerEvent.target.result;
    console.log(file, 'file')
    const params = new URLSearchParams();
    params.set('name', file.name);
    params.set('size', file.size);
    params.set('currentChunkIndex', currentChunkIndex);
    params.set('totalChunks', Math.ceil(file.size / chunkSize));
    // console.log(params, 'params')
    // const headers = 
    // {'Content-Type': 'application/octet-stream', 
    // 'method': "POST",
    // 'Authorization':'Basic bm56OmV5SmhiR2NpT2lKSVV6VXhNaUlzSW1saGRDSTZNVFkzTnpVd01ESTNNaXdpWlhod0lqb3hOamMzTlRnMk5qY3lmUS5leUoxYzJWeWJtRnRaU0k2SW1aeWIyNTBJbjAuSGdLTFdDengzd2RpV1RIYU9hWGFzV2lXNVl6UDVUbU9hSU9KZFFFZjh2LVRKbTFOdi1xS1k4M1B0b21BVHQ5Vl9tRlBsMXlhMHczUV9SUnRjRlFpRUE='};
    const url = `${BACKEND_URL_PREFIX}/digital_academy/${item.id}/video`;
    
    const fd = new FormData()
    fd.append('file', data)
    
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Authorization': `Basic ${btoa(`nnz:${token}`)}`,
      },
      body: JSON.stringify({
        qqfile: fd, 
        qqfilename: params.get('name'), 
        qqtotalparts: params.get('totalChunks'), 
        qqtotalfilesize: file.size, 
        qqchunksize: params.get('size'), 
        qqpartindex: params.get('currentChunkIndex'), 
        qqpartbytrateoffset: 0})
    })
      .then(response => {
        const file = files[currentFileIndex];
        const filesize = files[currentFileIndex].size;
        const chunks = Math.ceil(filesize / chunkSize) - 1;
        const isLastChunk = currentChunkIndex === chunks;
        if (isLastChunk) {
          file.finalFilename = response.data.finalFilename;
          setLastUploadedFileIndex(currentFileIndex);
          setCurrentChunkIndex(null);
        } else {
          setCurrentChunkIndex(currentChunkIndex + 1);
        }
      });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === files.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (files.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [files.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  useEffect(() => {
    if (type === "ADD") {
      setItem({ name: "", description: "", length: "" , category: ''});
      setIsCheck(null)
      setCategoriesList('');
      category && setCategoriesList(prev => ([...category]));
    } else {
      if (selectedItem) {
        setItem(prev => ({ ...selectedItem, ...prev }));
        const file = selectedItem.imageurl
        const fileNameArr = file?.split('/')
        const data = file
          ? {
            video: file,
            fileName: fileNameArr.pop(),
            preview: selectedItem.preview
          }
          : null
        setExistingFile(data)
      }
    }
  }, [selectedItem, type]);

  useEffect(() => {
    setIsCheck(null);
    getCategory(numberPage+1);
  }, [selectedItem?.category])

  useEffect(() => {
      selectedItem && selectedItem.category && setIsCheck(selectedItem.category);
      category && setCategoriesList(prev => ([...category]));
  }, [category])
 
  const addVideo = () => {
    
    const htmlObject = document.createElement('div');
    htmlObject.innerHTML = item.description;

    if (item.name.trim().length == 0) {
      createNotification("error", `Необходимо заполнить "Название"`); 
    } else if (htmlObject?.textContent == "") {
      createNotification("error", `Необходимо заполнить "Описание"`);
    } else if (!isCheck) {
      createNotification("error", `Необходимо выбрать категорию"`); 
    } else {
      addDigitalItem({...item, category: isCheck, description: htmlObject?.textContent}, numberPage+1, addFile, preview);
      setCategories(null);
      setCategoriesList(null);
      setIsCheck(null)
      props.onClose();
      setText('');
      }
  };

  const editVideo = () => {

    const htmlObject = document.createElement('div');
    htmlObject.innerHTML = item.description;

    if (item.name.trim().length == 0) {
      createNotification("error", `Необходимо заполнить "Название"`); 
    } else if (htmlObject?.textContent == "") {
      createNotification("error", `Необходимо заполнить "Описание"`);
    } else if (!isCheck) {
      createNotification("error", `Необходимо выбрать категорию"`); 
    } else {
      const incomingFile = selectedItem.imageurl
      const incomingFilePreview = selectedItem.preview
      const removeFile = incomingFile && !existingFile
      const removePreview = existingFile && !existingFile.preview && incomingFilePreview
      
      // uploader.methods.uploadStoredFiles()
      
      editDigitalItem({...item, category: isCheck}, numberPage+1, removeFile, addFile, preview, removePreview)
      setCategories(null);
      setCategoriesList(null);
      setIsCheck(null);
      props.onClose();
      setText('');
      }
  };

  const downloadFile = () => {
    if (existingFile.video) {
      const link = document.createElement('a')
      link.setAttribute('href', `${BACKEND_DOMAIN || window.location.origin}/${existingFile.video}`);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', 'download');
      link.click()
    }
  }

  const handleSelectFile = (e) => {
    const file = e.target.files[0]
    const name = file.name
    setAddFile({file, name})
    setExistingFile(null)
    validateFile(file)
  };

  const handleAdd = () => {
    if (categories.length > 0 && categories.replace(/\s/g, '')) {
      setCategoriesList(prev => ([...prev, {category: categories}]));
      setCategories('');
    } else {
      
    }
  }

  const  validateFile = (file) => {

    var video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function() {
      window.URL.revokeObjectURL(video.src);
      const formattedTime = moment.utc(video.duration*1000).format('HH:mm:ss');
      setItem(prev => ({...prev, length: formattedTime}))
    }

    video.src = URL.createObjectURL(file);
  }

  console.log(item)

  const handleDeleteFile = (e) => {
    const name = existingFile?.fileName || addFile.name
    e.stopPropagation()
    setConfirmation(name);
  };

  const acceptConfirmation = (name) => {
    setConfirmation(null)
    setExistingFile(null)
    setAddFile(null)
    setItem(prev => ({...prev, length: null}))
  };

  const rejectConfirmation = () => {
    setConfirmation(null);
  };

  const handleSelectPreview = (e) => {
    e.stopPropagation()
    const input = document.getElementById('inputPreview')
    input.click()
  }

  const handleSetPreview = (e) => {
    const file = e.target.files[0]
    setPreview(file)
    existingFile &&
      setExistingFile(prev => ({...prev, preview: null}))
  }

  const clearPreview = (e) => {
    e.stopPropagation()
    setPreview(null)
    existingFile &&
      setExistingFile(prev => ({...prev, preview: null}))
  }

  const handleFile = (e) => {
    console.log(e, 'ee')

  }

  const statusToggleClick = () => {
    const status = item.status === 2 ? 1 : 2
    setItem(prev => ({...prev, status}))
  }

  return (
    <Modal
      {...props}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {loading && <Loader top="40%" left="40%" />}
      <div className={styles.title}>
        {type === "ADD" ? "Добавление документа" : "Редактирование документа"}
      </div>
      {item && <div className={styles.modalBody}>

        <div>
          Название:
          <input
            value={item?.name}
            onChange={(e) =>
              setItem((prev) => ({ ...prev, name: e.target.value }))
            }
            disabled={!updateRights}
            className={styles.input}
            placeholder="Название"
            style={{ marginBottom: "10px" }}
          />
          <span className={styles.descr}>Описание:</span>
         
          <ReactQuill
            value={item?.description ? item?.description : ''}
            readOnly={!updateRights}
            onChange={(e) => setItem((prev) => ({ ...prev, description: e }))}
          />
            <span className={styles.categoriesTitleMain}>
              Порядковый номер документа:
            </span>
            <input
              className={styles.input}
              style={{width: "35px"}}
              type="number"
              disabled={!updateRights}
              value={item.serial_number}
              onChange={(e) => setItem(prev => ({...prev, serial_number: e.target.value}))}
            />
            <div className={styles.categoriesWrpapper}>
              <span className={styles.categoriesTitleMain}>
                Категории:
              </span>
              <div className={styles.wrapperInput}>
                <input
                  type="text"
                  value={categories}
                  disabled={!updateRights}
                  onChange={(e) => setCategories(e.target.value)}
                />
                {categories &&
                <div
                  className={styles.buttonAdd}
                  onClick={handleAdd}
                >
                  Добавить
                </div>}
              </div>
              <div className={styles.categoriesLists}>

              {categoriesList && categoriesList.map(item => 
                <div key={`id_${item.id}`}>                 
                  <div
                    className={styles.radio}
                    onClick={() => setIsCheck(item.category)}
                  >
                    <input
                      className={styles.customRadio}
                      type="radio"
                      checked={item.category == isCheck}
                      value={item.category || undefined}
                    />
                    <label>{item.category == null ? 'Не выбрано' : item.category}</label>
                  </div>
                </div>
              )}
              </div> 
            </div>
            {!(existingFile?.fileName || addFile) &&
            <div>
              Ссылка на файл:
              <input
                value={item?.link}
                disabled={!updateRights}
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, link: e.target.value }))
                }
                className={styles.input}
                placeholder="Ссылка на файл"
                style={{ marginBottom: "10px" }}
              />
            </div>}

            {(existingFile?.fileName || addFile) &&
          <div className={styles.fileBlock}>
            <p>Файл:</p>
            <input
              type="file"
              id="inputPreview"
              disabled={!updateRights}
              accept="image/png, image/jpeg"
              onChange={handleSetPreview}
              hidden
            />
            <div
              className={styles.fileItem}
              onClick={downloadFile}
            >
              <IoMdCreate onClick={handleSelectPreview} title='Изменить превью'/>
              <IoMdClose onClick={handleDeleteFile} title='Удалить видео'/>
              <div className={styles.filePreview}>
                <img
                  src={(existingFile && existingFile.preview) 
                        ? `${BACKEND_DOMAIN || window.location.origin}/${existingFile.preview.path}/${existingFile.preview.name}`
                        : (addFile && addFile.preview)
                          ? addFile.preview
                          : preview
                            ? URL.createObjectURL(preview)
                            : previewImage
                      }
                  alt="o_O"
                />
                {(existingFile?.preview || preview) &&
                <button
                  onClick={clearPreview}
                >
                  Очистить
                </button>}
              </div>
              <div className={styles.fileTitle}>{existingFile ? existingFile.fileName : addFile.name}</div>
            </div>
          </div>}
          <CustomCheckbox
            label='Отображать в мобильном приложении'
            value={item?.status === 2}
            onClick={statusToggleClick}
          />
            {!item.link && !(existingFile?.fileName || addFile)
            ? <div className={styles.input}>
                <InputFile
                  title="Выбрать видео"
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={handleSelectFile}
                />

              {/* <Gallery uploader={uploader} accept="video/mp4,video/x-m4v,video/*"/> */}

              {/* <FileInput multiple accept="video/mp4,video/x-m4v,video/*" onChange={(e) => handleFile(e)} uploader={uploader}className="file-upload">
                <span style={{cursor : 'pointer'}} className="fa fa-upload file-label">Выберите файл</span>
              </FileInput> */}
      {/* <div
        onDragOver={e => {setDropzoneActive(true); e.preventDefault();}}
        onDragLeave={e => {setDropzoneActive(false); e.preventDefault();}}
        onDrop={e => handleDrop(e)}
        className={"dropzone" + (dropzoneActive ? " active" : "")}>
        Drop your files here
      </div>
      <div className="files">
        {files.map((file,fileIndex) => {
          let progress = 0;
          if (file.finalFilename) {
            progress = 100;
          } else {
            const uploading = fileIndex === currentFileIndex;
            const chunks = Math.ceil(file.size / chunkSize);
            if (uploading) {
              progress = Math.round(currentChunkIndex / chunks * 100);
            } else {
              progress = 0;
            }
          }
          return (
            <a className="file" target="_blank"
               href={`${BACKEND_URL_PREFIX}/digital_academy/${item.id}/video`}>
              <div className="name">{file.name}</div>
              <div className={"progress " + (progress === 100 ? 'done' : '')}
                   style={{width:progress+'%'}}>{progress}%</div>
            </a>
          );
        })}
      </div> */}

              

              </div>
            : ''}

        </div>
        <div className={styles.modalButtons}>
          {updateRights &&
          <ButtonRed onClick={type === "ADD" ? addVideo : editVideo}>
            {type === "ADD" ? "Добавить" : "Сохранить"}
          </ButtonRed>}
          <ButtonRed onClick={props.onClose}>Закрыть</ButtonRed>
        </div>

      </div>}
    </Modal>
  );
};

export default DigitalModal;