import { buildDefaultHeaders } from "./helper";
import { BACKEND_URL_PREFIX, newsOnPage } from "../utils/const";
import { checkAndUpdateToken } from "./auth"
import {
  SET_DETACHMENTS,
  SET_LOCAL_DEPARTMENTS,
  SET_REGION_DEPARTMENTS,
  REMOVE_LOCAL_DEP,
  REMOVE_DETACHMENTS,
  SET_DETACHMENTS_LOAD,
  REMOVE_DEPARTMENTS,
  ERROR,
  SET_DEPARTMENTS_COUNT,
  SET_DETACHMENT_DETAILS,
  SET_REGS,
  GET_EXCEL,
  GET_EXCEL_LOADING,
  SET_DETACHMENTS_IMG_LOAD,
  SET_FROM_LIST_LOADING,
  SET_DETACHMENTS_BY_REGION,
  SET_DETACHMENT_DETAILS_LOAD,
  SET_TROOP_TYPES
} from "./types";
import { createNotification } from "../components/Notifications/Notifications"


export const removeDepartments = () => ({type: REMOVE_DEPARTMENTS})
export const removeLocalDep = () => ({type: REMOVE_LOCAL_DEP})
export const exelLoadingStop = () => ({type: GET_EXCEL_LOADING, payload: false})
export const removeDetachmentDetails = () => ({type: SET_DETACHMENT_DETAILS, payload: []})
export const removeDetachments = () => ({type: REMOVE_DETACHMENTS})

export const getDepartmentsCount = (parent=0, detachment=false, domDet="", offset=null, name, profileType) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({type: SET_DETACHMENTS_LOAD, payload: true});
    const par = domDet ? `&domestic_detachment=${domDet}` : '' 
    const newOffset = offset ? `&offset=${offset * newsOnPage - newsOnPage}` : '';
    const newName = name ? `&name=${name}` : '';
    const newProfileType = profileType ? `&profile_detachment=${profileType}` : '';
    const response = await fetch(`${BACKEND_URL_PREFIX}/department/count?parent=${parent}&detachment=${detachment}`+ par + newOffset + newName + newProfileType, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const pageCount = Math.ceil(data.total_rows / newsOnPage);
      dispatch({
        type: SET_DEPARTMENTS_COUNT,
        payload: { data: data.total_rows, pageCount: pageCount },
      });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
    dispatch({type: SET_DETACHMENTS_LOAD, payload: false});
  }
};

export const getDepartments = (parent=0, detachment=false, domDet="", limit, offset, name, profileType) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
    dispatch({type: SET_DETACHMENTS_LOAD, payload: true});
    if (getState().auth.token) {
      const par = domDet ? `&domestic_detachment=${domDet}` : '' 
      const newOffset = offset ? `&offset=${offset * newsOnPage - newsOnPage}` : '';
      const newLimit = limit ? `&limit=${limit}` : '';
      const newName = name ? `&name=${name}` : '';
      const newProfileType = profileType ? `&profile_detachment=${profileType}` : '';
      const response = await fetch(`${BACKEND_URL_PREFIX}/department?parent=${parent}&detachment=${detachment}`+ par + newOffset + newLimit + newName+ newProfileType, {
              method: "GET",
              ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
              const data = await response.json();
              const actionType = parent ? (detachment ? SET_DETACHMENTS : SET_LOCAL_DEPARTMENTS) : SET_REGION_DEPARTMENTS
              dispatch({ type: actionType, payload: data });
            } else {
                dispatch({ type: ERROR, payload: response });
            }
      }
      dispatch({type: SET_DETACHMENTS_LOAD, payload: false});
  }

  export const deleteDepartment =
  (id, parent=0, detachment=false, numberPage=0) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    dispatch({type: SET_DETACHMENTS_LOAD, payload: true});
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/department/${id}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        if (detachment) {
          dispatch(getDepartments(parent, detachment, '', 10, numberPage+1));
        } else {
          dispatch(getDepartments(parent, detachment, ''));
        }
        dispatch(getDepartmentsCount(parent, detachment, ''))
        // if (parent === 0) {
        //   dispatch(getDepartmentsAll(parent, true, '', 10, numberPage+1));
        // } else {
        //   dispatch(getDepartments(parent, true, '', 10, numberPage+1));
        // }
      } else {
        if (response.status===400) {
          createNotification('error', 'Невозможно удалить отделение, в котором кто-либо состоит')
        }
          dispatch({ type: ERROR, payload: response });
      }
      dispatch({type: SET_DETACHMENTS_LOAD, payload: false});
    }
  };

  export const addDepartment = (data, parent=0, detachment=false, file, numberPage=0) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      dispatch({type: SET_DETACHMENTS_LOAD, payload: true});
        const response = await fetch(`${BACKEND_URL_PREFIX}/department`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
            if (detachment) {
              dispatch(getDepartments(parent, detachment, '', 10, numberPage+1));
            } else {
              dispatch(getDepartments(parent, detachment, ''));
            }
            dispatch(getDepartmentsCount(parent, detachment, ''))  
            if (file.file) {
              const data = await response.json();
              const depType = detachment ? 'detachment' : 'department'
              const id = data[depType].id
              dispatch(uploadFile(id, file, parent, detachment))
                }
        } else {
          let error
          if (response.status===403) { error = 'Доступ к редактированию запрещен'}
          if (response.status===409) {
            const respData = await response.json()
            error = respData.error
          }
          error && createNotification('error', error);
        }
        dispatch({type: SET_DETACHMENTS_LOAD, payload: false});
    }
}

export const editDepartment = (id, data, parent=0, detachment=false, domestic_detachment, numberPage=0) => async (dispatch, getState) => {
  
  delete data.imageurl  
  delete data.chief_fathersname  
  delete data.chief_firstname  
  delete data.chief_lastname  
  delete data.yun_count  
  delete data.svo
  delete data.vov
  delete data.sportsman
  delete data.local_wars
  delete data.cosmonaut
  delete data.afghanistan
  delete data.other
  delete data.achievement_desc
  delete data.other_desc    
  delete data.count    

  
  !data.domestic_detachment && (data.domestic_achievements = {})

  for (let key in data.domestic_achievements) {
    if (['svo', 'vov', 'sportsman', 'local_wars', 'cosmonaut', 'afghanistan', 'other'].includes(key)) {
      if (data.domestic_achievements[key] == null) {
        data.domestic_achievements[key] = false;
      }
    }
  }
    
    await dispatch(checkAndUpdateToken())
    dispatch({type: SET_DETACHMENTS_LOAD, payload: true});
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/department/${id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
          if (detachment) {
            dispatch(getDepartments(parent, detachment, '', 10, numberPage+1));
          } else {
            dispatch(getDepartments(parent, detachment, ''));
          }
          dispatch(getDepartmentsCount(parent, detachment, ''))
        } else {
          if (response.status===403) {
            const respData = await response.json() 
            createNotification('error', respData.error)
          }  
          dispatch({ type: ERROR, payload: response });
        }
        dispatch({type: SET_DETACHMENTS_LOAD, payload: false});
    }
}

export const uploadFile =
  (department_id, file, parent=0, detachment=false, numberPage=0) => async (dispatch, getState) => {
    dispatch({ type: SET_DETACHMENTS_LOAD, payload: true });
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    fd.append("file", file.file);

    if (getState().auth.token) {
      try {
        const response = await fetch(
          `${BACKEND_URL_PREFIX}/department/${department_id}/upload_image`,
          {
            method: "POST",
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
          }
        );
        if (response.ok) {
          dispatch(getDepartments(parent, false, ''));
          dispatch({type: SET_DETACHMENTS_IMG_LOAD, payload: -1});
          if (parent === 0) {
            dispatch(getDepartmentsAll(parent, true, '', 10, numberPage+1));
            getDepartmentsCount(parent, true, '', 10, numberPage+1)
          } else {
            dispatch(getDepartments(parent, true, '', 10, numberPage+1));
            getDepartmentsCount(parent, true, '', 10, numberPage+1)
          }
        } else if (response.status == 403) {
          createNotification('error', 'Доступ к редактированию запрещен');
        } else if (response.status == 413) {
          createNotification('error', 'Размер файла не должен превышать 5Мб');
        } else {
          dispatch({ type: ERROR, payload: response });
        }
      } catch (ERR) {
        if (!Object.keys(ERR).length) {
          createNotification('error', 'Размер файла не должен превышать 5Мб');
        }
      } finally {
        dispatch({ type: SET_DETACHMENTS_LOAD, payload: false });
      }
    }
  };


  export const deleteFile =
  (department_id, parent=0, detachment=false, numberPage=0, file, itemParent) => async (dispatch, getState) => {
    dispatch({ type: SET_DETACHMENTS_LOAD, payload: true });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/department/${department_id}/delete_image`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        file?.file && await dispatch(uploadFile(itemParent, file));
        dispatch({ type: SET_DETACHMENTS_LOAD, payload: false });
        if (parent === 0) {
          dispatch(getDepartmentsAll(parent, true, '', 10, numberPage+1));
          getDepartmentsCount(parent, true, '', 10, numberPage+1)
        } else {
          dispatch(getDepartments(parent, true, '', 10, numberPage+1));
          getDepartmentsCount(parent, true, '', 10, numberPage+1)
        }
      } else if (response.status == 403) {
        createNotification('error', 'Доступ к редактированию запрещен');

      }

      else {
          dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const getForAny = (arr) => async (dispatch, getState) => {
    dispatch({type: SET_FROM_LIST_LOADING, payload: true})
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      for (let bool of [false, true]) {
        let finalArr = []
        for (let id of arr) {
        await dispatch(getDepartments(id, bool))
        const part = bool ? getState().departments.detachments : getState().departments.local_departments
        finalArr = [...finalArr, ...part]
      }
      let type = bool ? SET_DETACHMENTS : SET_LOCAL_DEPARTMENTS
      dispatch({type: type, payload: finalArr})
      }
    }
    dispatch({type: SET_FROM_LIST_LOADING, payload: false})
  }
  
  export const getDetachmentDetails = (id) => async (dispatch, getState) => {
    dispatch({type: SET_DETACHMENT_DETAILS_LOAD, payload: true});
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/detachment/${id}/details`, {
              method: "GET",
              ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
              const data = await response.json();
              dispatch({ type: SET_DETACHMENT_DETAILS, payload: data });
            } else {
              console.log("Response failed")
            }
      }
      dispatch({type: SET_DETACHMENT_DETAILS_LOAD, payload: false})
  }

  export const getRegions = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}/regions`, {
              method: "GET",
              ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
              const data = await response.json();
              dispatch({ type: SET_REGS, payload: data });
            } else {
              console.log("Response failed")
            }
      }
  }

  export const getDepartmentsExcel = (parent=0, detachment=false, domDet="", name) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      dispatch({ type: GET_EXCEL_LOADING, payload: true });
      const par1 = domDet ? `&domestic_detachment=${domDet}` : ''
      const par2 = detachment ? `&detachment=${detachment}` : ''
      const newName = name ? `&name=${name}` : ''
      const par3 = `&parent=${parent}`
      
      const response = await fetch(`${BACKEND_URL_PREFIX}/department?` + par3 + par2 + par1 + newName, {
              method: "GET",
              ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
              const data = await response.json();
              dispatch({ type: GET_EXCEL, payload: data });
              if (!data.length > 0) {
                dispatch({ type: GET_EXCEL_LOADING, payload: false });
              }
            } else {
                dispatch({ type: ERROR, payload: response });
              }
      }
  }

  export const getDepartmentsAll = (parent=0, detachment=false, domDet="", limit, offset, name, profileType) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    dispatch({type: SET_DETACHMENTS_LOAD, payload: true});
      if (getState().auth.token) {

        const newOffset = offset ? `&offset=${offset * newsOnPage - newsOnPage}` : '';
        const newLimit = limit ? `&limit=${limit}` : '';
        const par = domDet ? `&domestic_detachment=${domDet}` : ''
        const newName = name ? `&name=${name}` : ''
        const newProfileType = profileType ? `&profile_detachment=${profileType}` : '';

        const response = await fetch(`${BACKEND_URL_PREFIX}/department?parent=${parent}&detachment=${detachment}`+ par + newLimit + newOffset + newName + newProfileType, {
                method: "GET",
                ...buildDefaultHeaders(getState),
            });
            if (response.ok) {
                const data = await response.json();
                dispatch({ type: SET_DETACHMENTS, payload: data });
              } else {
                  dispatch({ type: ERROR, payload: response });
              }
        }
        dispatch({type: SET_DETACHMENTS_LOAD, payload: false});
    }

    export const getDepartmentsByRegion = (region_id) => async (dispatch, getState) => {
      await dispatch(checkAndUpdateToken())
        if (getState().auth.token) {
            const response = await fetch(`${BACKEND_URL_PREFIX}/department/${region_id}/domestic_departments`, {
                  method: "GET",
                  ...buildDefaultHeaders(getState),
              });
              if (response.ok) {
                  const data = await response.json();
                  dispatch({ type: SET_DETACHMENTS_BY_REGION, payload: data });
                } else {
                    dispatch({ type: ERROR, payload: response });
                }
          }
      }

      export const getTroopTypes = () => async (dispatch, getState) => {
        await dispatch(checkAndUpdateToken())
          if (getState().auth.token) {
            const response = await fetch(`${BACKEND_URL_PREFIX}/department/profile_detachment/types`, {
                  method: "GET",
                  ...buildDefaultHeaders(getState),
              });
              if (response.ok) {
                  const data = await response.json();
                  dispatch({ type: SET_TROOP_TYPES, payload: data.profile_detachments_types });
                } else {
                    dispatch({ type: ERROR, payload: response });
                }
          }
      }
      
    