import React from 'react'
import { FaFile } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import styles from './FileLabel.module.css'


export default function FileLabel({
    fileName,
    imageMode,
    fileLink,
    onDelete,
    onClick
}) {
  
  
  const deleteFile = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete()
  }

  return (
    <div
      onClick={onClick}
      className={styles.fileBlock}
    >
      {onDelete && <IoMdClose className={styles.deleteIcon} onClick={deleteFile}/>}
      {imageMode
        ? <div><img src={fileLink} alt="o_O" /></div>
        : <FaFile className={styles.fileIcon}/>
      }
      <span>{fileName}</span>
    </div>
  )
}
