import React, { useState, useEffect } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

export default function Yamap({ coordinate, setItem, width = '340px', name = 'geolocation', heigth = '280px' }) {
  
  const [markerCoord, setMarkerCoord] = useState(null);

  useEffect(() => {
    coordinate && coordinate.length &&
    setMarkerCoord(coordinate && coordinate.length ? coordinate.split(",") : ['55.755819', '37.617644']);
    setMapaState({center : coordinate ? coordinate.split(',') : ['55.755819', '37.617644'], zoom: 9})
  }, [coordinate]);

  const newMarkerCoords = (e) => {
    setMarkerCoord(e.get("coords"));
    setItem((prev) => ({ ...prev, [name]: e.get("coords").join()}));
  };

  const [mapaState, setMapaState] = useState({center: ['55.755819', '37.617644']});

  return (
    <YMaps>
      <Map
        width = {width}
        height={heigth}
        defaultState={mapaState}
        modules={["geoObject.addon.editor"]}
        onClick={(e) => newMarkerCoords(e)}
      >
        {markerCoord && (
          <Placemark geometry={markerCoord} options={{ draggable: false }} />
        )}
      </Map>
    </YMaps>
  );
}
