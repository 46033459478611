import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken, signOut } from "./auth";
import { SET_EQUIPMENT_COUNT, SET_EQUIPMENT_DATA, SET_EQUIPMENT_IMAGE, REMOVE_EQUIPMENT_IMAGE, SET_EQUIPMENT_LOADING, ERROR} from "./types.js";


export const removeEquipmentImage = () => {
  return {type: REMOVE_EQUIPMENT_IMAGE}
}

export const getEquipmentCount = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const newsOnPage = getState().auth.itemsOnPage
    const response = await fetch(`${BACKEND_URL_PREFIX}/equipment/count`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const pageCount = Math.ceil(data / newsOnPage);
      dispatch({
        type: SET_EQUIPMENT_COUNT,
        payload: { data: data, pageCount: pageCount },
      });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getEquipmentObject =
  (page=1, sort) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const newsOnPage = getState().auth.itemsOnPage
      const offset = page * newsOnPage - newsOnPage;
      const params = {
        offset: offset,
        limit: newsOnPage
      }
      sort && ( params.alph = sort )
      const urlParams = new URLSearchParams(params).toString();
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/equipment?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_EQUIPMENT_DATA, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteEquipmentObject =
  (id, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/equipment/${id}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        dispatch(getEquipmentCount());
        dispatch(getEquipmentObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const editEquipmentObject =
  (id, data, numberPage) => async (dispatch, getState) => {
    delete data.imageurls;
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/equipment/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        dispatch(getEquipmentObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

 
  export const addEquipmentObject =
  (data, numberPage, images = []) => async (dispatch, getState) => {
    const _data = {...data, price: data.price ? data.price.replace(/\s/g, '') : null};
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/equipment`, {
        method: "POST",
        body: JSON.stringify(_data),
        ...buildDefaultHeaders(getState),
      });
      if (response.ok && images.length > 0) {
        const data = await response.json();
        images.forEach(el => 
          dispatch(uploadImage(data.id, {file: el}, numberPage))
        )
      } else {
        dispatch(getEquipmentCount());
        dispatch(getEquipmentObject(numberPage));
        dispatch({ type: ERROR, payload: response });
      }
    }
  };


export const uploadImage =
  (id, image, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    fd.append("file", image.file);

    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/equipment/${id}/image`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
        }
      );
      if (response.ok) {
        dispatch(getEquipmentCount());
        dispatch(getEquipmentObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteImage = (id, imageURL, numberPage) => async (dispatch, getState) => {
  const img = imageURL.split('/').at(-1);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/equipment/${id}/image/${img}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      dispatch(getEquipmentCount());
      dispatch(getEquipmentObject(numberPage));
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getEquipmentImage =
(id) => async (dispatch, getState) => {
  dispatch({type: SET_EQUIPMENT_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());

  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/equipment/${id}/image`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        }
      }
    );
    if (response.ok) {
      const image = await response.json();
      dispatch({type: SET_EQUIPMENT_IMAGE, payload: image});
      dispatch({type: SET_EQUIPMENT_LOADING, payload: false})
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};