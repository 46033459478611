import React from 'react'
import styles from './CheckMode.module.css'
import {IoIosArrowRoundBack} from "react-icons/io";


export default function CheckMode({
  goBack
}) {
  return (
    <div className={styles.container}>
      <div onClick={goBack} className={styles.goBack}>
        <IoIosArrowRoundBack size={20} />
        назад
      </div>
    </div>
  )
}
