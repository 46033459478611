import React from 'react';
import onClickOutside from 'react-onclickoutside';
import MinButton from './MinButton';

import folder from '../../assets/images/archive-folder-icon.png';
import trash from '../../assets/images/trash-icon.png';

class Menu extends React.Component {
  handleClickOutside = () => {
    const { closeMenu, id } = this.props;
    closeMenu(id);
  };

  render() {
    const { id, editNews } = this.props;
    return (
      <div>
        <MinButton
          label="В архив"
          image={folder}
          onClick={editNews(id, 'status', 3)}
        />
        <MinButton
          label="В корзину"
          image={trash}
          onClick={editNews(id, 'status', 4)}
          className="newsListTableDropdown__btnTrash"
        />
      </div>
    );
  }
}

export default onClickOutside(Menu);
