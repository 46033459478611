import * as types from '../actions/types';

const initialState = {
  userList: [],
  userData: null,
  usersPageCount: 0,
  userRegions: [],
  regionList: [],
  userDepartments: [],
  changedDepartments: [],
  regionGroups: null,
  error: '',
  loading: false
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_USERS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_USERS_SUCCESS:
      const {users, pageCount} = action.payload
      return {
        ...state,
        userList: users,
        usersPageCount: pageCount,
        loading: false,
      };
    case types.FETCH_USERS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case types.ADD_USER_REQUEST:
      return { ...state, loading: true };
    case types.ADD_USER_SUCCESS:
      return { ...state, loading: false };
    case types.ADD_USER_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case types.SET_REGION_LIST:
      return { ...state, regionList: action.payload, loading: false };
    case types.SET_USER_REGIONS:
      return { ...state, userRegions: action.payload, loading: false };
    case types.SET_USER_DEPARTMENTS:
      return { ...state, userDepartments: action.payload };
    case types.SET_CHANGED_DEPARTMENTS:
      return { ...state, changedDepartments: action.payload };
    case types.SET_USER_DATA:
      return { ...state, userData: action.payload, loading: false };
    case types.RESET_USER_INFO:
      return { ...state, userData: null };
    case types.SET_REGION_GROUPS:
      return {
        ...state,
        regionGroups: action.payload
      };
    case types.SET_USER_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
