import img01 from '../assets/images/icons/01.png'
import img02 from '../assets/images/icons/02.png'
import img03 from '../assets/images/icons/03.png'
import img04 from '../assets/images/icons/04.png'
import img05 from '../assets/images/icons/05.png'
import img06 from '../assets/images/icons/06.png'
import img07 from '../assets/images/icons/07.png'
import img08 from '../assets/images/icons/08.png'
import img09 from '../assets/images/icons/09.png'
import img10 from '../assets/images/icons/10.png'
import img11 from '../assets/images/icons/11.png'
import img12 from '../assets/images/icons/12.png'
import img13 from '../assets/images/icons/13.png'
import img14 from '../assets/images/icons/14.png'
import img15 from '../assets/images/icons/15.png'
import img16 from '../assets/images/icons/16.png'
import img17 from '../assets/images/icons/17.png'
import img18 from '../assets/images/icons/18.png'
import img19 from '../assets/images/icons/19.png'
import img20 from '../assets/images/icons/20.png'
import img21 from '../assets/images/icons/21.png'
import img22 from '../assets/images/icons/22.png'
import img23 from '../assets/images/icons/23.png'
import img24 from '../assets/images/icons/24.png'
import img25 from '../assets/images/icons/25.png'
import img26 from '../assets/images/icons/26.png'
import img27 from '../assets/images/icons/27.png'
import img28 from '../assets/images/icons/28.png'
import img29 from '../assets/images/icons/29.png'
import img30 from '../assets/images/icons/30.png'

export const icons = [
    { name: "img01", value: img01 },
    { name: "img02", value: img02 },
    { name: "img03", value: img03 },
    { name: "img04", value: img04 },
    { name: "img05", value: img05 },
    { name: "img06", value: img06 },
    { name: "img07", value: img07 },
    { name: "img08", value: img08 },
    { name: "img09", value: img09 },
    { name: "img10", value: img10 },
    { name: "img11", value: img11 },
    { name: "img12", value: img12 },
    { name: "img13", value: img13 },
    { name: "img14", value: img14 },
    { name: "img15", value: img15 },
    { name: "img16", value: img16 },
    { name: "img17", value: img17 },
    { name: "img18", value: img18 },
    { name: "img19", value: img19 },
    { name: "img20", value: img20 },
    { name: "img21", value: img21 },
    { name: "img22", value: img22 },
    { name: "img23", value: img23 },
    { name: "img24", value: img24 },
    { name: "img25", value: img25 },
    { name: "img26", value: img26 },
    { name: "img27", value: img27 },
    { name: "img28", value: img28 },
    { name: "img29", value: img29 },
    { name: "img30", value: img30 }
]