import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import { CLEAR_IMPORT_DATA, ERROR, SET_IMPORT_DATA, SET_IMPORT_LOADING, SET_IMPORTS_HISTORY, SET_IMPORTS_BY_ID, SET_IMPORTS_BY_ID_LOAD } from "./types";
import { createNotification } from "../components/Notifications/Notifications";

export const clearImportData = () => ({type: CLEAR_IMPORT_DATA})

export const uploadExcelFile = (data) => async (dispatch, getState) => {
    
    dispatch({type: SET_IMPORT_LOADING, payload: true})

    await dispatch(checkAndUpdateToken())
  
    const fd = new FormData()
    fd.append('file', data.file)
  
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/unaprofile/import`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
        })
        if (response.ok) {
            const data = await response.json()
            dispatch({type: SET_IMPORT_DATA, payload: data})
            createNotification('success', 'Отправлено!')
        } else {
            dispatch({ type: ERROR, payload: response });
            createNotification('error', 'Ошибка!')
        }
    }
    dispatch({type: SET_IMPORT_LOADING, payload: false})
  }

  export const getImportsHistory = () => async (dispatch, getState) => {
    dispatch({type: SET_IMPORT_LOADING, payload: true})

    await dispatch(checkAndUpdateToken());
   
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/import_excel_details`, {
        ...buildDefaultHeaders(getState),
      });
  
      if (response.ok) {
        const payload = await response.json();
        dispatch({ type: SET_IMPORTS_HISTORY, payload });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }

    dispatch({type: SET_IMPORT_LOADING, payload: false})
}

export const getImportsById = (id = '') => async (dispatch, getState) => {
    dispatch({type: SET_IMPORTS_BY_ID_LOAD, payload: true})

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/import_excel_details/${id}`, {
        ...buildDefaultHeaders(getState),
      });
  
      if (response.ok) {
        const payload = await response.json();
        dispatch({ type: SET_IMPORTS_BY_ID, payload });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch({type: SET_IMPORTS_BY_ID_LOAD, payload: false})
}

export const deleteImportsById = () => ({type: SET_IMPORTS_BY_ID, payload: null})