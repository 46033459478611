import { buildDefaultHeaders } from "./helper";
import { BACKEND_URL_PREFIX } from "../utils/const";
import { checkAndUpdateToken } from "./auth"
import { SET_HERO, SET_HERO_LOADING, SET_HERO_DATA_BY_ID, GET_TROOPS, SET_HERO_COUNT, SET_HERO_IMAGE, REMOVE_HORE_IMAGE, ERROR } from "./types";
import { createNotification } from "../components/Notifications/Notifications"

export const removeHeroImage = () => {
  return {type: REMOVE_HORE_IMAGE}
}
export const removeTroops = () => {
  return {type: GET_TROOPS, payload: []}
}

export const getHeroesCount = (page = 1, param) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        let urlParams = '';

        const newsOnPage = getState().auth.itemsOnPage;
        const offset = page * newsOnPage - newsOnPage;
        const params = {
            offset: offset,
            limit: newsOnPage
        }
          param?.reg && ( params.region_id = param.reg );
          param?.name && ( params.name = param.name );
          urlParams = new URLSearchParams(params).toString();
      const response = await fetch(`${BACKEND_URL_PREFIX}/get_heroes_count?${urlParams}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        const pageCount = Math.ceil(data / newsOnPage);
        dispatch({
          type: SET_HERO_COUNT,
          payload: { data: data, pageCount },
        });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const getHeroes = (page, param) => async (dispatch, getState) => {
    dispatch({ type: SET_HERO_LOADING, payload: true });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        let urlParams = '';

        const newsOnPage = getState().auth.itemsOnPage;
        const offset = page * newsOnPage - newsOnPage;
        const params = {
            offset: offset,
            limit: newsOnPage
        }
          param?.reg && ( params.region_id = param.reg );
          param?.name && ( params.name = param.name );
          urlParams = new URLSearchParams(params).toString();
      
        const response = await fetch(`${BACKEND_URL_PREFIX}/get_heroes?${urlParams}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({
          type: SET_HERO,
          payload: data.Heroes,
        });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch({ type: SET_HERO_LOADING, payload: false });
  };

  export const getHero =
  (id) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/get_hero/${id}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_HERO_DATA_BY_ID, payload: data.Hero[0] });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };


  export const getTroops = (region, name = '', detachment) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const params = {detachment: true}
      name && ( params.name = name );
      region && (params.regionId = region)
      
      const urlParams = new URLSearchParams(params).toString();
        const response = await fetch(`${BACKEND_URL_PREFIX}/department/list?${urlParams}`, {
              method: "GET",
              ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
              const data = await response.json();
              dispatch({ type: GET_TROOPS, payload: Array.isArray(data) ? data : [data] });
            } else {
              dispatch({ type: ERROR, payload: response });
            }
      }
  }

  export const addHero =
  (data, numberPage, param, images = []) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/create_hero`, {
        method: "POST",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      });

      if (response.ok) {
        const data = await response.json();
        if (images && images.length > 0) {
          images.forEach(el => 
            dispatch(uploadImage(data.id, {file: el}, numberPage)))
          }
        createNotification('success', 'Успешно');
        dispatch(getHeroesCount(1, param));
        dispatch(getHeroes(numberPage, param));
      } else {
        dispatch(getHeroesCount(1, param));
        dispatch(getHeroes(numberPage));
        dispatch({ type: ERROR, payload: response });
        createNotification('error', "Ошибка");  
      }
    }
  };

  export const editHero =
  (id, data, numberPage, param) => async (dispatch, getState) => {

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      
      delete data.department_name;
      delete data.images;

      const response = await fetch(
        `${BACKEND_URL_PREFIX}/update_hero/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        createNotification('success', 'Успешно');
        dispatch(getHeroesCount(1, param));
        dispatch(getHeroes(numberPage, param));
      } else {
        dispatch(getHeroesCount(1, param));
        dispatch(getHeroes(numberPage));
        dispatch({ type: ERROR, payload: response });
        createNotification('error', "Ошибка");  
      }
    }
  };

  export const uploadImage =
  (id, image) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    fd.append("file", image.file);

    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/hero_name/${id}/image`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
        }
      );
      if (response.ok) {
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteImage = (id, imgId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/hero_name/${id}/image/${imgId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const deleteHero =
  (id, numberPage, param) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/delete_hero/${id}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        createNotification('success', 'Успешно');  
        dispatch(getHeroesCount(1, param));
        dispatch(getHeroes(numberPage, param));
      } else {
        const respData = await response.json()
        createNotification('error', respData.error);
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const getHeroImage =
(id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());

  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/hero_name/${id}/image`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        }
      }
    );
    if (response.ok) {
      const image = await response.json();
      dispatch({type: SET_HERO_IMAGE, payload: image.files});
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};