import React, { useState, useEffect } from "react";
import styles from "./FormInput.module.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function FormInput({
  title = "",
  width = "",
  name = "",
  data = {},
  value,
  onChange = () => {},
  editMode = false,
  notValid = false,
  placeholder = "",
  type = "",
  onBlur = () => {}
}) {
  return (
    <div
      className={`${styles.inputWrapper} ${editMode && notValid ? styles.notValid : null}`}
      style={{ width: width }}
    >
      {editMode && notValid && <div className={styles.validDescr}>{notValid}</div>}
      <div className={styles.title}>{title}</div>

      {editMode ? (
        [
          "chiefContactPhone",
          "chiefFathersName",
          "chiefFirstName",
          "chiefLastName",
        ].includes(name) ? 
          <div className={styles.block}>{value && value}</div>
         : (name==="profile_filled_date" && value.length>23) ?
          <div className={styles.block}>{moment(value).format("LLL")}</div>
         : <input
            name={name}
            className={styles.input}
            type={type}
            value={value ? value : ""}
            onChange={onChange}
            placeholder={placeholder}
            onBlur={onBlur}
          />
      ) : (
        name==="profile_filled_date" ?
        <div className={styles.block}>{moment(value).format("LLL")}</div>
        : <div className={styles.block}>{value && value}</div>
      )}
    </div>
  );
}
