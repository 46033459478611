import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken, signOut } from "./auth";
import { SET_TOURISM_COUNT, SET_TOURISM_DATA, SET_REGION_LIST_TOURISM, ERROR } from "./types.js";

export const getTourismCount = (search, region) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const newsOnPage = getState().auth.itemsOnPage
    const params = {}
    if(!!search) params.name = search
    if(!!region) params.region_ids = `[${[region].join(',')}]`

    const urlParams = new URLSearchParams(params).toString();

    const response = await fetch(`${BACKEND_URL_PREFIX}/touristobject/count?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const pageCount = Math.ceil(data / newsOnPage);

      dispatch({type: SET_TOURISM_COUNT, payload: { data: data, pageCount: pageCount }});
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getTourismObject =
  (page, search='', region='', sort='') =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    if (getState().auth.token) {
      const newsOnPage = getState().auth.itemsOnPage
      const offset = page * newsOnPage - newsOnPage;
      const params = {
        offset: offset,
        limit: newsOnPage
      }

      if(!!search) params.name = search
      if(!!region) params.region_ids = `[${[region].join(',')}]`
      if(!!sort) params.alph = sort

      const urlParams = new URLSearchParams(params).toString();
      await dispatch(getTourismCount(search, region))
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/touristobject?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_TOURISM_DATA, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteTourismObject =
  (id, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/touristobject/${id}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        dispatch(getTourismCount());
        dispatch(getTourismObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const editTourismObject =
  (id, data, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const regionList = data.regions && [...data.regions]
      delete data.created
      delete data.created_by
      delete data.created_by_name
      delete data.updated
      delete data.updated_by
      delete data.updated_by_name
      delete data.regions
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/touristobject/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        if (regionList && regionList.length) {
          await dispatch(editTourismObjectRegions(id, regionList))
        }
        dispatch(getTourismObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const addTourismObject =
  (data, numberPage, image) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const regionList = data.regions && [...data.regions]
      delete data.regions
      const response = await fetch(`${BACKEND_URL_PREFIX}/touristobject`, {
        method: "POST",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        regionList && regionList.length &&
        await dispatch(editTourismObjectRegions(data.id, regionList))
        image.file && await dispatch(uploadImage(data.id, image, numberPage))
      } else {
        dispatch({ type: ERROR, payload: response });
      }
      dispatch(getTourismCount());
      dispatch(getTourismObject(numberPage));
    }
  };

export const uploadImage =
  (id, image, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    const fd = new FormData();
    fd.append("file", image.file);

    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/touristobject/${id}/image`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
        }
      );
      if (response.ok) {
        dispatch(getTourismCount());
        dispatch(getTourismObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteImage = (id, numberPage) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/touristobject/${id}/image`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      dispatch(getTourismCount());
      dispatch(getTourismObject(numberPage));
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getRegions = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/regions`, {
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_REGION_LIST_TOURISM, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const editTourismObjectRegions =
  (id, regionList) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const data = { region_ids: regionList}
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/touristobject/${id}/regions`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };