import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { BACKEND_DOMAIN, optionsStap } from "../../../utils/const";
import styles from "../Tasks.module.css";

export default function TaskRealization({
    taskId,
    profileId,
    goBack,
    getTaskRealization

}) {
  
    const { loading, task_realization } = useSelector(state => state.task)
    
    useEffect(() => {
        getTaskRealization(taskId, profileId)
    }, [])
    
  
    const strip = (html) => {
      var tmp = document.createElement("div");
      tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText;
    };


    return (
    <>
      <div className={styles.title}>Выполнение задания</div>
      <div className={styles.modalBody}>
        <div className={styles.realizBlock}>
            {loading ? <Loader size={60} top="40%" left="47%"/> : task_realization && task_realization.steps.length &&
            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Название</th>
                        <th>Задание</th>
                        <th>Результат</th>
                        <th>Выполнение</th>
                    </tr>
                </thead>
                {task_realization && task_realization.steps.map(item => {
                  const link = item.answer?.answer
                  const linkText = item.answer && ( link || `${BACKEND_DOMAIN || ''}/${item.answer.files[0].path}/${item.answer.files[0].name}` )
                  return (
                    <tr>
                        <td>{item.number}</td>
                        <td>{item.name}</td>
                        <td>{strip(item.description)}</td>
                        <td style={{textAlign: "center"}}>{optionsStap.find(it => it.value==item.result).name}</td>
                        <td>
                          {item.answer
                              ? link?.startsWith('https')
                                  ? <a
                                      href={linkText}
                                      target="_blank"
                                      title='Открыть ссылку'
                                      rel="noopener noreferrer"
                                    >
                                      {!!link
                                        ? <div>{linkText}</div>
                                        : <img src={linkText} alt="o_O"/>
                                      }
                                    </a>
                                  : <div title='Скопируйте и вставьте ссылку в поисковую строку'>{link}</div>
                              : 'нет'
                          }
                        </td>
                    </tr>
                  )
                }
                )}
            </table>}
        </div>
        <div className="settingModal__btns">
          <button
            className="newsListHeader__btn btn_grey"
            type="button"
            onClick={goBack}
          >
            Назад
          </button>
          {/* <button
                  className="newsListHeader__btn btn_grey"
                  onClick={props.onClose}
                  type="button"
                >
                  Закрыть
                </button> */}
        </div>
      </div>
    </>
  );
}
